
import { toRef, computed, defineComponent, PropType } from "vue";
import { ciTrackingService } from "@services/ci-tracking.service";
import { useNotifications } from "@composables/notifications";
import { useRouter } from "vue-router/composables";

export default defineComponent({
  name: "CiNotificationWrapper",
  components: {
    CiEventNotification: () => import("@components/notifications/event-notification.vue"),
    CiContentNotification: () => import("@components/notifications/content-notification.vue"),
    CiNewProductNotification: () => import("@components/notifications/new-product-notification.vue"),
    CiPortfolioFollowerNotification: () => import("@components/notifications/portfolio-follower.vue"),
    CiPortfolioNotification: () => import("@components/notifications/portfolio-change.vue"),
    CiProfileVisitorNotification: () => import("@components/notifications/profile-visitor-notification.vue"),
    CiProductNotification: () => import("@components/notifications/product-notification.vue"),
  },
  props: {
    notification: {
      type: Object as PropType<Notifications.MappedItem>,
      required: true,
    },
    handheldStyle: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String as PropType<"center" | "popup">,
      required: true,
    },
  },
  setup(props) {
    const notification = toRef(props, "notification");
    const { markAsRead } = useNotifications();
    const router = useRouter();

    const isUnread = computed(() => !props.notification.original.read_at);

    const notificationComponent = computed(() => {
      const { attribute } = props.notification.original;
      const { type } = props.notification.original;
      if (type === "ProductPerformanceAlarmNotification") {
        return "CiProductNotification";
      }
      if (type === "ProductAttachmentNotification") {
        return "CiProductNotification";
      } else if (type == "ProductChangeNotification") {
        switch (attribute) {
          case "manager":
          case "company__name":
          case "fees__sum_ongoing_charges__value":
          case "characteristics__is_softclosing":
          case "characteristics__is_closed":
          case "sustainable_preference":
          case "risk_level__sri":
            return "CiProductNotification";
          default:
            return null;
        }
      }

      switch (attribute) {
        case "new_article":
        case "new_podcast":
        case "new_video":
        case "new_webcast":
          return "CiContentNotification";
        case "new_event":
        case "new_webinar":
          return "CiEventNotification";
        case "new_product":
          return "CiNewProductNotification";
        case "new_follower":
          return "CiPortfolioFollowerNotification";
        case "new_visitor":
          return "CiProfileVisitorNotification";
        case "performance_alarm":
        case "portfolio":
          return "CiPortfolioNotification";
        default:
          return null;
      }
    });

    const handleClick = async () => {
      const targetIdentifier = props.notification.original.id.toString();
      const targetUrl =
        typeof notification.value.to === "string"
          ? notification.value.to
          : `${location.origin}${router.resolve(notification.value.to).href}`;
      const sourceName = props.location === "popup" ? "NotificationPopup" : "NotificationCenter";
      await Promise.all([
        markAsRead(props.notification.original.id, props.location),
        ciTrackingService.trackClick("Notification", targetIdentifier, sourceName, targetUrl),
      ]);
    };

    const notificationTitleTranslationSource = computed(() => {
      const { attribute, type } = props.notification.original;
      if (type === "ProductAttachmentNotification") {
        return "ui.components.notifications.titles.fonds_mediathek";
      }
      if (type === "ProductPerformanceAlarmNotification") {
        return "ui.components.notifications.titles.product_performance_alarm";
      }

      return `ui.components.notifications.titles.${attribute}`;
    });

    return {
      isUnread,
      notificationComponent,
      handleClick,
      notificationTitleTranslationSource,
    };
  },
});
