var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ci-header" }, [
    _c(
      "div",
      {
        staticClass:
          "ci-header__container ci-content-padding-x d-flex align-items-center w-100",
      },
      [
        !_vm.conditionals.fullscaleSearch
          ? _c(
              "ci-button",
              {
                staticClass: "ci-header__logo mr-3 mr-sm-4",
                attrs: {
                  label: _vm.$t("ui.shared.navigation.main.start_page"),
                  to: { name: _vm.$routes.HOME },
                },
                on: {
                  click: function ($event) {
                    return _vm.trackClick($event, "logo")
                  },
                },
              },
              [_c("img", { attrs: { src: _vm.logo, alt: "CAPinside Logo" } })]
            )
          : _vm._e(),
        _vm.conditionals.showNav ? _c("ci-header-nav-primary") : _vm._e(),
        _c("ci-header-nav-secondary"),
        _c("ci-button", {
          staticClass: "d-lg-none ml-1",
          attrs: {
            icon: _vm.navExpanded ? "mdi-close" : "mdi-menu",
            "icon-class": "mdi-24px",
            variant: "filled-navigation",
            "aria-label": _vm.$t("ui.shared.navigation.tooltips.menu"),
          },
          on: { click: _vm.toggleNav },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }