import { DirectiveOptions } from "vue";

const directive: DirectiveOptions = {
  bind(element) {
    element.addEventListener("click", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  },
};

export default directive;
