import { DirectiveOptions } from "vue";
import { utilityService } from "@services/utility.service";
import { DirectiveBinding } from "vue/types/options";
import { VNode } from "vue/types/umd";
import { Emitter } from "@utils/events";

const checkViewportPosition = (
  element: HTMLElement,
  binding: DirectiveBinding,
  vnode: VNode,
  sleep: boolean
): boolean => {
  if (utilityService.isInViewport(element)) {
    if (!sleep) {
      Emitter.emit("ci::element-in-viewport", binding.value.type || binding.value);
    }
    return true;
  } else {
    Emitter.emit("ci::element-out-viewport", binding.value.type || binding.value);
    return false;
  }
};

const directive: DirectiveOptions = {
  bind: function (element, binding, vnode) {
    let sleep = false;
    setTimeout(() => {
      let scrolleventListener: EventTarget = window;
      if (binding.value.container) {
        scrolleventListener = document.getElementById(binding.value.container) || window;
      }
      scrolleventListener.addEventListener("scroll", () => {
        sleep = checkViewportPosition(element, binding, vnode, sleep);
      });
    });
    setTimeout(() => checkViewportPosition(element, binding, vnode, sleep), 50);
  },
};

export default directive;
