import ImgixClient from "@imgix/js-core";
import { settingsService } from "./settings.service";
import { Imgix } from "@services/imgix";

export class ImgixServiceClass implements Imgix.Service {
  public domain!: string;
  public client!: ImgixClient;

  private defaultParams: Indexable = { auto: "compress,format", fit: "max", cs: "srgb" };

  constructor() {
    this.domain = settingsService.options.imgix_url;
    if (this.domain)
      this.client = new ImgixClient({
        domain: this.domain,
        useHTTPS: true,
      });
  }

  public image: Imgix.GetImgFn = (url, params = {}) => {
    if (!this.client || process.env.NODE_ENV !== "production") return url;

    return this.client.buildURL(url, {
      ...this.defaultParams,
      ...params,
    });
  };

  public buildSrcSet: Imgix.SrcSetFn = (url, params = {}) => {
    if (!this.client || process.env.NODE_ENV !== "production") return `${url} 1x`;

    return this.client.buildSrcSet(url, {
      ...this.defaultParams,
      ...params,
    });
  };
}

export const ImgixService = new ImgixServiceClass();
