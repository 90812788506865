
import { Component, Vue } from "vue-property-decorator";
import { MAIN_NAVIGATION } from "@models/constants/navigation";
import { throttle } from "throttle-debounce";
import NavItem from "./item.vue";
import { getModule } from "vuex-module-decorators";
import { HeaderStoreModule } from "./header.store";
import { ciTrackingService } from "@services/ci-tracking.service";

const HeaderStore = getModule(HeaderStoreModule);

@Component({
  name: "ci-header-nav-primary",
  components: {
    "ci-nav-item": NavItem,
  },
})
export default class extends Vue {
  public scrollShadow = {
    top: false,
    bottom: true,
  };

  public handleThrottledScroll = throttle(100, (event) => {
    this.handleScroll(event);
  });

  public get navExpanded(): boolean {
    return HeaderStore.navExpanded;
  }

  // TO DO: Add new nav item to MAIN_NAVIGATION constant once flipper is fully enabled
  public get items(): Navigation.List {
    return MAIN_NAVIGATION();
  }

  public addDropdownArrow(item: any): boolean {
    return item.groups.length === 0 ? false : true;
  }

  public trackNavItemClick(item: Navigation.Item | Navigation.Child): void {
    ciTrackingService.trackClick("ButtonOnMenuBar", item.trackingIdentifier);
  }

  public openLoginModal(): void {
    this.trackNavClick("login");
    this.$root.$emit("bv::show::modal", `modal-login`);
  }

  public trackNavClick(targetIdentifier: string): void {
    ciTrackingService.trackClick("ButtonOnMenuBar", targetIdentifier);
  }

  public handleScroll(event: Event): void {
    const target = event.target as HTMLDivElement;

    this.scrollShadow.top = target.scrollTop > 20;
    this.scrollShadow.bottom =
      target.scrollHeight > target.clientHeight && target.scrollTop < target.scrollHeight - target.clientHeight - 20;
  }
}
