export enum ROUTENAMES {
  ADVISOR_SEARCH_INDEX = "advisor_search-index",
  BCA_LOGIN = "bca-login",
  COMPANIES_ASSET_MANAGERS_DASHBOARD = "companies_assets_managers-dashboard",
  COMPANIES_ASSET_MANAGERS_INDEX = "companies_assets_managers-index",
  COMPANIES_ASSET_MANAGERS_PRODUCTS = "companies_assets_managers-products",
  COMPANIES_ASSET_MANAGERS_SHOW = "companies_assets_managers-show",
  COMPANIES_ASSET_MANAGERS_PARTICIPATIONS = "companies_asset_managers-participations",
  COMPANIES_DASHBOARD = "companies-dashboard",
  COMPANIES_PREVIEW = "companies-show-preview",
  COMPANIES_PARTICIPATIONS = "companies-participation",
  COMPANIES_PRODUCTS = "companies-products",
  COMPANIES_SERVICE_PROVIDERS_DASHBOARD = "companies_service_providers-dashboard",
  COMPANIES_SERVICE_PROVIDERS_INDEX = "companies_service_providers-index",
  COMPANIES_SERVICE_PROVIDERS_PRODUCTS = "companies_service_providers-products",
  COMPANIES_SERVICE_PROVIDERS_PARTICIPATIONS = "companies_service_providers-participations",
  COMPANIES_SERVICE_PROVIDERS_SHOW = "companies_service_providers-show",
  COMPANIES_SHOW = "companies-show",
  COMPANIES_WEALTH_MANAGERS_DASHBOARD = "companies_wealth_managers-dashboard",
  COMPANIES_WEALTH_MANAGERS_INDEX = "companies_wealth_managers-index",
  COMPANIES_WEALTH_MANAGERS_PARTICIPATIONS = "companies_wealth_managers-participations",
  COMPANIES_WEALTH_MANAGERS_PRODUCTS = "companies_wealth_managers-products",
  COMPANIES_WEALTH_MANAGERS_SHOW = "companies_wealth_managers-show",
  COMPARISONS_INDEX = "products-comparisons-index",
  COMPARISONS_SHOW = "products-comparisons-show",
  CONTENTS_EXPERT_ARTICLES = "contents_expert_articles-index",
  CONTENT_HUBS_SHOW = "content-hubs-show",
  CONTENT_HUBS_ROOT_SHOW = "content-hubs-empty-show",
  CONTENTS_INDEX = "contents-index",
  CONTENTS_PODCASTS_INDEX = "contents_podcasts-index",
  CONTENTS_PREVIEW = "contents-show-preview",
  CONTENTS_SHOW = "contents-show",
  CONTENTS_VIDEOS_INDEX = "contents_videos-index",
  CONTENTS_WATCHLIST = "contents_watchlist-index",
  CONVERSATIONS_INDEX = "conversations-index",
  CONVERSATIONS_SHOW = "conversations-show",
  EVENTS_INDEX = "events-index",
  EVENTS_SHOW = "events-show",
  HOME = "home",
  IMPRINT = "imprint-index",
  NEWSCENTER_INDEX = "newscenter-index",
  NOTIFICATIONS_INDEX = "notifications-index",
  ONBOARDING_INDEX = "onboarding-index",
  ONBOARDING_SIGN_UP_SUCCESS = "onboarding-sign-up-success",
  PORTFOLIOS_COMPARISON_INDEX = "portfolios_comparison-index",
  PORTFOLIOS_EDIT = "portfolios-edit",
  PORTFOLIOS_INDEX = "portfolios-index",
  PORTFOLIOS_SHOW = "portfolios-show",
  PRIVACY_POLICY = "privacy-policy-index",
  PRODUCTS_ATTACHMENTS_EDIT = "products-attachments-edit",
  PRODUCTS_PEERGROUPS_INDEX = "products-peergroups-index",
  PRODUCTS_PEERGROUPS_SHOW = "products-peergroups-show",
  PRODUCTS_RANKING_INDEX = "products-ranking-index",
  PRODUCTS_RANKING_START = "products-ranking-start",
  PRODUCTS_SEARCH_INDEX = "products-search-index",
  PRODUCTS_SHOW = "products-show",
  PRODUCTS_WATCHLIST_INDEX = "products-watchlist-index",
  SEARCH_INDEX = "search-index",
  TERMS_AND_CONDITIONS = "terms-and-conditions-index",
  USERS_ARTICLES_INDEX = "users_articles-index",
  USERS_EDIT = "users-edit",
  USERS_PRIVACY_SETTINGS = "users-settings-privacy",
  USERS_SETTINGS = "users-settings",
  USERS_SETTINGS_SUBSCRIPTION_MANAGEMENT = "users-settings-subscription-management",
  USERS_SHOW = "users-show",
  WEBCASTS_INDEX = "webcasts-index",
}
