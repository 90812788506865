import axios from "axios";
import { BaseApiService } from "./base";
import { gtmTrackingService } from "@services/gtm-tracking.service";

const API_ENDPOINT = "/api/v4/portfolios";
export class PortfolioApiService extends BaseApiService implements PortfoliosApi.Service {
  public axiosInstance = axios;

  public all: PortfoliosApi.AllFn = (params = {}) => this.axios.get(API_ENDPOINT, { params });
  public get: PortfoliosApi.GetFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}`);
  public followed: PortfoliosApi.FollowedFn = (params = {}) => this.axios.get(`${API_ENDPOINT}/followed`, { params });
  public create: PortfoliosApi.CreateFn = (data) =>
    this.axios.post(API_ENDPOINT, data).then((response) => {
      const eventAction = data.tracking_event_action === "upload" ? "PortfolioUpload" : "PortfolioCreation";
      gtmTrackingService.track({ eventCategory: "Portfolio", eventAction });
      return response;
    });

  public search: PortfoliosApi.SearchFn = (params = {}) => this.axios.get(`${API_ENDPOINT}/search`, { params });

  public duplicate: PortfoliosApi.DuplicateFn = (portfolioId) =>
    this.axios.post(`${API_ENDPOINT}/${portfolioId}/duplicate`);

  public update: PortfoliosApi.UpdateFn = (id, data) => this.axios.patch(`${API_ENDPOINT}/${id}`, data);
  public toggleFollow: PortfoliosApi.ToggleFollowFn = (id, state) => this.axios.patch(`${API_ENDPOINT}/${id}/${state}`);
  public remove: PortfoliosApi.RemoveFn = (id) => this.axios.delete(`${API_ENDPOINT}/${id}`);
  public history: PortfoliosApi.HistoryFn = (id, params) => this.axios.get(`${API_ENDPOINT}/${id}/changes`, { params });
  public followers: PortfoliosApi.FollowersFn = (id, params = {}) =>
    this.axios.get(`${API_ENDPOINT}/${id}/followers`, { params });

  public addProductsToCollection: PortfoliosApi.AddProductsToCollectionFn = async (productIds, portfolioIds) =>
    this.axios.patch(`${API_ENDPOINT}/add_to_portfolios`, { product_ids: productIds, portfolio_ids: portfolioIds });

  public upload: PortfoliosApi.UploadFn = (file) => {
    const uploader = this.axiosInstance.create();
    uploader.defaults.headers.common = Object.assign({}, this.axios.defaults.headers.common);
    delete uploader.defaults.headers.common["content-type"];

    const data = new FormData();
    data.append("portfolio_upload[file]", file);

    return uploader.post("/api/v4/portfolio_uploads", data);
  };
}
