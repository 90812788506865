import { render, staticRenderFns } from "./foldable-text.vue?vue&type=template&id=165dc012&scoped=true&lang=pug"
import script from "./foldable-text.vue?vue&type=script&lang=ts"
export * from "./foldable-text.vue?vue&type=script&lang=ts"
import style0 from "./foldable-text.vue?vue&type=style&index=0&id=165dc012&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "165dc012",
  null
  
)

export default component.exports