var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      staticClass: "ci-dropdown",
      on: { mouseenter: _vm.handleHover, mouseleave: _vm.handleHover },
    },
    [
      _c(
        "div",
        {
          ref: "toggle",
          staticClass: "toggle",
          class: {
            active: _vm.expanded && _vm.showActiveState,
            disabled: _vm.disabled,
          },
          on: { click: _vm.handleClick },
        },
        [_vm._t("button-content", null, { expanded: _vm.expanded })],
        2
      ),
      _c("div", { ref: "dropdownMenu", staticClass: "ci-dropdown-content" }, [
        _vm.expanded
          ? _c(
              "div",
              {
                staticClass: "ci-dropdown-content-card ci-card",
                attrs: { "with-border": true },
              },
              [_c("div", { class: _vm.contentClass }, [_vm._t("default")], 2)]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }