import { configure, extend } from "vee-validate";
import { required, confirmed, min, email, regex, max } from "vee-validate/dist/rules.umd.js";
import { i18nInstance } from "@composables/i18n";

export const validateDateAfter = (value: any, { target }: any): boolean => new Date(value) > new Date(target);
export const validateDateBefore = (value: any, { target }: any): boolean => new Date(value) < new Date(target);

export const validateDateBeforeNow = (value: any): boolean => new Date(value) < new Date();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const validateFalse = (_: any): boolean => false;

export const ValidationSetup = (): void => {
  configure({
    defaultMessage: (field, values) => {
      if (!field || !values) return "";
      /* eslint-disable-next-line */
      return i18nInstance.t(`validation.${values._rule_}`, values) as string;
    },
    mode: function (a) {
      return {
        on: a.errors.length > 0 ? ["input", "change"] : ["blur"],
      };
    },
  });

  extend("required", {
    ...required,
  });

  extend("regex", {
    ...regex,
  });

  extend("confirmed", {
    ...confirmed,
  });

  extend("min", {
    ...min,
  });

  extend("max", {
    ...max,
  });

  extend("email", {
    ...email,
  });

  extend("dateAfter", {
    params: ["target"],
    validate: validateDateAfter,
  });

  extend("dateBefore", {
    params: ["target"],
    validate: validateDateBefore,
  });

  extend("dateBeforeNow", { validate: validateDateBeforeNow });

  extend("validateFalse", { validate: validateFalse });
};

ValidationSetup();
