var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        ref: "interest_tags_container",
        staticClass: "interest-tags w-100",
        class: _vm.lineClampClass,
      },
      [
        _c(
          "div",
          {
            ref: "interest_tags_wrapper",
            staticClass:
              "interest-tags__wrapper d-flex flex-wrap justify-content-start align-items-center",
          },
          _vm._l(_vm.interestTags, function (tag, index) {
            return _c("ci-tag", {
              key: `${_vm.containerId}_${tag.name}_${index}`,
              staticClass: "ci-tag mr-2 my-1",
              attrs: {
                context: _vm.context,
                tagName: tag.name,
                tagId: tag.id,
                tag: tag,
                variant: _vm.variant,
                trackingIdentifierOverride: _vm.tagTrackingIdentifierOverride,
                trackingTargetName: _vm.tagTrackingTargetName,
              },
              model: {
                value: _vm.selected,
                callback: function ($$v) {
                  _vm.selected = $$v
                },
                expression: "selected",
              },
            })
          }),
          1
        ),
        _vm.clamp > 0 && _vm.showExpandCta
          ? _c("div", { staticClass: "interest-tags__cover" }, [
              _c("span", {
                staticClass:
                  "cursor-pointer toggletags mdi mdi-chevron-down my-2",
                class: _vm.expandedClass,
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      `user_popup.section_toggle.${
                        _vm.expanded ? "hide" : "show"
                      }`
                    )
                  ),
                },
                on: { click: _vm.toggleClamp },
              }),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }