import { BaseApiService } from "./base";

const API_ENDPOINT = "/users/sign_in";

export class SessionApiService extends BaseApiService implements SessionApi.Service {
  public login: SessionApi.LoginFn = (email, password, rememberMe) => {
    const params = { user: { email, password, remember_me: rememberMe } };
    return this.axios.post(API_ENDPOINT, params);
  };
}
