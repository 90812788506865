import VueRouter from "vue-router";

import { Vue } from "vue-property-decorator";
import { Routes } from "@router/routes";
import { RouterHooks } from "./hooks";
import { Route } from "vue-router/types/router";
import { i18nInstance } from "@composables/i18n";
import { getLocaleFromSettings } from "@utils/i18n/i18n.instance";
import { utilityService } from "@services/utility.service";

export const Router = new VueRouter({
  mode: "history",
  routes: Routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // the "official" vue way (see return below) does not work reliably
      const hash = decodeURIComponent(to.hash);
      // empty hash or invalid hash used by Chrome to highlight searched elements or invalid hash from facebook login
      if (hash === "#" || hash.startsWith("#:~:") || hash.startsWith("#_=_")) return null;
      const anchor = hash.startsWith("#") ? hash.substring(1) : hash;
      scrollToAnchor(anchor);
      return { selector: hash, behavior: "smooth" };
    }
    if (to.path === from.path) return null;
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

const scrollToAnchor = (anchor: string, retryCount: number = 0): void => {
  // with dynamic content loading we may need more then one tick, currently arbitrarily stopping after max 5 tries
  Vue.nextTick(() => {
    if (anchor && document.getElementById(anchor)) {
      document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth" });
    } else {
      if (retryCount < 5) {
        setTimeout(() => scrollToAnchor(anchor, retryCount + 1), 100);
      }
    }
  });
};

Router.beforeEach((to, from, next) => {
  RouterHooks.beforeEachHook(to, from, next);
});

Router.afterEach((to, from) => {
  RouterHooks.afterEachHook(to, from);
});

const originalRouterResolve = Router.resolve.bind(Router);

Router.resolve = (to: RawLocation, current?: Route | undefined, append?: boolean | undefined) => {
  if (typeof to === "object" && i18nInstance.locale !== getLocaleFromSettings()) {
    const toLocationCopy = utilityService.deepCopy(to);
    toLocationCopy.params = { locale: i18nInstance.locale, ...(toLocationCopy.params || null) };
    return originalRouterResolve(toLocationCopy, current, append);
  }

  return originalRouterResolve(to, current, append);
};

const originalRouterPush = Router.push.bind(Router);

Router.push = (to: RawLocation) => {
  const { location } = Router.resolve(to);

  return originalRouterPush(location).catch((error) => {
    if (error.toString().match("NavigationDuplicated") || error.toString().match("Navigation cancelled")) {
      return Router.currentRoute;
    } else {
      throw new Error(error);
    }
  });
};

const originalRouterReplace = Router.replace.bind(Router);

Router.replace = (to: RawLocation) => {
  const { location } = Router.resolve(to);
  return originalRouterReplace(location);
};
