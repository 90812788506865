
import { computed, defineComponent, nextTick, onMounted, Ref, ref, toRef } from "vue";

export default defineComponent({
  props: {
    height: {
      type: Number,
      default: null,
    },
    fade: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const collapsed = ref(true);
    const containerHeight: Ref<Nullable<number>> = ref(null);
    const containerInner: Ref<Nullable<HTMLElement>> = ref(null);
    const fade = toRef(props, "fade");
    const height = toRef(props, "height");

    const classes = computed(() =>
      `${collapsed.value ? "collapsed" : "expanded"} ${fade.value && collapsed.value ? "fading" : ""}`.trim()
    );
    const style = computed(() => ({
      maxHeight: collapsed.value ? (height.value ? `${height.value}px` : "100px") : "initial",
    }));
    const showTrigger = computed(() => {
      if (containerHeight.value) return height.value < containerHeight.value;
      else return true;
    });

    const toggle = () => {
      collapsed.value = !collapsed.value;
    };

    onMounted(async () => {
      await nextTick();
      containerHeight.value = containerInner.value?.offsetHeight || 0;
    });

    return { collapsed, classes, style, showTrigger, toggle, containerInner };
  },
});
