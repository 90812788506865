
import { computed, ComputedRef, defineComponent, getCurrentInstance, onMounted, PropType, Ref, ref, toRef } from "vue";
import { TranslateResult } from "vue-i18n";
import { getModule } from "vuex-module-decorators";
import { Options } from "highcharts";
import { Constants } from "@app/constants";
import CiCheckbox from "@components/base/forms/checkbox.vue";
import CiSfdr from "@components/base/sfdr.vue";
import { useI18n } from "@composables/i18n";
import useProductAlarm from "@composables/productAlarm";
import { EventCategory } from "@models/enums";
import { chartService } from "@services/charts.service";
import { ciTrackingService } from "@services/ci-tracking.service";
import { chartApiService } from "@services/performance_charts/chart-api.service";
import { ViewportStoreModule } from "@stores/viewport.store";
import { buildURL } from "@utils/build-url";
import { Emitter } from "@utils/events";

const ViewportStore = getModule(ViewportStoreModule);

export default defineComponent({
  name: "CiProductTile",
  components: {
    "ci-checkbox": CiCheckbox,
    "ci-product-mediathek-label": () => import("@components/products/mediathek-label.vue"),
    "ci-sfdr": CiSfdr,
  },
  props: {
    product: { type: Object as PropType<Products.TileData>, required: true },
    withBorder: { type: Boolean, default: false },
    followable: { type: Boolean, default: true },
    link: { type: Boolean, default: true },
    context: { type: String as PropType<"default" | "search" | "recommendation" | "watchlist">, default: "default" },
    portfolioId: { type: String as PropType<Nullable<string>>, default: null },
    additionalTracking: { type: Object as PropType<Nullable<{ [id: string]: string }>>, default: () => ({}) },
    useSmallTile: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: false },
    showEmptyChartMessage: { type: Boolean, default: false },
    isCheckboxDisabled: { type: Boolean, default: false },
    showPerformanceAlarm: { type: Boolean, default: false },
    value: { type: Boolean },
  },
  emits: ["input", "on-unfollow"],
  setup(props, { emit }) {
    const product: Ref<Products.TileData> = toRef(props, "product");
    const link = toRef(props, "link");
    const withBorder = toRef(props, "withBorder");
    const additionalTracking = toRef(props, "additionalTracking");
    const chartData: Ref<Nullable<Options>> = ref(null);
    const chartInitialized = ref(false);
    const contextMenuOpen = ref(false);
    const eventCategory: Ref<EventCategory> = ref(EventCategory.Product);
    const hasChartData = ref(false);
    const { t } = useI18n(getCurrentInstance());

    const { alarmButtonDisabled, productAlarmTooltipContent, confirmProductAlarmRemoval } = useProductAlarm(product);

    function openProductAlarmModal() {
      ciTrackingService.trackClick("ButtonProductAlert", props.product.id.toString());
      Emitter.emit("ci::product::refetch");
      Emitter.emit("ci::product-alarm::set-product-id", props.product.id);
    }

    const isMobile = computed(() => ViewportStore.smartphone);

    const linkAttributes = computed(() => {
      if (link.value) {
        if (!additionalTracking.value) return { to: product.value.url };

        return { to: buildURL(product.value.url, { searchParams: additionalTracking.value }) };
      } else return { to: "", disabled: true };
    });

    const classes = computed(() => {
      const classes: Indexable = {
        "context-menu": contextMenuOpen.value,
        "with-hover": true,
        "with-border": withBorder.value,
      };
      classes[`tile-context-${props.context}`] = true;
      return classes;
    });

    const information: ComputedRef<{
      [key: string]: { label: string | TranslateResult; value: Nullable<string | number> | undefined };
    }> = computed(() => {
      let info: {
        [key: string]: { label: string | TranslateResult; value: Nullable<string | number> | undefined };
      } = {};
      if (props.context === "recommendation") {
        info = {
          performance_1y: {
            label: t("ui.shared.global.metrics.performance.abbr_with_year_period", { period: 1 }) as string,
            value: product.value.performance[`performance_1_year`],
          },
          performance_3y: {
            label: t("ui.shared.global.metrics.performance.abbr_with_year_period", { period: 3 }) as string,
            value: product.value.performance[`performance_3_years`],
          },
          volatility_1y: {
            label: t("ui.shared.global.metrics.volatility.abbr_with_year_period", { period: 1 }) as string,
            value: product.value.ratios_euro[`volatility_1_year`],
          },
          sum_ongoing_charges: {
            label: t("ui.shared.products.attributes.ongoing_charges_abbr") as string,
            value: product.value.sum_ongoing_charges || "-",
          },
          distribution_type: {
            label: t("ui.shared.products.attributes.distribution_type") as string,
            value: product.value.distribution_type,
          },
          focus_region: {
            label: t("ui.shared.products.attributes.focus_region") as string,
            value: product.value.focus_region,
          },
          focus_asset: {
            label: t("ui.shared.products.attributes.focus_asset") as string,
            value: product.value.focus_asset,
          },
        };
      } else if (props.context === "watchlist") {
        info = {
          performanceForOneYear: {
            label: t("products.followed.table.heads.performance_1_year"),
            value: product.value.performance.performance_1_year || undefined,
          },
          performanceSinceInception: {
            label: t("ui.shared.products.attributes.since_inception"),
            value: product.value.performance.performance_since_inception || undefined,
          },
          focus_asset: {
            label: t("ui.shared.products.attributes.focus_asset") as string,
            value: product.value.focus_asset,
          },
        };
      } else {
        info = {
          performance: {
            label: performanceTimeframe.value?.label || "",
            value: product.value.performance[`performance_${performanceTimeframe.value?.partial}`],
          },
          volatility: {
            label: t("ui.shared.global.metrics.volatility.abbr_with_year_period", { period: 1 }) as string,
            value: product.value.ratios_euro[`volatility_${volatilityTimeframe.value?.partial}`],
          },
          focus_asset: {
            label: t("ui.shared.products.attributes.focus_asset") as string,
            value: product.value.focus_asset,
          },
        };
      }

      return info;
    });

    const performanceTimeframe: ComputedRef<Nullable<{ label: string; partial: string }>> = computed(() => {
      if (product.value.performance.performance_1_year)
        return {
          label: t("ui.shared.global.metrics.performance.abbr_with_year_period", { period: 1 }) as string,
          partial: "1_year",
        };
      else {
        if (product.value.performance.performance_6_months)
          return {
            label: t("ui.shared.global.metrics.performance.abbr_with_month_period", { period: 6 }) as string,
            partial: "6_months",
          };
        if (product.value.performance.performance_3_months)
          return {
            label: t("ui.shared.global.metrics.performance.abbr_with_month_period", { period: 3 }) as string,
            partial: "3_months",
          };
        if (product.value.performance.performance_1_month)
          return {
            label: t("ui.shared.global.metrics.performance.abbr_with_month_period", { period: 1 }) as string,
            partial: "1_month",
          };
      }

      return null;
    });

    const volatilityTimeframe: ComputedRef<Nullable<{ label: string; partial: string }>> = computed(() =>
      product.value.ratios_euro.volatility_1_year
        ? {
            label: t("ui.shared.global.metrics.volatility.abbr_with_year_period", { period: 1 }) as string,
            partial: "1_year",
          }
        : null
    );

    const trackingData: ComputedRef<GTMTracking.GaTrackingEvent> = computed(() => ({
      eventCategory: "Product",
      eventAction: "Button DetailsOpen",
      eventLabel: `product_id : ${product.value.id} ; isin : ${product.value.isin}`,
    }));

    const volumeValue: ComputedRef<number | undefined> = computed(() => product.value.volume?.EUR?.value || 0);

    const checkboxChangeHandler = (isChecked: boolean) => {
      emit("input", isChecked);
    };

    const onUnfollow = () => {
      emit("on-unfollow");
    };

    const fetchChartData = async (): Promise<void> => {
      try {
        const response = await chartApiService.get([product.value.isin], { range: "max" });

        if (response.length === 0) {
          console.error("No chart data for: ", product.value.isin);
          return;
        }

        const negativeFactor =
          props.context === "watchlist"
            ? product.value.performance.performance_since_inception
            : product.value.performance.performance_1_year;

        const data = response[0].ts;
        chartInitialized.value = data.length > Constants.productPerformanceChartDataThreshold;
        if (chartInitialized.value) {
          chartData.value = chartService.backgroundChart(data, true, (negativeFactor || 0) < 0);
          hasChartData.value = true;
        }
      } catch (err) {
        console.error(err);
        hasChartData.value = false;
      }
    };

    onMounted(() => {
      fetchChartData();
    });

    const performanceAlarmVisible = computed(() => props.product && props.showPerformanceAlarm);

    return {
      chartData,
      chartInitialized,
      confirmProductAlarmRemoval,
      contextMenuOpen,
      eventCategory,
      information,
      trackingData,
      performanceTimeframe,
      volatilityTimeframe,
      linkAttributes,
      classes,
      volumeValue,
      isMobile,
      checkboxChangeHandler,
      hasChartData,
      onUnfollow,
      alarmButtonDisabled,
      performanceAlarmVisible,
      productAlarmTooltipContent,
      openProductAlarmModal,
    };
  },
});
