var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "omniauth-buttons d-flex justify-content-center" },
    [
      _vm._l(_vm.providers, function (provider) {
        return [
          _c("ci-button-to", {
            directives: [
              {
                name: "ga-track-click",
                rawName: "v-ga-track-click",
                value: provider.tracking,
                expression: "provider.tracking",
              },
            ],
            staticClass: "h2 m-2 text-primary",
            attrs: {
              url: provider.base_url,
              icon: _vm.providerIconClass(provider.name),
              tooltip: _vm.capitalize(provider.name),
              extraParams: _vm.extraParams,
              authenticityToken: _vm.authenticityToken,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }