import { routingService } from "@services/routing.service";
import { utilityService } from "@services/utility.service";
import { Component } from "vue-property-decorator";
import { NavigationGuardNext, Route } from "vue-router";
import { CurrentUser } from "@utils/user";
import { Emitter } from "@utils/events";

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

export const RouterHooks = {
  /*
   * The very first hook will pretend to be from "/" which is not true
   * The other parameters like matched and route name seem to be null for the very first route so we rely on
   * this for right now.
   */
  beforeEachHook: (to: Route, from: Route, next: NavigationGuardNext): void => {
    Emitter.emit("ci::routing::start");

    if (from.meta?.spa) {
      utilityService.timeme.sendCurrentTime();
      routingService.initializePage(to, from);
    }
    if (to.meta!.gated && !CurrentUser.signedIn) window.location.replace("/users/sign_in");

    next();
  },
  afterEachHook: (to: Route, from: Route): void => {
    Emitter.emit("ci::routing::stop", { to: to, from: from });
    if (from.meta?.spa) {
      utilityService.timeme.setCurrentPageName(window.location.origin + to.fullPath);
      utilityService.timeme.startTimer();
    }
  },
};
