
import { Component, Prop } from "vue-property-decorator";
import { FormElementBase } from "./base";
import { ValidationProvider } from "vee-validate";

@Component({
  name: "ci-input",
  components: { ValidationProvider },
})
export default class extends FormElementBase {
  @Prop({ default: "Value " }) public value!: string;
  @Prop({ default: "text" }) public type!: "text" | "email" | "hidden";
  @Prop({ default: "" }) public inputClass!: string;
  @Prop({ default: false }) public readonly!: boolean;

  public handleInput(ev: ExplicitAny): void {
    this.$emit("input", ev.target.value);
    const el = ev.target;
    const { selectionStart } = el;

    /*
     * resets the cursor position to previous position
     * needed because v-model puts the cursor to end of input
     */

    if (this.type !== "email") {
      this.$nextTick(() => {
        this.$nextTick(() => {
          el.setSelectionRange(selectionStart, selectionStart);
        });
      });
    }
  }
}
