var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-reminder",
        centered: "",
        visible: "",
        "hide-footer": "",
        "hide-header": "",
        "body-class": "p-0",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "row justify-content-center align-items-center onboarding m-0 overflow-hidden",
        },
        [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "text-center mb-1 h-100" }, [
              _c("div", {
                staticClass: "signup-success-decoration-circle-second",
              }),
              _c("div", { staticClass: "signup-success-decoration-circle" }),
              _c(
                "div",
                {
                  staticClass:
                    "row justify-content-center align-items-center h-100",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-10 col-md-8" },
                    [
                      _c("inline-svg", {
                        staticClass: "mt-5",
                        attrs: { src: _vm.mailEnvelope },
                      }),
                      _c("h5", {
                        staticClass: "mt-3",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("confirmation.reminder_text")
                          ),
                        },
                      }),
                      _c("p", {
                        staticClass: "mt-3",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "confirmation.remaining_time_reminder_html",
                              { remaining_time: _vm.distanceTimeWords }
                            )
                          ),
                        },
                      }),
                      _c("div", { staticClass: "mt-3 mb-5" }, [
                        _c("small", [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "ui.components.users.onboarding.email_not_received"
                                )
                              ),
                            },
                          }),
                          _c("a", {
                            staticClass: "cursor-pointer ml-1",
                            attrs: { id: "resend-email" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "ui.components.users.onboarding.email_resend"
                                )
                              ),
                            },
                            on: { click: _vm.resendConfirmationMail },
                          }),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }