import algoliasearch, { SearchClient } from "algoliasearch";

import { settingsService } from "@services/settings.service";

class Service {
  public readonly NEWS_TILE_ATTRIBUTES_TO_RETRIEVE = [
    "id",
    "image_url",
    "title_de",
    "teaser_de",
    "url",
    "copyright",
    "type",
    "publication_date",
    "grade",
    "outlet",
    "interest_tags",
  ];

  private readonly pClient: SearchClient;

  constructor() {
    const appId = settingsService.options!.algolia_application_id;
    const readKey = settingsService.algoliaRegionalizedLiveSearchKey;
    this.pClient = algoliasearch(appId, readKey);
  }

  public getClient(): SearchClient {
    return this.pClient;
  }

  public getAdminClient() {
    return algoliasearch(
      settingsService.options!.algolia_application_id,
      settingsService.options.algolia_live_search_key
    );
  }

  public mapAlgoliaContent(algoliaContent: Articles.AlgoliaContent): Articles.TileData {
    const type = algoliaContent.type as Articles.ContentType;

    return {
      id: algoliaContent.id,
      img: {
        url: algoliaContent.image_url,
        params: {},
      },
      title: algoliaContent.title_de,
      teaser: algoliaContent.teaser_de,
      url: algoliaContent.url,
      watched: false,
      type: type,
      publication_date: new Date(algoliaContent.publication_date * 1000).toISOString(),
      time_consumption: null,
      video: type === "Video" ? { id: 0, type: "" } : null,
      outlet: algoliaContent.outlet,
      copyright: null,
      interest_tags: algoliaContent.interest_tags,
    };
  }
}

export const algoliaService = new Service();
