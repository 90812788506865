import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/content_routes";

export class ContentHubsApiService extends BaseApiService implements ContentHubsApi.Service {
  public getContentRoute: ContentHubsApi.GetContentRouteFn = (slug: string) =>
    this.axios.get(`${API_ENDPOINT}/${slug || ""}`);

  public getArticles: ContentHubsApi.GetArticlesFn = (slug: string, page?: number) =>
    this.axios.get(`${API_ENDPOINT}/${slug}/articles`, { params: { page: page } });

  public getProducts: ContentHubsApi.GetProductsFn = (slug: string, page?: number) =>
    this.axios.get(`${API_ENDPOINT}/${slug}/products`, { params: { page: page } });

  public getRelatedContentRoutes: ContentHubsApi.GetRelatedContentRoutesFn = (slug: string | undefined) => {
    if (slug) return this.axios.get(`${API_ENDPOINT}/${slug}/related`);
    else return this.axios.get(`${API_ENDPOINT}/related`);
  };

  public getActiveContentRoutes: ContentHubsApi.GetActiveContentRoutes = (nested: boolean) =>
    this.axios.get(`${API_ENDPOINT}/active`, { params: { top_level: nested } });

  public getPopularContentRoutes: ContentHubsApi.GetPopularContentRoutes = (popular: boolean) =>
    this.axios.get(`${API_ENDPOINT}/active`, { params: { popular: popular } });
}
