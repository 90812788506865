import { settingsService } from "@services/settings.service";
import { Constants } from "@app/constants";

class Service implements Routing.Service {
  // The very first from we get from the vue router does not reflect the referrer so we default to the document referrer
  private localReferrer: string = document.referrer;

  public get referrer(): string {
    return settingsService.options.app_request ? Constants.ciTracking.appReferrer : this.localReferrer;
  }

  public initializePage: Routing.InitializePageFn = (to, from) =>
    new Promise((resolve) => {
      this.localReferrer = location.origin + from.fullPath;
      resolve();
    });
}

export const routingService = new Service();
