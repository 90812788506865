
import { ciTrackingService } from "@services/ci-tracking.service";
import { Vue, Component, Prop, Inject } from "vue-property-decorator";
import { SharingNetworks } from "@models/enums";
import DropdownMenu from "./dropdown-menu.vue";
import DropdownItem from "./dropdown-item.vue";
import { CreateElement } from "vue";

type NetworkWithOptions = {
  attributes: {
    network: SharingNetworks;
    tag: string;
    url: string;
    title: string;
    description: string;
    quote: string;
  };
  disabled: boolean;
  icon: string;
  label: string;
  gated: boolean;
  internal: boolean;
  event: string;
};

const ShareNetworkDisabled = Vue.extend({
  render(h: CreateElement) {
    return h("div", this.$slots.default);
  },
});

@Component({
  name: "ci-share",
  components: {
    "ci-dropdown-menu": DropdownMenu,
    "ci-dropdown-item": DropdownItem,
    "share-network-disabled": ShareNetworkDisabled,
  },
})
export default class extends Vue {
  @Prop({ required: true }) public url!: string;
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public trackingTarget!: CiTracking.TrackingTarget;
  @Prop({ default: "" }) public description!: string;
  @Prop({ default: "" }) public quote!: string;
  @Prop({ default: true }) public dropdown!: boolean;
  @Prop({ default: "" }) public label!: string;
  @Prop({}) public preventToggle?: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: "left" }) public dropdownPosition!: string;
  @Prop({ default: "" }) public type!: string;
  @Prop({ default: () => Object.values(SharingNetworks) }) public networks!: SharingNetworks[];
  @Prop({ default: () => [] }) public disabledNetworks!: SharingNetworks[];
  @Prop({ default: () => null }) public networkTooltips!: Indexable;

  @Prop({ default: false }) public gated!: boolean;
  @Prop({ default: "login" }) public gatingType!: "login" | "registration";
  @Prop({ default: false }) public professional!: boolean;
  @Prop({ default: "" }) public tooltip!: string;
  @Prop({}) public buttonVariant?: Components.Button.Variant;

  @Inject({ default: null }) public trackingUserType!: Nullable<string>;

  public get networksWithOptions(): NetworkWithOptions[] {
    return this.networks.map((network) => ({
      attributes: {
        network: network,
        tag: "span",
        url: this.url,
        title: this.title,
        description: this.description,
        quote: this.quote,
      },
      disabled: this.getDisabledState(network),
      icon: this.fetchNetworkIcon(network),
      label: this.$t(`social_share_button.${network}`) as string,
      tooltip: this.networkTooltips ? this.networkTooltips[network] : "",
      gated: network === "custom_messaging" && !this.$user.signedIn,
      internal: network === "custom_messaging",
      event: `share-via-${network}`,
    }));
  }

  public get sharingData(): Indexable {
    return {
      url: this.url,
      title: this.title,
      type: this.type,
    };
  }

  public get togglePrevented(): boolean {
    return typeof this.preventToggle !== "undefined" ? this.preventToggle : !this.$user.signedIn;
  }

  public emitNetworkEvent(network: NetworkWithOptions): void {
    if (!this.disabled && !network.disabled) {
      this.trackNetworkEvent(network);
      this.$root.$emit(network.event, this.sharingData);
    }
  }

  public getDisabledState(network: SharingNetworks): boolean {
    return this.disabledNetworks.includes(network);
  }

  private trackNetworkEvent(network: NetworkWithOptions): void {
    ciTrackingService.track({
      event: {
        action: "share",
        value: Object.values(SharingNetworks).indexOf(network.attributes.network),
      },
      target: this.trackingTarget,
      source: {
        name: this.trackingUserType,
        identifier: this.url,
      },
    });
  }

  private fetchNetworkIcon(network: string): string {
    if (network === SharingNetworks.CustomEmail) return "mdi-email";
    if (network === SharingNetworks.CustomMessaging) return "mdi-message-text";

    return `mdi-${network}`;
  }
}
