import { CurrentUser } from "@utils/user";
import Cookies from "js-cookie";
import { ref, Ref } from "vue";

const override: Ref<Nullable<boolean>> = ref(null);

export const useConfirmationReminder = () => ({
  showConfirmationReminder: (): boolean => {
    if (!CurrentUser.signedIn || !CurrentUser.confirmation_remaining_time_days) return false;
    if (override.value !== null) {
      // reset the override
      const { value } = override;
      override.value = null;
      return value;
    }

    const reminderLastShownAt = Cookies.get("confirmation_reminder_shown_time");
    if (reminderLastShownAt === undefined || Date.now() - Date.parse(reminderLastShownAt) > 12 * 3600 * 1000) {
      Cookies.set("confirmation_reminder_shown_time", String(new Date().getTime()), { expires: 14 });
      return true;
    }

    return false;
  },
  override: override,
});
