var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ci-toaster toaster-top-right" }, [
    _vm.toasts.length
      ? _c(
          "div",
          { staticClass: "toaster-slot" },
          _vm._l(_vm.toasts, function (toast) {
            return _c(
              "Toast",
              _vm._b(
                { key: toast.id, staticClass: "mb-2" },
                "Toast",
                toast,
                false
              )
            )
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }