import { CreateToast } from "@utils/toasts";
import { i18nInstance } from "@composables/i18n";
import { DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";
const copyToClipboard = async (binding: DirectiveBinding): Promise<void> => {
  try {
    await navigator.clipboard.writeText(binding.value.toString());
    CreateToast.success(i18nInstance.t("successfully_copied_to_clipboard") as string);
  } catch (err) {
    console.error(err);
  }
};
const directive: DirectiveOptions = {
  bind(element, binding) {
    element.classList.add("btn-copy-to-clipboard");
    if (binding.value) {
      element.classList.add("btn-cursor");
      if (!navigator.clipboard) {
        element.remove();
      } else {
        element.addEventListener("click", async () => {
          copyToClipboard(binding);
        });
      }
    }
  },
};

export default directive;
