
import { CreateElement } from "vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { VNode } from "vue/types/umd";

@Component({
  name: "ci-router-link",
})
export default class extends Vue {
  @Prop({ required: true }) to!: RawLocation;
  @Prop({ default: "page" }) ariaCurrentValue!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({}) exact!: boolean;
  @Prop({}) exactPath!: boolean;
  @Prop({}) append!: boolean;
  @Prop({}) replace!: boolean;
  @Prop({}) activeClass!: string;
  @Prop({}) exactActivePath!: string;
  @Prop({}) gated!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  public get isDirectLink(): boolean {
    return typeof this.to === "string" && this.to.startsWith("http");
  }

  public get isSinglePageApplication(): boolean {
    return this.$page.context === "spa";
  }

  protected render(h: CreateElement): VNode {
    if (this.disabled) {
      return h(
        "span",
        {
          attrs: { disabled: this.disabled, ...this.$attrs },
        },
        this.label || this.$slots.default
      );
    } else if (this.gated) {
      return h(
        "a",
        {
          attrs: this.$attrs,
          class: "cursor-pointer",
          directives: [{ name: "b-modal", value: "modal-login" }],
        },
        this.$slots.default
      );
    }
    const resolveResult = this.$router.resolve(this.to);
    if (this.isDirectLink || !this.isSinglePageApplication || !resolveResult.route.meta!.spa) {
      return h(
        "a",
        {
          attrs: { href: this.isDirectLink ? this.to : resolveResult.href, ...this.$attrs },
          on: {
            click: async (event: PointerEvent) => {
              this.$emit("click", event, false, this.isDirectLink ? this.to : resolveResult.href);
            },
          },
        },
        this.label || this.$slots.default
      );
    } else {
      return h(
        "router-link",
        {
          props: this.$props,
          ...this.$attrs,
          nativeOn: {
            click: async (event: PointerEvent) => {
              this.$emit("click", event, true);
            },
          },
        },
        this.label || this.$slots.default
      );
    }
  }
}
