var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: { open: _vm.open }, attrs: { id: "notifications-popup-wrapper" } },
    [
      _c("div", {
        staticClass: "backdrop",
        on: {
          click: function ($event) {
            return _vm.close(true)
          },
        },
      }),
      _c("div", { staticClass: "notifications-popup ci-card shadow-dark" }, [
        _c("header", { staticClass: "popup-header px-3 pt-3 shadow-sm" }, [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("h5", {
                staticClass: "popup-title flex-grow-1 mb-0",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("ui.components.notifications.popup.title")
                  ),
                },
              }),
              _c("ci-button", {
                attrs: {
                  id: "close-button",
                  icon: "mdi-cog",
                  to: {
                    name: _vm.$routes.USERS_SETTINGS,
                    query: { tab: "notifications" },
                  },
                  "aria-label": _vm.$t(
                    "ui.components.notifications.popup.settings_button"
                  ),
                },
                on: { click: _vm.close },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex align-items-center pb-3" },
            [
              _c("ci-checkbox", {
                class: _vm.showMarkAllAsReadButton ? "" : "pt-1 mb-1",
                attrs: {
                  name: "only_unread_popup",
                  value: _vm.currentTab,
                  disabled: _vm.loading,
                  label: _vm.$t(
                    "ui.components.notifications.popup.show_only_unread"
                  ),
                  toggle: true,
                },
                on: { input: _vm.setCurrentTab },
              }),
              _vm.showMarkAllAsReadButton
                ? _c("ci-button", {
                    staticClass: "ml-auto",
                    attrs: {
                      id: "mark-as-read",
                      size: "sm",
                      variant: "ghost-primary",
                      label: _vm.$t(
                        "ui.components.notifications.popup.mark_all_as_read"
                      ),
                    },
                    on: { click: _vm.handleReadAllClick },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "section",
          { staticClass: "popup-body pt-3" },
          [
            _c(
              "b-tabs",
              {
                staticClass: "ci-tabs",
                attrs: { replaceUrl: false, "nav-wrapper-class": "d-none" },
                model: {
                  value: _vm.currentTab,
                  callback: function ($$v) {
                    _vm.currentTab = $$v
                  },
                  expression: "currentTab",
                },
              },
              [
                _c(
                  "b-tab",
                  [
                    _vm.list.length
                      ? _c("NotificationsList", {
                          attrs: {
                            id: "regular-list",
                            items: _vm.list,
                            loading: _vm.loading,
                            "handheld-style": true,
                            type: "all",
                            location: "popup",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "b-tab",
                  [
                    _c("NotificationsList", {
                      attrs: {
                        id: "filtered-list",
                        items: _vm.filtered.popup.unread,
                        loading: _vm.loading,
                        "handheld-style": true,
                        type: "unread",
                        location: "popup",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "footer",
          { staticClass: "popup-footer p-3" },
          [
            _c("ci-button", {
              staticClass: "w-100",
              attrs: {
                id: "mark-as-seen",
                variant: "filled-primary",
                label: _vm.$t("ui.components.notifications.popup.see_all"),
                size: "sm",
                to: { name: _vm.$routes.NOTIFICATIONS_INDEX },
              },
              on: { click: _vm.handleSeeAllClick },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }