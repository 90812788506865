var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      staticClass: "ci-header__nav-item d-flex align-items-center",
      class: { hover: _vm.hover, open: _vm.open },
      attrs: { id: _vm.item.id },
      on: {
        mouseenter: _vm.handleMouseEnter,
        mouseleave: _vm.handleMouseLeave,
      },
    },
    [
      _c(
        "ci-button",
        _vm._b(
          { class: { hover: _vm.hover }, on: { click: _vm.handleTouched } },
          "ci-button",
          _vm.linkProps,
          false
        )
      ),
      _vm.drop
        ? _c("ci-button", {
            staticClass: "drop d-lg-none",
            attrs: {
              icon: "mdi-chevron-down",
              "icon-class": { "mdi-rotate-180": _vm.open },
              variant: "filled-navigation-drop",
              "aria-label": _vm.$t("ui.shared.navigation.main.dropdown"),
            },
            on: {
              click: function ($event) {
                _vm.open = !_vm.open
              },
            },
          })
        : _vm._e(),
      _vm.item.groups.length > 0
        ? _c(
            "div",
            {
              staticClass: "ci-header__nav-item__dropdown",
              class: { routing: _vm.routing },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ci-header__nav-item__dropdown__wrapper py-2 p-lg-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.item.groups, function (group) {
                      return _c(
                        "div",
                        { class: _vm.$root.handheld ? "col-12" : "col-4" },
                        [
                          group.localeKey
                            ? _c("div", {
                                staticClass: "nav-chapter py-1 mb-2",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "ui.shared.navigation.main." +
                                        group.localeKey
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                          _vm._l(group.children, function (child) {
                            return _c("ci-nav-child", {
                              key: child.id,
                              attrs: { item: child },
                              on: {
                                click: function ($event) {
                                  return _vm.trackChildNavItemClick(child)
                                },
                              },
                            })
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }