import { BaseApiService } from "@services/api/base";

const API_ENDPOINT = "/api/v4/social_events";

export class SocialEventsApiService extends BaseApiService implements SocialEventsApi.Service {
  public index: SocialEventsApi.IndexFn = () => this.axios.get(`${API_ENDPOINT}`);

  public getAll: SocialEventsApi.GetAllFn = (contextId: number | string, context: "company_profiles") => {
    const apiEndpoint = ["/api/v4", context, contextId, "social_events"].join("/");
    return this.axios.get(apiEndpoint);
  };

  public participate: SocialEventsApi.ParticipateFn = (eventId: number | string) =>
    this.axios.post(`${API_ENDPOINT}/${eventId}/participate`);

  public isRunning: SocialEventsApi.IsRunningFn = (eventId: number | string) =>
    this.axios.get(`${API_ENDPOINT}/${eventId}/is_running`);

  public register: SocialEventsApi.RegisterFn = (eventId: number | string) =>
    this.axios.post(`${API_ENDPOINT}/${eventId}/register`);

  public show: SocialEventsApi.ShowFn = (eventId: number | string) => this.axios.get(`${API_ENDPOINT}/${eventId}`);

  public details: SocialEventsApi.ShowFn = (eventId: number | string) =>
    this.axios.get(`${API_ENDPOINT}/${eventId}/details`);

  public unregister: SocialEventsApi.UnregisterFn = (eventId: number | string) =>
    this.axios.post(`${API_ENDPOINT}/${eventId}/unregister`);

  public userIsRegistered: SocialEventsApi.UserIsRegisteredFn = (eventId: number | string) =>
    this.axios.get(`${API_ENDPOINT}/${eventId}/is_registered`);
}
