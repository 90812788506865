import { Emitter } from "@utils/events";
import { i18nInstance } from "@composables/i18n";
import { default as colors } from "../shared/styles/variables/capinside.scss";
import {
  LegendOptions,
  NavigatorOptions,
  RangeSelectorButtonsEventsOptions,
  RangeSelectorButtonsOptions,
  RangeSelectorOptions,
  TooltipOptions,
} from "highcharts";

const handleChartPeriodClick = (event: Event, context: RangeSelectorButtonsEventsOptions): void => {
  const target = event.composedPath().find((el) => (el as HTMLElement).classList.contains("ci-highcharts"));
  const id = (target as HTMLElement)?.getAttribute("data-chart-id") || "";

  Emitter.emit("ci::range-selector::clicked", {
    event: event,
    id,
    period: context,
  });
};

export const CHART_PERIODS = (): Array<RangeSelectorButtonsOptions & { id: string }> => [
  {
    id: "3_month",
    type: "month",
    count: 3,
    text: i18nInstance.t("ui.shared.global.metrics.periods.month.abbr", { period: 3 }) as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
  {
    id: "6_month",
    type: "month",
    count: 6,
    text: i18nInstance.t("ui.shared.global.metrics.periods.month.abbr", { period: 6 }) as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
  {
    id: "ytd",
    type: "ytd",
    text: i18nInstance.t("ui.shared.global.metrics.periods.ytd") as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
  {
    id: "1_year",
    type: "year",
    count: 1,
    text: i18nInstance.t("ui.shared.global.metrics.periods.year.abbr", { period: 1 }) as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
  {
    id: "3_years",

    type: "year",
    count: 3,
    text: i18nInstance.t("ui.shared.global.metrics.periods.year.abbr", { period: 3 }) as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
  {
    id: "5_years",
    type: "year",
    count: 5,
    text: i18nInstance.t("ui.shared.global.metrics.periods.year.abbr", { period: 5 }) as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
  {
    id: "all",
    type: "all",
    text: i18nInstance.t("ui.shared.global.all") as string,
    events: {
      click(event) {
        handleChartPeriodClick(event, this);
      },
    },
  },
];

export const CHART_COLORS = {
  primary: colors.chartPrimary,
  primaryGradient: colors.chartPrimaryGradient,
  danger: colors.chartDanger,
  dangerGradient: colors.chartDangerGradient,
  text: colors.chartText,
  muted: colors.chartMuted,
  dark: colors.chartDark,
  lightGray: colors.chartLightGray,
  default: [
    colors.chartColors0,
    colors.chartColors1,
    colors.chartColors2,
    colors.chartColors3,
    colors.chartColors4,
    colors.chartColors5,
    colors.chartColors6,
    colors.chartColors7,
    colors.chartColors8,
    colors.chartColors9,
    colors.chartColors10,
    colors.chartColors11,
    colors.chartColors12,
    colors.chartColors13,
    colors.chartColors14,
    colors.chartColors15,
    colors.chartColors16,
    colors.chartColors17,
    colors.chartColors18,
    colors.chartColors19,
    colors.chartColors20,
    colors.chartColors21,
    colors.chartColors22,
    colors.chartColors23,
    colors.chartColors24,
    colors.chartColors25,
    colors.chartColors26,
  ],
  products: [colors.chartPrimary, colors.chartColors5],
  donut: {
    disabled: [colors.donutDisabled],
    empty: [colors.donutEmpty],
    enabled: [
      colors.donutColors0,
      colors.donutColors1,
      colors.donutColors2,
      colors.donutColors3,
      colors.donutColors4,
      colors.donutColors5,
      colors.donutColors6,
      colors.donutColors7,
      colors.donutColors8,
      colors.donutColors9,
      colors.donutColors10,
      colors.donutColors11,
      colors.donutColors12,
      colors.donutColors13,
    ],
  },
};

export const CUSTOM_LEGEND = (
  alignCenter: boolean,
  maxHeight: number,
  enableRemoving: boolean = true
): LegendOptions => ({
  maxHeight: maxHeight,
  align: "left",
  squareSymbol: true,
  enabled: true,
  itemMarginTop: 10,
  alignColumns: false,
  useHTML: true,
  navigation: {
    activeColor: "#51c4ac",
  },
  labelFormatter: function (this) {
    const alignClass = alignCenter ? "align-items-center" : "";
    if (enableRemoving) {
      const icon = (this.options as any)?.custom && !(this.options as any)?.custom.disableRemove ? "mdi-close" : "";
      return `<div class="legend-item d-flex">
              <span>${this.name}</span>
              <span class="mdi ${icon} ml-2"></span>
            </div>`;
    } else
      return `<div class="legend-item d-flex align-items-center ${alignClass}">
                <span>${this.name}</span>
              </div>`;
  },
  itemStyle: {
    fontSize: "14px",
    fontWeight: "400",
  },
  symbolWidth: 22,
  symbolRadius: 0,
});

export const CUSTOM_TOOLTIPS: TooltipOptions = {
  dateTimeLabelFormats: {
    millisecond: "%A, %e %b, %H:%M:%S.%L",
    second: "%A, %e %b, %H:%M:%S",
    minute: "%A, %e %b, %H:%M",
    hour: "%A, %e %b, %H:%M",
    day: "%A, %e %b %Y",
    week: "Woche von %A, %e %b %Y",
    month: "%B %Y",
    year: "%Y",
  },
  xDateFormat: "%d.%m.%Y",
  valueDecimals: 2,
  changeDecimals: 2,
  pointFormat: `<div class="d-flex align-items-center pl-1" style="height: 20px">
                  <span class="mr-1 d-inline-block">{series.name}:</span>
                  <strong>{point.change}%</strong>
                  <span class="color-indicator" style="background-color:{point.color}"></span>
                </div>`,
  borderColor: `${CHART_COLORS.lightGray}`,
  backgroundColor: "#FFF",
  useHTML: true,
  padding: 8,
  style: {
    color: "#333333",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
};

export const CUSTOM_NAVIGATOR: NavigatorOptions = {
  maskFill: "rgba(84, 196, 173, 0.3)",
  handles: {
    backgroundColor: "#54c4ad",
    borderColor: "white",
    height: 20,
    width: 11,
  },
  outlineColor: "transparent",
  outlineWidth: 1,
  series: {
    lineWidth: 0.5,
  },
};

export const CUSTOM_RANGE_SELECTOR = (): RangeSelectorOptions => ({
  inputBoxHeight: 30,
  inputBoxWidth: 120,
  inputDateFormat: "%d.%m.%Y",
  inputEditDateFormat: "%d.%m.%Y",
  inputStyle: {
    fontSize: "14px",
    color: CHART_COLORS.text,
  },
  inputDateParser: (value: string): number => {
    const parts = value.match(/(\d+)/g);
    if (parts && parts.length >= 3)
      return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)).getTime();
    return 0;
  },
  labelStyle: {
    fontSize: "16px",
    fill: "none",
    fontWeight: "600",
  },
  buttonSpacing: 0,
  buttonPosition: {
    x: -8,
  },
  buttonTheme: {
    paddingLeft: 12,
    width: "auto",
    paddingRight: 12,
    fill: "transparent",
    r: 0,
    height: 30,
    style: {
      color: CHART_COLORS.text,
      fontWeight: "600",
      fontSize: "16px",
      padding: "16px",
    },
    states: {
      hover: {
        fill: "transparent",
        style: {
          color: CHART_COLORS.dark,
        },
      },
      select: {
        fill: "transparent",
        style: {
          color: CHART_COLORS.primary,
        },
      },
    },
  },
  buttons: CHART_PERIODS(),
});

export const BASE_OPTIONS = {
  credits: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
};

export const BASE_CHART_OPTIONS = {
  animation: false,
  spacingRight: 0,
  spacingLeft: 0,
  style: {
    fontFamily: `Lato, "Helvetica Neue", Arial, sans-serif`,
  },
};
