import { buildURL } from "@utils/build-url";
import { apiService } from "./api.service";

class Service implements Portfolios.Service.Instance {
  public metricsTemplate = {
    performance_1_year: null,
    volatility_1_year: null,
    performance_3_years: null,
    volatility_3_years: null,
    performance_5_years: null,
    volatility_5_years: null,
  };

  public getMetrics: Portfolios.Service.GetMetricsFn = (portfolio) => {
    const funds = portfolio.portfolio_products;
    const weighted = funds.some((portfolioProduct) => portfolioProduct.weight > 0);
    const products = weighted ? funds.filter((portfolioProduct) => portfolioProduct.weight > 0) : funds;

    const metrics = products.map((p) => ({
      ...p.product.performance,
      ...p.product.ratios_euro,
    })) as Portfolios.Metrics<Nullable<number>>[];
    const weights = products.map((p) => p.weight);

    return this.calculatePerformance(metrics, weights);
  };

  public calculatePerformance: Portfolios.Service.CalculatePerformanceFn = (productMetrics, weights) => {
    if (productMetrics.length !== weights.length) {
      console.error("portfolio metrics calculation failed - length of metrics and weights does not match");
      return this.metricsTemplate;
    }

    const hasWeights = weights.some((weight) => weight > 0);
    const weightSum = weights.reduce((accumulator, val) => accumulator + val, 0);

    const alignedWeights =
      hasWeights && weightSum < 100
        ? weights.map((weight) => {
            const scaleFactor = 100 / weightSum;
            return weight * scaleFactor;
          })
        : weights;

    const productMetricsWithWeights: any[] = productMetrics.map((metricsSet, index) => {
      const weight = hasWeights ? alignedWeights[index] : 100 / productMetrics.length;
      const map = new Map();
      Object.entries(metricsSet).forEach(([key, value]) => {
        map.set(key, value ? (value * weight) / 100 : 0);
      });
      return map;
    });

    const sum = (values: number[]): number =>
      values.reduce((accumulator: number, current: number) => accumulator + current, 0);

    return Object.fromEntries(
      Object.keys(this.metricsTemplate).map((key) => [
        key,
        sum(productMetricsWithWeights.map((metricsSet) => metricsSet.get(key))),
      ])
    ) as Portfolios.Metrics<number>;
  };

  public duplicatePortfolio: Portfolios.Service.DuplicatePortfolioFn = async (portfolio) => {
    const response = await apiService.portfolios.duplicate(portfolio.id);
    // FIXME: (PS) browsers will block this popup resulting in an unspecified error
    // since the response is handled in another tab, the original page does not update with the new list of portfolios
    // so a proper fix would be to route to the new portfolio instead.
    window.open(buildURL(`/portfolios/${response.data.id}`), "_blank")!.focus();
  };

  public resetWeights: Portfolios.Service.ResetWeightsFn = (portfolioProducts) => {
    portfolioProducts.forEach((product) => {
      product.weight = 0;
    });
  };
}

export const portfoliosService = new Service();
