import { CurrentUser } from "@utils/user";

export class GoogletagSupport {
  public static initAds(ads: CIAdsMeta[], callback?: () => void): void {
    if (ads === undefined || ads.length === 0) return;

    this.addSlots(ads, false);
    this.showAds(ads, false);

    if (callback) {
      callback();
    }
  }

  public static initInlineAds(ads: CIAdsMeta[]): void {
    if (ads === undefined || ads.length === 0) return;
    this.addSlots(ads, true);
    this.showAds(ads, true);
  }

  public static findSlotByDomId(id: string): Nullable<ExplicitAny> {
    return this.findSlotBy(id, (slot, id) => slot.getSlotId().getDomId() === id);
  }

  public static destroySlots(): void {
    if (typeof window.googletag?.destroySlots === "function") {
      window.googletag.destroySlots();
    }
  }

  private static addSlots(ads: CIAdsMeta[], inlineOnly: boolean): void {
    window.googletag.cmd.push(() => {
      ads.forEach((adDef: CIAdsMeta) => {
        if (this.adDisplayable(adDef)) {
          if (adDef.dom_id.endsWith("{ad_uuid_placeholder}")) {
            let adCount = 1;
            const inlineAds = document.querySelectorAll<HTMLDivElement>(`[id^='${adDef.dom_id.slice(0, -21)}']`);
            inlineAds.forEach((inlineAd) => {
              // each inline ad requires their own slot, so we add a numbered, two digit appendix for each one
              this.addSlot(
                `${adDef.path}-${String(adCount).padStart(2, "0")}`,
                adDef.sizes,
                adDef.sizeMapping,
                inlineAd.id
              );
              adCount += 1;
            });
          } else if (!inlineOnly) {
            this.addSlot(adDef.path, adDef.sizes, adDef.sizeMapping, adDef.dom_id);
          }
        }
      });
      window.googletag.pubads().enableSingleRequest();
      window.googletag.pubads().collapseEmptyDivs();
      window.googletag.enableServices();
    });
  }

  private static adDisplayable(adDef: CIAdsMeta): boolean {
    return CurrentUser.signedIn == adDef.member;
  }

  private static addSlot(
    path: string,
    sizes: [number, number] | number[][],
    sizeMapping: CIAdSize[],
    domId: string
  ): void {
    if (typeof window.googletag.defineSlot === "undefined") return;
    const gptSlot = this.findSlotByPath(path) || window.googletag.defineSlot(path, sizes, domId);
    if (gptSlot) {
      gptSlot.addService(window.googletag.pubads());
      if (sizeMapping.length > 0) {
        const mapping = window.googletag.sizeMapping();
        sizeMapping.forEach((sizeStep) => {
          mapping.addSize(sizeStep.viewport, sizeStep.sizes);
        });

        gptSlot.defineSizeMapping(mapping.build());
      }
    }
  }

  private static showAds(ads: CIAdsMeta[], inlineOnly: boolean): void {
    window.googletag.cmd.push(() => {
      ads.forEach((adDef: CIAdsMeta) => {
        if (adDef.dom_id.endsWith("{ad_uuid_placeholder}")) {
          const inlineAds = document.querySelectorAll<HTMLDivElement>(`[id^='${adDef.dom_id.slice(0, -21)}']`);
          inlineAds.forEach((inlineAd) => {
            if (this.findSlotByDomId(inlineAd.id) !== undefined) {
              window.googletag.display(inlineAd.id);
            }
          });
        } else if (!inlineOnly && this.findSlotByDomId(adDef.dom_id) !== undefined) {
          window.googletag.display(adDef.dom_id);
        }
      });
    });
  }

  private static findSlotByPath(path: string): Nullable<ExplicitAny> {
    return this.findSlotBy(path, (slot, path) => slot.getSlotId().getAdUnitPath() === path);
  }

  private static findSlotBy(
    identifier: string,
    validSlot: { (slot: any, identifier: string): boolean }
  ): Nullable<ExplicitAny> {
    if (
      typeof window.googletag.pubads === "undefined" ||
      typeof window.googletag.pubads().getSlots === "undefined" ||
      !identifier
    )
      return undefined;
    return window.googletag
      .pubads()
      .getSlots()
      .find((slot: ExplicitAny) => validSlot(slot, identifier));
  }
}
