import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/products/peergroups";
export class PeergroupsApiService extends BaseApiService implements PeergroupsApi.Service {
  public all: PeergroupsApi.AllFn = (params = {}) => this.axios.get(API_ENDPOINT, { params });
  public get: PeergroupsApi.GetFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}`);
  public contents: PeergroupsApi.ContentsFn = (id, params = {}) =>
    this.axios.get(`${API_ENDPOINT}/${id}/contents`, { params });
  public tiles: PeergroupsApi.TilesFn = () => this.axios.get(`${API_ENDPOINT}/tiles`);
}
