
import Vue from "vue";
import { Prop, Ref } from "vue-property-decorator";
import Component from "vue-class-component";
import * as vClickOutside from "v-click-outside";
import { Emitter } from "@utils/events";
import { Instance, createPopper } from "@popperjs/core";

type DropdownPlacement =
  | "top-start"
  | "top"
  | "top-end"
  | "bottom-start"
  | "bottom"
  | "bottom-end"
  | "right-start"
  | "right"
  | "right-end"
  | "left-start"
  | "left"
  | "left-end";
@Component({
  name: "ci-dropdown-menu",
  directives: {
    clickOutside: vClickOutside.directive,
  },
})
export default class extends Vue {
  @Prop({ default: "bottom-start" }) placement!: DropdownPlacement;

  @Prop({ default: true }) showActiveState!: boolean;
  @Prop({ default: false }) preventToggle!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) hover!: boolean;
  @Prop({ default: "py-3" }) contentClass!: string;

  @Ref("dropdownMenu") dropdown!: HTMLElement;

  public expanded = false;
  public popperInstance: Nullable<Instance> = null;

  public toggle(): void {
    if (!this.preventToggle && !this.disabled) {
      this.expanded = !this.expanded;
      this.popperInstance?.update();
      if (this.dropdown) this.dropdown.style.visibility = "visible";
    }
  }

  public handleClick(): void {
    if (!this.hover) this.toggle();
  }

  public handleHover(): void {
    if (this.hover) this.toggle();
  }

  public hide(): void {
    this.expanded = false;
    this.popperInstance?.update();
  }

  protected async mounted(): Promise<void> {
    Emitter.on("ci::dropdown-menu-item::selected", () => {
      if (this.dropdown) this.dropdown.style.visibility = "hidden";
    });
    Emitter.on("ci::dropdown-menu-item::deselected", () => {
      if (this.dropdown) this.dropdown.style.visibility = "visible";
    });

    this.$nextTick(() => {
      const toggle = this.$refs.toggle as HTMLElement;
      const dropdown = this.$refs.dropdownMenu as HTMLElement;
      this.popperInstance = createPopper(toggle, dropdown, {
        placement: this.placement,
        modifiers: [
          ...(["right", "left"].includes(this.placement)
            ? [
                {
                  name: "offset",
                  options: {
                    offset: [60, 0],
                  },
                },
              ]
            : []),
        ],
      });
    });
    Emitter.on("ci::hide::dropdown-menu", () => {
      setTimeout(() => this.hide());
    });
  }
}
