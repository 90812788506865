import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";

import { i18nInstance } from "@composables/i18n";
import { Router } from "@router";
import { store } from "@stores";
import { viewportService } from "@services/viewport.service";
import { utilityService } from "@services/utility.service";
import { CurrentUser } from "@utils/user";
import { throttle } from "throttle-debounce";
import UserPilot from "@utils/userpilot";
import { Emitter } from "@utils/events";

@Component({
  i18n: i18nInstance,
  store: store,
  router: Router,
})
export default class extends Vue {
  public initialized: boolean = false;

  private throttledScrollDispatch = throttle(500, () => {
    Emitter.emit("ci::scroll::throttled");
  });

  public get handheld(): boolean {
    return viewportService.check(["xs", "sm", "md"]);
  }

  public get isTouchDevice(): boolean {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }

  public dispatchScrollEvent(): void {
    this.throttledScrollDispatch();
  }

  public viewport(viewports: Viewport.VPs[]): boolean {
    return viewportService.check(viewports);
  }

  protected mounted(): void {
    CurrentUser.initialize();
    this.initializeServices();
    this.initializeUserpilot();

    window.addEventListener("scroll", this.dispatchScrollEvent);
    this.$root.$on("bv::show::modal", (id: string) => {
      if (!this.$bvModalList.exists(id)) console.warn(`the modal "${id}" is not available to open`);
    });
  }

  protected destroyed() {
    window.removeEventListener("scroll", this.dispatchScrollEvent);
  }

  private initializeServices(): void {
    window.$app = utilityService.app;
    viewportService.registerEvents();
    utilityService.listenForModalRequest(this);
    utilityService.startTimeMe();
  }

  private async initializeUserpilot(): Promise<void> {
    const userpilot = await UserPilot.initialize();
    userpilot.trackEvent("user initialized");
  }
}
