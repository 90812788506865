
import { Component, Model, Prop, Vue } from "vue-property-decorator";
import CiTag from "./tag.vue";

@Component({
  name: "ci-interest-tags",
  components: {
    "ci-tag": CiTag,
  },
})
export default class extends Vue {
  @Prop({ default: () => [] }) interestTags!: Users.InterestTagsList;
  @Prop({ default: 0 }) clamp!: number;
  @Prop({ default: false }) showExpandCta!: boolean;
  @Prop({}) public context?: string;
  @Prop({}) public variant?: string;
  @Prop({ default: "" }) public tagTrackingIdentifierOverride?: string;
  @Prop({}) public tagTrackingTargetName?: string;
  @Prop({}) containerId?: string | number;
  @Model("update", { type: Array }) selectedTags?: Users.InterestTagsList;

  public options: Users.InterestTagsList = [];
  public expanded: boolean = false;

  public get selected(): Users.InterestTagsList {
    return this.selectedTags || [];
  }

  public set selected(val: Users.InterestTagsList) {
    this.$emit("update", val);
  }

  public get lineClampClass(): string {
    const clampClass = this.clamp > 0 && !this.expanded ? `line-${this.clamp}` : "expanded";
    const toggleClass = this.showExpandCta ? "show-toggle " : "";
    return toggleClass + clampClass;
  }

  public get expandedClass(): string {
    if (this.interestTags.length > 0) {
      return this.expanded ? "mdi-chevron-up" : "mdi-chevron-down";
    } else {
      return "d-none";
    }
  }

  public toggleClamp(): void {
    this.expanded = !this.expanded;
  }
}
