
import { Vue, Component, Prop } from "vue-property-decorator";
import RippleDirective from "@directives/base/ripple";
import { CreateElement, VNode } from "vue";
import { utilityService } from "@services/utility.service";
import { ROUTENAMES } from "@models/constants/routes";

const BUTTON_PREFIX = "ci-btn";

@Component({
  name: "ci-button",
  directives: {
    "ci-ripple": RippleDirective,
  },
})
export default class extends Vue {
  @Prop({ default: "button" }) public type!: "reset" | "button" | "submit";
  @Prop({ default: "_self" }) public target!: "_blank" | "_self" | "_parent" | "_top";
  @Prop({ default: "" }) public href!: string;
  @Prop({ default: null }) public to!: Components.Button.Route;

  @Prop({ default: "sm" }) public size!: Components.Button.Size;
  @Prop({ default: "ghost-light" }) public variant!: Components.Button.Variant;
  @Prop({ default: false }) public square!: boolean;
  @Prop({ default: false }) public fab!: boolean;
  @Prop({ default: "" }) public iconClass!: string;
  @Prop({ default: "" }) public buttonClass!: string;
  @Prop({ default: "" }) public innerClass!: string;

  @Prop({ default: "" }) public icon!: string;
  @Prop({ default: "" }) public label!: string;

  @Prop({ default: "" }) public tooltip!: string;
  @Prop({ default: "" }) public disabledTooltip!: string;
  @Prop({ default: "" }) public gatedTooltip!: string;
  @Prop({ default: "" }) public tooltipClass!: string;

  @Prop({ default: false }) public hideLabel!: boolean;
  @Prop({ default: 0 }) public notification!: number;
  @Prop({ default: false }) public notificationBubble!: boolean;
  @Prop({ default: false }) public dropdown!: boolean;
  @Prop({ default: false }) public loading!: boolean;
  @Prop({ default: false }) public active!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public professional!: boolean;
  @Prop({ default: false }) public gated!: boolean;
  @Prop({ default: true }) public showGatingTooltip!: boolean;
  @Prop({ default: "login" }) public gatingType!: "login" | "registration";

  public tooltipId: string = utilityService.guid();

  public get isGated(): boolean {
    return this.gated && (!this.$user.signedIn || (this.professional && !this.$user.professional));
  }

  public get iconClasses(): string[] {
    const classes = ["mdi", this.iconClass, this.icon];
    if ((this.icon && !this.label) || this.hideLabel) classes.push(`icon-only`);
    if (this.label && !this.hideLabel) classes.push("mr-2");

    return classes;
  }

  public get buttonClasses(): string[] {
    const classes = [
      `${BUTTON_PREFIX}--variant-${this.variant}`,
      `${BUTTON_PREFIX}--size-${this.size}`,
      this.buttonClass,
    ];

    if (this.fab) classes.push(`${BUTTON_PREFIX}--fab`);
    if (this.square) classes.push(`${BUTTON_PREFIX}--square`);
    if (this.dropdown) classes.push(`${BUTTON_PREFIX}--dropdown`);
    if (this.isGated) classes.push(`${BUTTON_PREFIX}--gated`);
    if (this.disabled) classes.push(`${BUTTON_PREFIX}--disabled`);
    if ((this.icon && !this.label) || this.hideLabel) classes.push(`${BUTTON_PREFIX}--icon-only`);
    if (!this.icon && this.label) classes.push(`${BUTTON_PREFIX}--label-only`);
    if (this.notification > 0) classes.push(`${BUTTON_PREFIX}--notification-number`);
    if (this.notificationBubble) classes.push(`${BUTTON_PREFIX}--notification-bubble`);
    if (this.active) classes.push(`${BUTTON_PREFIX}--active`);

    return classes;
  }

  public get tooltipOptions(): {
    id: string;
    title: string;
    disabled: boolean;
    interactive: boolean;
    customClass: string;
  } {
    let title = this.label ? "" : this.tooltip;

    if (this.gated && this.showGatingTooltip) title = this.gatingTooltip || this.tooltip;
    if (this.disabled && this.disabledTooltip) title = this.disabledTooltip;
    return {
      id: this.tooltipId,
      interactive: false,
      title,
      disabled: !title || this.$root.handheld || this.active,
      customClass: this.tooltipClass,
    };
  }

  private get gatingTooltip(): string {
    if (this.$user.signedIn) {
      return this.professional && !this.$user.professional
        ? this.gatedTooltip || (this.$t("products.thumbnail_hover.to_the_professional_settings") as string)
        : "";
    } else {
      return this.gatedTooltip || (this.$t("products.thumbnail_hover.please_login") as string);
    }
  }

  public async handleGatingAction(event: MouseEvent): Promise<void> {
    this.$root.$emit("bv::hide::tooltip", this.tooltipId);

    if (this.disabled || this.isGated) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    if (!this.disabled) {
      if (this.isGated) {
        this.handleGatedClick();
      } else {
        this.$emit("click", event);
      }
    }
  }

  protected render(h: CreateElement): VNode {
    if (!this.label && !this.icon)
      console.error(
        "ci-button should have at least a label OR icon. Use the label prop instead of v-text if the directive is attached on this button",
        this.$el
      );
    const directives = {
      tooltip: { name: "b-tooltip", value: this.tooltipOptions, modifiers: { hover: true, blur: true } },
      ripple: { name: "ci-ripple" },
    };

    const buttonContent = () => {
      const contents = [];

      if (this.icon) contents.push(h("span", { class: ["ci-btn__icon", ...this.iconClasses] }));
      if (this.loading) contents.push(h("span", { class: ["mdi mdi-loading mdi-spin mr-2"] }));
      if (this.label && !this.hideLabel) contents.push(h("span", { class: ["ci-btn__label"] }, this.label));
      if (this.notification > 0 || this.notificationBubble) {
        const showNumber = this.notification > 1;

        contents.push(
          h(
            "span",
            {
              class: [
                `ci-btn__notification ci-btn__notification${
                  showNumber ? "--number" : this.notificationBubble ? "--bubble" : ""
                }`,
              ],
            },
            showNumber ? (this.notification > 99 ? "99+" : this.notification.toString()) : ""
          )
        );
      }
      if (this.dropdown) contents.push(h("span", { class: ["mdi mdi-chevron-down ml-1"] }));

      return [h("div", { class: ["ci-btn__content", this.innerClass], directives: [directives.tooltip] }, contents)];
    };

    const button = () => {
      const content = this.$slots.default || buttonContent();
      const baseAttributes = {
        "disabled": this.disabled,
        "target": this.target,
        "aria-label": this.label || this.tooltip,
        "title": this.label || "",
        "rel": this.target === "_blank" ? "noopener" : "",
      };

      const options = (attributes = {}) => ({
        directives: this.disabled ? [] : [directives.ripple],
        class: ["ci-btn align-items-center", ...this.buttonClasses],
        on: { click: this.handleGatingAction },
        attrs: { ...baseAttributes, ...attributes },
      });

      if (this.disabled && (this.to || this.href)) return h("span", options(), content);
      else if (this.to) return h("ci-router-link", options({ to: this.to }), content);
      else if (this.href) return h("a", options({ href: this.href }), content);
      else return h("button", options({ type: this.type }), content);
    };

    return button();
  }

  private async handleGatedClick(): Promise<void> {
    if (!this.$user.signedIn) {
      this.$root.$emit("bv::show::modal", `modal-${this.gatingType}`);
    } else if (this.professional && !this.$user.professional) this.openUserModalInvestmentProfile();
  }

  private async openUserModalInvestmentProfile(): Promise<void> {
    const route = this.$router.resolve({ name: ROUTENAMES.USERS_SETTINGS, query: { tab: "investment_profile" } });
    if (this.$page.context === "spa") this.$router.push(route.location);
    else location.href = route.href;
  }
}
