
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import { Emitter } from "@utils/events";
import { Instance, createPopper } from "@popperjs/core";

@Component({
  name: "ci-dropdown-item",
})
export default class extends Vue {
  @Prop({ default: () => false }) expandable!: boolean;
  @Prop({ default: () => "left-start" }) placement!:
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";

  @Prop({ default: "" }) name!: string;
  @Prop({ default: "" }) iconClasses!: string;
  @Prop({ default: "" }) tooltip!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) toggleActive!: boolean;
  @Prop({ default: "" }) to!: Location;
  @Prop({ default: "" }) href!: string;
  @Prop({ default: () => ({}) }) hrefAttributes!: Indexable;
  @Prop({ default: "" }) target!: string;
  @Prop({ default: "" }) rel!: string;
  @Prop({ default: "" }) description!: string;
  @Prop({ default: "" }) modal!: string;
  @Prop({ default: null }) public gatingType!: "login" | "registration" | null;
  @Prop({ default: "" }) public itemNameClass!: string;

  @Ref("expandedContent") expandedContent!: HTMLElement;
  @Ref("dropdownItem") dropdownItem!: HTMLElement;

  public expanded = false;
  public popperInstance: Nullable<Instance> = null;

  public get tag(): string {
    return this.to ? "ci-router-link" : this.href ? "a" : "div";
  }

  public get tagAttributes(): Indexable {
    if (this.to) return { to: this.to, target: this.target, rel: this.rel };
    if (this.href) return { href: this.href, target: this.target, rel: this.rel, ...(this.hrefAttributes || {}) };
    else return {};
  }

  public get hasContentSlot(): boolean {
    return !!this.$slots.content;
  }

  public expand(): void {
    if (!this.disabled) {
      this.expanded = true;
      if (this.expandable) this.popperInstance?.update();
    }
  }

  public handleClick(): void {
    if (this.$root.handheld && this.expandable) {
      Emitter.emit("ci::dropdown-menu-item::selected");
      this.expandedContent.classList.add("mobile-selected");
    } else if (!this.disabled) {
      this.$emit("click");
      Emitter.emit("ci::hide::dropdown-menu");
      if (this.gatingType && !this.$user.signedIn) {
        this.$bvModal.show(`modal-${this.gatingType}`);
        return;
      }
      if (this.modal) this.$bvModal.show(this.modal);
    }
  }

  public collapse(): void {
    if (this.$root.handheld && this.expandable) {
      Emitter.emit("ci::dropdown-menu-item::deselected");
      this.expandedContent.classList.remove("mobile-selected");
    }
    this.expanded = false;
  }

  protected async mounted(): Promise<void> {
    if (this.expandable)
      this.$nextTick(() => {
        const toggle = this.$refs.toggle as HTMLElement;
        const dropdown = this.expandedContent as HTMLElement;
        this.popperInstance = createPopper(toggle, dropdown, {
          placement: this.placement,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-16, 0],
              },
            },
          ],
        });
      });
  }
}
