class Service implements GTMTracking.Service {
  readonly VALID_TRACKING_DATA_ATTRIBUTES = ["event", "eventCategory", "eventLabel", "eventAction"];

  public track: GTMTracking.TrackFn = (trackingPayload) => {
    const datalayer: GTMTracking.GaEventDataLayer = {
      ...trackingPayload,
      event: "gaEvent",
    };

    if (typeof datalayer.eventLabel === "object") {
      datalayer.eventLabel = this.objectToEventLabel(datalayer.eventLabel);
    }
    this.pushToDatalayer(datalayer);
  };

  public pushToDatalayer: GTMTracking.PushToDatalayerFn = (value: GTMTracking.GaEventDataLayer) => {
    if (!this.isTrackingDataValid(value)) {
      throw new Error("trying to track invalid data: " + JSON.stringify(value));
    }
    if (window.dataLayer) window.dataLayer.push(value);
    else console.error("gtm not initialized");
  };

  public isTrackingDataValid: GTMTracking.ValidateTrackingDataFn = (payload) => {
    if (typeof payload === "object" && payload !== null) {
      const payloadPropertyKeys = Object.keys(payload);

      if (!payloadPropertyKeys.every((key) => this.VALID_TRACKING_DATA_ATTRIBUTES.indexOf(key) >= 0)) {
        return false;
      }

      return !!(payload.eventCategory && payload.eventAction);
    }

    return false;
  };

  public reinitialize: GTMTracking.ReinitializeFn = (referrer, pageType, options = {}) => {
    const description = document.head.querySelector("meta[name='description']")?.getAttribute("content") || "";
    window.dataLayer?.push({
      ciInternalReferrerPath: referrer,
      ciPageType: pageType,
      ciReferenceTags: options.ciReferenceTags || undefined,
      ciSEODescription: description,
      event: "refresh",
    });
  };

  /*
   * Converts object into a format we use for event labels.
   * The resulting event label has a stable format. The keys will always appear in alphabetical order
   */
  private objectToEventLabel: GTMTracking.ObjectToEventLabelFn = (eventLabel: GTMTracking.EventLabel) =>
    Object.entries(eventLabel)
      .map((entry) => {
        entry[1] = entry[1] === null || entry[1] === undefined ? "null" : entry[1];
        return entry;
      })
      .map((entry) => entry.join(" : "))
      .sort()
      .join(" ; ");
}

export const gtmTrackingService = new Service();
