import { PluginObject } from "vue";

import { DropdownPlugin, TooltipPlugin, ModalPlugin, BTab, CarouselPlugin } from "bootstrap-vue";

import BModalExtended from "@components/plugins/b-modal.extended.vue";
import { Modals } from "@models/enums";
import BTabsExtended from "@components/plugins/b-tabs.extended.vue";

const BVModalListPlugin = (v: any): any => {
  let modals: string[] = v.observable([]);

  v.prototype.$bvModalList = {
    ids: Modals,
    modals: modals,
    register(modal: string): void {
      if (this.exists(modal)) {
        console.warn(`modal "${modal}" is already registered -- avoid double registration with the same id`);
      }
      modals.push(modal);
    },

    drop(modal: string): void {
      if (this.exists(modal)) modals = modals.filter((item) => item !== modal);
      else console.warn(`modal "${modal} was not found and can't be dropped"`);
    },

    exists(modal: string): boolean {
      return modals.findIndex((item) => item === modal) > -1;
    },
  };
};

export const CIBootstrapPlugin: PluginObject<boolean> = {
  install: (v) => {
    v.use(BVModalListPlugin);
    v.use(ModalPlugin, {
      BModal: {
        okVariant: "primary",
        cancelVariant: "light",
      },
    });
    v.use(TooltipPlugin);
    v.use(DropdownPlugin);
    v.use(CarouselPlugin);
    v.component("b-modal", BModalExtended);
    v.component("b-tabs", BTabsExtended);
    v.component("b-tab", BTab);
  },
};
