import { appsignal } from "@utils/appsignal";
import { errorHandler } from "@appsignal/vue";
import "@router/hooks";
import Vue from "vue";

import { i18nLoader, i18nInstance } from "@composables/i18n";
import { store } from "@stores";

import { AnalyticsStoreModule } from "@stores/analytics.store";

import RootMixin from "./root.mixin";
import DynamicMixin from "../shared/dynamic.mixin";
import { CISetupPlugin } from "./plugins/setup.plugin";
import { CIBootstrapPlugin } from "./plugins/bootstrap.plugin";
import { CIVendorPlugin } from "./plugins/vendor.plugin";
import { CIComponentsPlugin } from "./plugins/components.plugin";
import { CIDirectivesPlugin } from "./plugins/directives.plugin";
import { Userpilot } from "userpilot";
import VueMeta, { MetaInfo } from "vue-meta";
import { getLocaleFromSettings } from "@utils/i18n/i18n.instance";
import SharingLogo from "@images/logos/sharing/logo_white.png";

Vue.config.productionTip = false;
Vue.config.errorHandler = errorHandler(appsignal, Vue);

const install = async (): Promise<void> => {
  Vue.use(CISetupPlugin);
  Vue.use(CIBootstrapPlugin);
  Vue.use(CIVendorPlugin);
  Vue.use(CIComponentsPlugin);
  Vue.use(CIDirectivesPlugin);
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
  });

  // Register analytics module manually, as it is not used by any component explicitly
  store.registerModule("analytics", AnalyticsStoreModule);

  await i18nLoader.load(getLocaleFromSettings());

  window.$vnode = new Vue({
    el: "#app",
    mixins: [RootMixin, DynamicMixin],
    watch: {
      $route: {
        handler: () => Userpilot.reload(),
        deep: true,
        immediate: true,
      },
    },
    metaInfo(): MetaInfo {
      const metaOptions = {
        locationPath: window.location.origin,
        imagePath: SharingLogo,
        fallbackTitle: i18nInstance.t("ui.shared.global.meta_tags.title") as string,
        fallbackDescription: i18nInstance.t("ui.shared.global.meta_tags.description") as string,
      };
      return {
        titleTemplate: (titleChunk: string) =>
          `${titleChunk ? titleChunk : metaOptions.fallbackTitle} | ${i18nInstance.t("ui.shared.global.site.title")}`,
        meta: [
          { vmid: "description", name: "description", content: metaOptions.fallbackDescription },
          {
            vmid: "og:site_name",
            property: "og:site_name",
            content: i18nInstance.t("ui.shared.global.site.title") as string,
          },
          { vmid: "og:type", property: "og:type", content: "website" },
          { vmid: "og:image", property: "og:image", content: metaOptions.locationPath + metaOptions.imagePath },
          {
            vmid: "og:title",
            property: "og:title",
            content: metaOptions.fallbackTitle,
          },
          { vmid: "og:description", property: "og:description", content: metaOptions.fallbackDescription },
          { vmid: "og:url", property: "og:url", content: metaOptions.locationPath + this.$route.path },
          { vmid: "twitter:card", name: "twitter:card", content: "summary_large_image" },
          { vmid: "twitter:site", name: "twitter:site", content: "@CAPinside" },
          {
            vmid: "twitter:title",
            name: "twitter:title",
            content: metaOptions.fallbackTitle,
          },
          { vmid: "twitter:description", name: "twitter:description", content: metaOptions.fallbackDescription },
          { vmid: "twitter:image", name: "twitter:image", content: metaOptions.locationPath + metaOptions.imagePath },
          { vmid: "robots", name: "robots", content: "index, follow" },
        ],
        link: [{ vmid: "canonical", rel: "canonical", href: metaOptions.locationPath + this.$route.path }],
      };
    },
  });
};

document.addEventListener("DOMContentLoaded", install);
