export default class ProductPerformanceChartRequest implements ChartApi.RequestObject {
  private request: ChartApi.ChartProvider;
  private readonly isin: string;

  constructor(isin: string, request: ChartApi.ChartProvider) {
    this.isin = isin;
    this.request = request;
  }

  public identifiers(): string[] {
    return [this.isin];
  }

  public async chart(): Promise<ChartApi.ResponseItemSeriesData<number>> {
    return (await this.request.charts()).find((chart) => chart.meta.id === this.isin)?.ts || [];
  }

  public async meta(): Promise<ChartApi.ResponseItemMeta> {
    return (
      (await this.request.charts()).find((chart) => chart.meta.id === this.isin)?.meta || {
        currency: "",
        name: "",
        id: "",
      }
    );
  }
}
