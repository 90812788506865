export const NavigationHelper = {
  ItemIsActive: (item: Navigation.Child | Navigation.Item, route: Route): boolean => {
    if (item.additionalRoutes.includes(route.name!)) return true;
    return item.route.href === "/"
      ? route.path === item.route.href
      : route.name?.startsWith(item.route.route.name!) || false;
  },

  FlipperIsActive: (item: Navigation.Child | Navigation.Item): boolean => {
    if (!item.route.resolved.meta?.hasOwnProperty("flipperEnabled")) return true;

    return item.route.resolved.meta?.flipperEnabled;
  },
};
