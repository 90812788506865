
import { computed, defineComponent } from "vue";
import { settingsService } from "@services/settings.service";
import { CurrentUser } from "@utils/user";
import Cookies from "js-cookie";

export default defineComponent({
  name: "CiProfessionalGate",
  setup() {
    const currentRegion = settingsService.options.region;

    const showGate = computed(() => {
      if (currentRegion.code != "CH") return false;
      if (Cookies.get("professional_gate")) return false;
      if (!CurrentUser.signedIn) return true;

      return !CurrentUser.professional;
    });
    const onHideGate = () => {
      Cookies.set("professional_gate", String(new Date().getTime()), { expires: 14 });
    };

    return { showGate, onHideGate };
  },
});
