
import { defineComponent } from "vue";
import Toast from "./toast.vue";
import { Toasts } from "@utils/toasts";

export default defineComponent({
  components: {
    Toast,
  },
  setup() {
    const toasts = Toasts;

    return { toasts };
  },
});
