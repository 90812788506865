class Service implements Storage.Service {
  public storage = window.localStorage;
  private storagePrefix = "capfw-";

  public clear: Storage.ClearFn = () => {
    this.storage.clear();
  };

  public get: Storage.GetFn = (key, fallback) => {
    if (this.storage) {
      const item = this.storage.getItem(`${this.storagePrefix}${key}`);
      if (item) {
        return JSON.parse(item);
      } else if (fallback !== undefined) return fallback;
      else return null;
    } else return null;
  };

  public set: Storage.SetFn = (key, value) => {
    this.storage.setItem(`${this.storagePrefix}${key}`, JSON.stringify(value));
  };
}

export const storageService = new Service();
