import { i18nInstance } from "@composables/i18n";
import { useRegion } from "@composables/region";

type LocaleImportArray = Promise<any>[];

export const LocaleOptions = {
  paths: (): Indexable<() => LocaleImportArray> => ({
    "de": () => [
      import(/* webpackChunkName: "locales_de" */ "@locales/de/de.yml?locale=true" as any),
      import(/* webpackChunkName: "locales_vv_de" */ "vee-validate/dist/locale/de.json"),
    ],
    "de-AT": () => [import(/* webpackChunkName: "locales_de-AT" */ "@locales/de-AT/de-AT.yml?locale=true" as any)],
    "de-CH": () => [import(/* webpackChunkName: "locales_de-CH" */ "@locales/de-CH/de-CH.yml?locale=true" as any)],
    "en": () => [
      import(/* webpackChunkName: "locales_en" */ "@locales/en/en.yml?locale=true" as any),
      import(/* webpackChunkName: "locales_vv_en" */ "vee-validate/dist/locale/en.json"),
    ],
  }),
};

class Service {
  public loadedLanguages: string[] = [];
  private region = useRegion();

  public setI18nLanguage = (lang: string): string => {
    i18nInstance.locale = lang;
    const doc = document.querySelector("html");
    if (doc) doc.setAttribute("lang", lang);
    return lang;
  };

  public load = async (lang: string): Promise<string> => {
    if (this.loadedLanguages.includes(lang)) {
      return Promise.resolve(this.setI18nLanguage(lang));
    }

    if (lang != this.region.defaultRegionCode().toLowerCase()) {
      // first load the fallback language
      await this.load(this.region.defaultRegionCode().toLowerCase());
    }

    return Promise.all(LocaleOptions.paths()[lang]()).then((messages: Indexable[]) => {
      const defaultTranslations = messages[0][lang];
      let validateTranslations = [];
      if (messages[1]) {
        validateTranslations = messages[1].messages;
      }
      i18nInstance.setLocaleMessage(lang, {
        ...defaultTranslations,
        validation: validateTranslations,
      });
      this.loadedLanguages.push(lang);
      return this.setI18nLanguage(lang);
    });
  };

  public reset(): void {
    this.loadedLanguages.length = 0;
  }
}

export const i18nLoader = new Service();
