var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "ci-header__search_dropdown" }, [
    _c(
      "form",
      { attrs: { name: "nav-search" } },
      [
        _vm.search.backdrop ? _c("div", { staticClass: "backdrop" }) : _vm._e(),
        _c(
          "ci-fe-wrapper",
          _vm._b({}, "ci-fe-wrapper", _vm.wrapperAttributes, false),
          [
            _c("v-select", {
              ref: "vs",
              staticClass: "ci-form--input",
              class: { searching: _vm.searching },
              attrs: {
                label: "label",
                options: _vm.results,
                filterable: false,
                selectable: (option) => option.type !== "chapter",
                placeholder: _vm.$t("global_search_index_placeholder"),
                rules: { min: 3 },
              },
              on: {
                search: _vm.handleSearch,
                input: _vm.handleInput,
                "search:focus": _vm.handleSearchFocus,
                "search:blur": _vm.handleSearchBlur,
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function (option) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "search-result-option d-flex flex-nowrap align-items-center",
                          class: "option-type-" + option.type,
                        },
                        [
                          option.type === "chapter"
                            ? [
                                _c("div", {
                                  staticClass:
                                    "result-chapter text-md font-weight-semi text-dark pt-2 w-100",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.decorateLabel(option)
                                    ),
                                  },
                                }),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "result-decoration flex-center mr-2",
                                  },
                                  [
                                    option.type === "user"
                                      ? [
                                          _c("ci-user-avatar", {
                                            attrs: {
                                              src: option.image,
                                              size: "sm",
                                              username: option.label,
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("span", {
                                            staticClass: "mdi mdi-24px",
                                            class: option.icon,
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.decorateLabel(option)
                                    ),
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]
                  },
                },
                {
                  key: "no-options",
                  fn: function () {
                    return [
                      _vm.showSearchHint
                        ? _c("div", {
                            staticClass: "py-1 px-3 text-left",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("searches.dropdown_hint")
                              ),
                            },
                          })
                        : _vm.queryTooLong
                        ? _c("div", {
                            staticClass: "py-1 px-3 text-left",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "ui.components.base.search_bar.query_too_long_hint"
                                )
                              ),
                            },
                          })
                        : _c("div", {
                            staticClass: "py-3",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("searches.no_results")
                              ),
                            },
                          }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "open-indicator",
                  fn: function () {
                    return [_c("span")]
                  },
                  proxy: true,
                },
                {
                  key: "list-header",
                  fn: function () {
                    return [
                      _vm.showHistory
                        ? _c(
                            "li",
                            {
                              staticClass:
                                "d-flex flex-nowrap align-items-center pt-2 pb-3",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "flex-grow-1 pl-3 text-md font-weight-semi text-dark",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("searches.history.title")
                                  ),
                                },
                              }),
                              _c("a", {
                                staticClass:
                                  "mr-3 font-weight-semi cursor-pointer text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("searches.history.clear")
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteSearchHistory.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.search.value,
                callback: function ($$v) {
                  _vm.$set(_vm.search, "value", $$v)
                },
                expression: "search.value",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }