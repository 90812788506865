
import { apiService } from "@services/api.service";
import { gtmTrackingService } from "@services/gtm-tracking.service";
import { Vue, Component, Prop } from "vue-property-decorator";
import { AxiosError } from "axios";
import Userpilot from "@utils/userpilot";
import InlineSvg from "vue-inline-svg";
import buttonTo from "@components/base/button-to.vue";
import CiOmniauthButtons from "@components/base/omniauth-buttons.vue";
import CiPartnerButtons from "@components/base/PartnerButtons.vue";
import { settingsService } from "@services/settings.service";
import { useLoader } from "@composables/loader";

const GTM_EVENT_CATEGORY = "Login";

@Component({
  name: "ci-login-form",
  components: {
    "ci-partner-buttons": CiPartnerButtons,
    "ci-omniauth-buttons": CiOmniauthButtons,
    "inline-svg": InlineSvg,
  },
})
export default class extends Vue {
  @Prop({ default: "" }) registrationUrl!: string;
  @Prop({ default: "" }) newPasswordUrl!: string;
  @Prop({ default: "" }) newConfirmationMailUrl!: string;
  @Prop({ default: "" }) gatingBannerLocation!: string;
  @Prop({ default: null }) onboardingType!: Nullable<string>;
  @Prop({ default: "" }) formAuthenticityToken!: string;
  @Prop({ default: false }) isFullScreen!: boolean;

  public redirectUrl: string = "/users/sign_in";
  public redirectMethod: string = "post";

  public email: Nullable<string> = null;
  public password: Nullable<string> = null;
  public rememberMe: boolean = false;
  public errorText: Nullable<string> = null;
  private loader = useLoader();

  public get currentLocation(): string {
    return window.location.href;
  }

  public get providers(): Settings.Provider[] {
    return settingsService.options.registration.omniauth.providers;
  }

  public get partnersEnabled(): boolean {
    return settingsService.options.region.code === "DE";
  }

  public get trackingOnboardingType(): string {
    return this.onboardingType ?? "modal-login-page";
  }

  public get registrationHintHeadline(): string {
    const secondPart = this.isFullScreen
      ? this.$t("ui.components.base.login_form.registration_hint.second_full")
      : this.$t("ui.components.base.login_form.registration_hint.second");

    return `${this.$t("ui.components.base.login_form.registration_hint.first")} <b>${secondPart}</b>`;
  }

  public async submit(): Promise<void> {
    if (this.email === null || this.password === null) return;

    try {
      this.loader.start();
      const response = await apiService.session.login(this.email, this.password, this.rememberMe);
      await this.trackLoginSuccess(response.data);
      this.trackLoginOnUserpilot(response.data);
      location.reload();
    } catch (err) {
      const error = err as AxiosError<any>;
      this.trackLoginFailure();
      if (error.request.status === 401) {
        this.errorText = this.$t("ui.components.base.login_form.failure.invalid") as string;
      } else if (error.request.status === 307) {
        const responseBody = JSON.parse(error.request.response);
        this.redirectUrl = responseBody.path;
        this.redirectMethod = responseBody.method;
        await this.$nextTick();
        const redirectButton = this.$refs.redirectButton as buttonTo;
        redirectButton.submit();
      } else if (error.response?.data?.errors?.reason === "unconfirmed") {
        window.location.href = this.$settings.options.login.block_unconfirmed_user_path;
      } else {
        this.errorText = this.$t("ui.components.base.login_form.failure.error_occurred") as string;
      }
      this.loader.stop();
    }
  }

  private async trackLoginSuccess(response: SessionApi.LoginResponse): Promise<void> {
    const { user } = response;

    const eventLabel: Indexable<string> = {
      login_type: user.provider || "email",
      professional: user.professional !== null ? `${user.professional}` : "not set",
      classification: user.classification || "not set",
    };

    gtmTrackingService.track({
      eventCategory: GTM_EVENT_CATEGORY,
      eventAction: "LoginSuccessful",
      eventLabel,
    });
  }

  private trackLoginOnUserpilot(response: SessionApi.LoginResponse): void {
    const { user } = response;
    // BVK: note that this user data is limited, and will be fully re-initialized after a subsequent page reload
    Userpilot.identifyUser(user);
    Userpilot.trackEvent("user logged in");
  }

  private trackLoginFailure(): void {
    gtmTrackingService.track({
      eventCategory: GTM_EVENT_CATEGORY,
      eventAction: "LoginFailed",
    });
  }
}
