
import { useNotifications } from "@composables/notifications";
import { defineComponent, getCurrentInstance, onMounted, ref, computed } from "vue";
import NotificationsList from "@components/notifications/list.vue";
import { useI18n } from "@composables/i18n";
import { CurrentUser } from "@utils/user";
import { ciTrackingService } from "@services/ci-tracking.service";

export default defineComponent({
  components: {
    NotificationsList,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { popup, filtered, markAllAsRead } = useNotifications();
    const { t } = useI18n(getCurrentInstance());

    const currentTab = ref(0);

    const showMarkAllAsReadButton = computed(() => currentTab.value === 0 || filtered.value.popup.unread.length);

    onMounted(async () => {
      if (CurrentUser.signedIn) await popup.fetch();
    });

    const setCurrentTab = (displayUnread: boolean) => {
      currentTab.value = displayUnread ? 1 : 0;
    };

    const handleSeeAllClick = () => {
      ciTrackingService.trackClick("ButtonSeeAll", "all", "NotificationPopup");
      popup.close();
    };

    const handleReadAllClick = () => {
      ciTrackingService.trackClick("ButtonReadAll", "all", "NotificationPopup");
      markAllAsRead("popup");
    };

    return {
      list: popup.data,
      loading: popup.loading,
      close: popup.close,
      currentTab,
      showMarkAllAsReadButton,
      filtered: filtered,
      markAllAsRead,
      handleReadAllClick,
      handleSeeAllClick,
      setCurrentTab,
      t,
    };
  },
});
