
import { defineComponent, computed } from "vue";
import { getModule } from "vuex-module-decorators";
import { AnalyticsStoreModule } from "@stores/analytics.store";

const analyticsStore = getModule(AnalyticsStoreModule);

export default defineComponent({
  name: "CiOmniauthButtons",
  props: {
    providers: { type: Array<Settings.Provider>, default: () => [] },
    onboardingType: { type: String, default: "" },
    gatingBannerLocation: { type: String, default: "" },
    authenticityToken: { type: String, default: "" },
    registrationReferrer: { type: String, default: () => window.location.href },
  },

  setup(props) {
    const extraParams = computed(() => {
      const loginLocation = analyticsStore.loginLocation || props.onboardingType;
      return {
        login_location: loginLocation,
        gating_banner_location: props.gatingBannerLocation,
        onboarding_type: props.onboardingType,
        registration_referrer: props.registrationReferrer,
      };
    });

    function capitalize(provider: string) {
      const result = provider.replace("_oauth2", "");
      return result[0].toUpperCase() + result.slice(1);
    }

    function providerIconClass(provider: string) {
      // used icons to make sure they are included in the webfont:
      // mdi-apple mdi-facebook mdi-google mdi-linkedin mdi-twitter
      switch (provider) {
        case "google_oauth2":
          return "mdi-google";
        case "apple":
        case "facebook":
        case "linkedin":
        case "twitter":
          return `mdi-${provider}`;
        default:
          throw new Error(`${provider} has no icon class defined!`);
      }
    }

    return {
      extraParams,
      capitalize,
      providerIconClass,
    };
  },
});
