import { utilityService } from "@services/utility.service";
import { i18nInstance } from "@composables/i18n";
import { Emitter } from "@utils/events";
import { Ref, ref } from "vue";

export const ToastDefaults: Toasts.Props = {
  title: "",
  message: "",
  variant: "notice",
  toastClass: "",
  modal: undefined,
  to: undefined,
  href: "",
};

export const BuildToast = (props: Toasts.Props) => {
  const options: Toasts.Props = {
    ...ToastDefaults,
    ...props,
    id: props.id || utilityService.guid(),
  };
  Toasts.value.push(options);

  return options;
};

export const Toasts: Ref<Toasts.Props[]> = ref([]);

export const CreateToast: Record<Toasts.Variants, Toasts.CreateFn> = {
  success(message, options = {}) {
    BuildToast({
      title: i18nInstance.t("ui.shared.global.toasts.success") as string,
      variant: "success",
      ...options,
      message,
    });
  },
  warning(message, options = {}) {
    BuildToast({
      title: i18nInstance.t("ui.shared.global.toasts.warning") as string,
      variant: "warning",
      ...options,
      message,
    });
  },
  error(message, options = {}) {
    BuildToast({
      title: i18nInstance.t("ui.shared.global.toasts.error") as string,
      variant: "error",
      ...options,
      message,
    });
  },
  info(message, options = {}) {
    BuildToast({
      title: i18nInstance.t("ui.shared.global.toasts.notification") as string,
      variant: "info",
      ...options,
      message,
    });
  },
  notice(message, options = {}) {
    BuildToast({
      title: i18nInstance.t("ui.shared.global.toasts.notification") as string,
      variant: "notice",
      ...options,
      message,
    });
  },
};

export const DestroyToast = (id: string) => {
  Toasts.value = Toasts.value.filter((toast) => toast.id !== id);
};

Emitter.on("ci::toast::create", BuildToast);

Emitter.on("ci::toast::destroy", (id) => {
  Toasts.value = Toasts.value.filter((toast) => toast.id !== id);
});
