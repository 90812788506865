
import { Component, Prop, Watch } from "vue-property-decorator";
import { FormElementBase } from "./base";
import { ValidationProvider } from "vee-validate";
import { utilityService } from "@services/utility.service";

@Component({
  name: "ci-input-number",
  components: { ValidationProvider },
})
export default class extends FormElementBase {
  @Prop({ default: null }) public value!: number;
  @Prop({ default: null }) public min!: Nullable<number>;
  @Prop({ default: null }) public max!: Nullable<number>;
  @Prop({ default: false }) public hideArrows!: boolean;
  @Prop({ default: false }) public lazy!: boolean;
  @Prop({ default: null }) public decimals!: Nullable<number>;
  @Prop({ default: NaN }) public defaultValue!: number;

  public showValue: Nullable<number> = null;
  public manualInput = false;

  public get populated(): boolean {
    if (typeof this.showValue === "number") return true;

    return this.showValue !== undefined && this.showValue !== null;
  }

  @Watch("value")
  onValueChanged(val: number): void {
    if (this.decimals || this.decimals === 0) {
      this.showValue = utilityService.round(val, this.decimals) as number;
    }
    this.manualInput = false;
  }

  public handleInput(ev: ExplicitAny): void {
    this.manualInput = true;
    const val = parseFloat(ev.target.value);
    if (!this.lazy) {
      this.$emit("input", this.validateValue(val));
    }
  }

  public handleFocus(): void {
    if (this.lazy) this.manualInput = true;
  }

  public handleBlur(ev: ExplicitAny): void {
    this.manualInput = false;
    const val = parseFloat(ev.target.value);
    this.$emit("input", this.validateValue(val));
  }

  protected async mounted(): Promise<void> {
    if (this.decimals || this.decimals === 0) {
      this.showValue = utilityService.round(this.value, this.decimals) as number;
    }
  }

  private validateValue(val: number) {
    if (isNaN(val)) {
      this.showValue = this.defaultValue;
      return this.defaultValue;
    }
    if (this.max && val > this.max) {
      this.showValue = this.max;
      return this.max;
    }
    if ((this.min || this.min === 0) && val < this.min) {
      this.showValue = this.min;
      return this.min;
    }

    return val;
  }
}
