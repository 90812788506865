import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { store } from "@stores";
import { ViewportSizes } from "@models/enums";

const getViewport = (): Viewport.VPs => {
  const width = window.innerWidth;

  if (width < ViewportSizes.SM) {
    return "xs";
  } else if (width >= ViewportSizes.SM && width < ViewportSizes.MD) {
    return "sm";
  } else if (width >= ViewportSizes.MD && width < ViewportSizes.LG) {
    return "md";
  } else if (width >= ViewportSizes.LG && width < ViewportSizes.XL) {
    return "lg";
  } else if (width >= ViewportSizes.XL && width < ViewportSizes.XXL) {
    return "xl";
  } else if (width >= ViewportSizes.XXL) {
    return "xxl";
  } else {
    return "print";
  }
};

@Module({
  dynamic: true,
  store: store,
  name: "viewport",
  namespaced: true,
})
export class ViewportStoreModule extends VuexModule {
  public elementSizes: GlobalElementSizes = {
    header: 0,
    content_header_sticky: 0,
    content_header: 0,
    content: 0,
    footer: 0,
  };

  private pCurrentViewport: Viewport.VPs = getViewport();

  get currentViewport(): Viewport.VPs {
    return this.pCurrentViewport;
  }

  get handheld(): boolean {
    return ["xs", "sm", "md"].includes(this.pCurrentViewport);
  }

  get smartphone(): boolean {
    return ["xs", "sm"].includes(this.pCurrentViewport);
  }

  @Action public update(): Promise<Nullable<string>> {
    return new Promise((resolve) => {
      const viewport = getViewport();

      if (viewport !== this.pCurrentViewport) {
        this.context.commit("set", viewport);
        resolve(viewport);
      } else {
        resolve(null);
      }
    });
  }

  @Mutation public set(viewport: Viewport.VPs): void {
    this.pCurrentViewport = viewport;
  }

  @Action public updateElementSizes(sizes: GlobalElementSizes): void {
    this.context.commit("setElementSizes", sizes);
  }

  @Mutation public setElementSizes(sizes: GlobalElementSizes): void {
    this.elementSizes = sizes;
  }
}
