var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "notification",
      on: {
        "!click": function ($event) {
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "notification-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "notification-icon-column",
            class: { "is-unread": _vm.isUnread },
          },
          [
            _c("div", {
              staticClass: "notification-icon mdi",
              class: _vm.notification.icon,
            }),
            _vm.isUnread
              ? _c("div", { staticClass: "notification-dot-indicator" })
              : _vm._e(),
            _vm.isUnread
              ? _c("div", { staticClass: "notification-bar-indicator" })
              : _vm._e(),
          ]
        ),
        _c("div", { staticClass: "notification-content-column" }, [
          _c(
            "div",
            {
              staticClass: "notification-header",
              class: { "font-weight-bold": _vm.isUnread },
            },
            [
              _c("div", {
                staticClass: "font-weight-bold",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(_vm.notificationTitleTranslationSource)
                  ),
                },
              }),
              _c("small", {
                staticClass:
                  "notification-age text-sm text-right text-muted timestamp flex-shrink-1 ml-3 text-nowrap",
                domProps: { textContent: _vm._s(_vm.notification.age) },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "notification-body" },
            [
              _c(_vm.notificationComponent, {
                tag: "component",
                attrs: {
                  notification: _vm.notification.original,
                  to: _vm.notification.to,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }