import { BaseApiService } from "./base";

const API_ENDPOINT_MOST_RELEVANT = "/api/v4/newscenter/most_relevant";
const API_ENDPOINT_MOST_READ = "/api/v4/newscenter/most_read";
const API_ENDPOINT_LATEST = "/api/v4/newscenter/latest";
const API_ENDPOINT_NEWS_SOURCES = "/api/v4/news_sources";

export class NewsCenterApiService extends BaseApiService implements NewsCenterApi.Service {
  public mostRelevant: NewsCenterApi.MostRelevantFn = (cancelToken = null, params = {}) =>
    this.axios.get(API_ENDPOINT_MOST_RELEVANT, { params, cancelToken: cancelToken?.token });

  public mostRead: NewsCenterApi.MostReadFn = (cancelToken = null, params = {}) =>
    this.axios.get(API_ENDPOINT_MOST_READ, { params, cancelToken: cancelToken?.token });

  public latest: NewsCenterApi.LatestFn = (cancelToken = null, params = {}) =>
    this.axios.get(API_ENDPOINT_LATEST, { params, cancelToken: cancelToken?.token });

  public newsSources: NewsCenterApi.GetNewsSourcesFn = () => this.axios.get(API_ENDPOINT_NEWS_SOURCES);
}
