
import { apiService } from "@services/api.service";
import { CreateToast } from "@utils/toasts";
import { useI18n } from "@composables/i18n";
import {
  computed,
  ComputedRef,
  defineComponent,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { Emitter } from "@utils/events";
import { ROUTENAMES } from "@models/constants/routes";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<"contents" | "products" | "portfolios" | "model_portfolios">,
      required: true,
      default: "",
    },
    buttonSize: {
      type: String,
      default: "sm",
    },
    variant: {
      type: String as PropType<Components.Button.Variant>,
      default: "ghost-primary",
    },
    withLabel: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<Articles.TileData | Products.TileData | Portfolios.TileData>,
      default: null,
      required: true,
    },
    beforeFollow: {
      type: Function as PropType<() => Promise<boolean>>,
      default: null,
    },
    beforeUnfollow: {
      type: Function as PropType<() => Promise<boolean>>,
      default: null,
    },
  },
  emits: ["follow", "unfollow"],
  setup(props, { emit }) {
    const followed = ref(false);
    const { t } = useI18n(getCurrentInstance());

    watch(
      () => props.item,
      () => {
        followed.value =
          (props.item as Articles.TileData).watched ||
          (props.item as Products.TileData | Portfolios.TileData | Portfolios.Data).followed ||
          false;
      }
    );

    onMounted(() => {
      followed.value =
        (props.item as Articles.TileData).watched ||
        (props.item as Products.TileData | Portfolios.TileData | Portfolios.Data).followed ||
        false;
      Emitter.on("ci::bookmark::followed", (data) => bookmarkEmitBusHandler(data, true));
      Emitter.on("ci::bookmark::unfollowed", (data) => bookmarkEmitBusHandler(data, false));
    });

    onBeforeUnmount(() => {
      Emitter.off("ci::bookmark::followed");
      Emitter.off("ci::bookmark::unfollowed");
    });

    const bookmarkEmitBusHandler = (
      { id, type }: { id: string | number; type: "contents" | "products" | "portfolios" | "model_portfolios" },
      followedValue: boolean
    ) => {
      if (id === props.item.id && type === props.type) {
        followed.value = followedValue;
      }
    };

    const toastRedirectUrl: ComputedRef<string | undefined> = computed(() => {
      switch (props.type) {
        case "contents":
          return ROUTENAMES.CONTENTS_WATCHLIST;
        case "products":
          return ROUTENAMES.PRODUCTS_WATCHLIST_INDEX;
        case "portfolios":
        case "model_portfolios":
          return ROUTENAMES.PORTFOLIOS_INDEX;
        default:
          return undefined;
      }
    });

    const followUrl = () => {
      if (props.type === "model_portfolios") return `/api/v4/portfolios/${props.item.id}/follow`;

      return `/api/v4/${props.type}/${props.item.id}/follow`;
    };

    const follow = async () => {
      if (props.beforeFollow && !(await props.beforeFollow())) return;
      try {
        await apiService.axios.post(followUrl());
        if (props.type === "products") (props.item as Products.TileData).followed = true;
        else if (props.type === "contents") (props.item as Articles.TileData).watched = true;
        else (props.item as Portfolios.TileData).followed = true;

        emit("follow", props.item);
        Emitter.emit("ci::bookmark::followed", { id: props.item.id, type: props.type });

        CreateToast.success(toastPayload.value.follow.success, {
          to: { name: toastRedirectUrl.value },
        });
        followed.value = true;
      } catch (err) {
        CreateToast.error(toastPayload.value.follow.error);
      }
    };

    const unfollowUrl = () => {
      if (props.type === "model_portfolios") return `/api/v4/portfolios/${props.item.id}/unfollow`;

      return `/api/v4/${props.type}/${props.item.id}/unfollow`;
    };

    const unfollow = async () => {
      if (props.beforeUnfollow && !(await props.beforeUnfollow())) return;
      try {
        await apiService.axios.post(unfollowUrl());

        if (props.type === "products") (props.item as Products.TileData).followed = false;
        else if (props.type === "contents") (props.item as Articles.TileData).watched = false;
        else (props.item as Portfolios.TileData).followed = false;

        emit("unfollow", props.item);
        Emitter.emit("ci::bookmark::unfollowed", { id: props.item.id, type: props.type });
        CreateToast.success(toastPayload.value.unfollow.success);
        followed.value = false;
      } catch (err) {
        CreateToast.error(toastPayload.value.unfollow.error);
      }
    };

    const toastPayload = computed(() => {
      const title = (props.item as Articles.TileData).title || (props.item as Products.TileData).name || "";

      return {
        follow: {
          success: t(`ui.components.base.bookmark.${props.type}.follow.success_html`, {
            title,
          }) as string,
          error: t(`ui.components.base.bookmark.${props.type}.follow.error`, { title }) as string,
        },
        unfollow: {
          success: t(`ui.components.base.bookmark.${props.type}.unfollow.success`, {
            title,
          }) as string,
          error: t(`ui.components.base.bookmark.${props.type}.unfollow.error`, { title }) as string,
        },
      };
    });

    return {
      follow,
      unfollow,
      followed,
    };
  },
});
