
import { Vue, Component } from "vue-property-decorator";
import { Loader } from "@composables/loader";

@Component({
  name: "ci-loading",
})
export default class extends Vue {
  public get loading(): boolean {
    return Loader.loading.state;
  }

  public get slow(): boolean {
    return Loader.loading.slow;
  }
}
