import Axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { i18nInstance } from "@composables/i18n";

import { Api } from "@services/api";
import { AdvisorsApiService } from "@services/api/advisors-api.service";
import { AdvisoryServicesApiService } from "@services/api/advisory-services-api.service";
import { ArticlesApiService } from "@services/api/articles-api.service";
import { CompanyProfilesApiService } from "@services/api/company-profiles-api.service";
import { ComparisonsApiService } from "@services/api/comparisons-api.service";
import { ContentHubsApiService } from "./api/content-hubs-api.service";
import { ContentsApiService } from "@services/api/contents-api.service";
import { ConversationsApiService } from "@services/api/conversations-api.service";
import { CountriesApiService } from "@services/api/countries-api.service";
import { NewsCenterApiService } from "@services/api/news-center-api.service";
import { NotificationsApiService } from "@services/api/notifications-api.service";
import { PagesApiService } from "@services/api/pages-api.service";
import { PeergroupsApiService } from "@services/api/peergroups-api.service";
import { PerformanceAlarmApiService } from "@services/api/performance-alarm-api.service";
import { PortfolioApiService } from "@services/api/portfolios-api.service";
import { ProductsApiService } from "@services/api/products-api.service";
import { SessionApiService } from "@services/api/session-api.service";
import { SettingsApiService } from "@services/api/settings-api.service";
import { SocialEventsApiService } from "@services/api/social-events-api.service";
import { RegistrationsApiService } from "@services/api/registrations-api.service";
import { UsersApiService } from "@services/api/users-api.service";
import { routingService } from "@services/routing.service";
import { settingsService } from "@services/settings.service";

import { CreateToast } from "@utils/toasts";
import { CurrentUser } from "@utils/user";

class Service implements Api.Service {
  public advisors: AdvisorsApi.Service;
  public advisoryServices: AdvisoryServicesApi.Service;
  public articles: ArticlesApi.Service;
  public companies: CompanyProfilesApiService;
  public comparisons: ComparisonsApi.Service;
  public contents: ContentsApi.Service;
  public contentHubs: ContentHubsApi.Service;
  public conversations: ConversationsApi.Service;
  public countries: CountriesApi.Service;
  public news_center: NewsCenterApi.Service;
  public notifications: NotificationsApi.Service;
  public pages;
  public peergroups: PeergroupsApi.Service;
  public performanceAlarms: PerformanceAlarmApiService;
  public portfolios: PortfoliosApi.Service;
  public products: ProductsApi.Service;
  public session: SessionApi.Service;
  public settings: SettingsApi.Service;
  public social_events: SocialEventsApi.Service;
  public users: UsersApi.Service;
  public registrations: RegistrationsApi.Service;

  private axiosInstance = Axios.create();
  private fileAxiosInstance = Axios.create();
  private optionsPassed = false;

  constructor() {
    this.advisors = new AdvisorsApiService(this.axios);
    this.advisoryServices = new AdvisoryServicesApiService(this.axios);
    this.articles = new ArticlesApiService(this.axios);
    this.companies = new CompanyProfilesApiService(this.axios);
    this.comparisons = new ComparisonsApiService(this.axios);
    this.contents = new ContentsApiService(this.axios);
    this.contentHubs = new ContentHubsApiService(this.axios);
    this.conversations = new ConversationsApiService(this.axios);
    this.countries = new CountriesApiService(this.axios);
    this.news_center = new NewsCenterApiService(this.axios);
    this.notifications = new NotificationsApiService(this.axios);
    this.pages = new PagesApiService(this.axios);
    this.peergroups = new PeergroupsApiService(this.axios);
    this.performanceAlarms = new PerformanceAlarmApiService(this.axios);
    this.portfolios = new PortfolioApiService(this.axios);
    this.products = new ProductsApiService(this.axios);
    this.registrations = new RegistrationsApiService(this.axios);
    this.session = new SessionApiService(this.axios);
    this.settings = new SettingsApiService(this.axios);
    this.social_events = new SocialEventsApiService(this.axios);
    this.users = new UsersApiService(this.axios);
  }

  get axios(): AxiosInstance {
    if (!this.optionsPassed) this.setAxiosConfig(this.axiosInstance);
    return this.axiosInstance;
  }

  /**
   * File axios is an axios instance that has default parameters set. is the same as #axios except that this one does
   * not set a default header for content type.
   *
   * By not setting this header it allows for file uploads.
   */
  // FIXME: use interceptors to set content type form multipart?
  get fileAxios(): AxiosInstance {
    this.fileAxiosInstance.defaults.headers.common = Object.assign({}, this.axios.defaults.headers.common);
    // unset default content type as this prevents us from sending files or anything but json
    delete this.fileAxiosInstance.defaults.headers.common["content-type"];
    return this.fileAxiosInstance;
  }

  public getArticlePage: Api.GetArticlePageFn = (url, page, type?, requestHeader = false) => {
    const params: { [key: string]: string | number | boolean } = {};
    params.request_header = requestHeader;

    if (page > 1) params.page = page;
    if (type && type.slug !== "all") params.feed_content_type = type.slug;

    return this.axios.get(url, { params });
  };

  public getUser: Api.GetUserFn = (userId: number) => this.axios.get(`/api/v3/frontend/users/${userId}`);

  public getUserContactDetails: Api.GetUserContactDetailsFn = (userId: number) =>
    this.axios.get(`/api/v3/frontend/users/${userId}/contact_details`);

  public refreshSettings: Api.RefreshSettingsFn = async () => {
    const response = await this.axios.get("/api/v4/settings");
    settingsService.merge(response.data);
    CurrentUser.initialize();
  };

  public emailSettingsUser: Api.EmailSettingsUserFn = (token: string, newsletter: Record<string, boolean>) => {
    const data: {
      token: string;
      user: {
        events_invitations?: boolean;
        newsletter_capinsider?: boolean;
        newsletter_earlybird?: boolean;
        newsletter_events?: boolean;
        newsletter_lunchbreak?: boolean;
        newsletter_new_features?: boolean;
        email_after_onboarding?: boolean;
        mail_notification_for_unread_messages?: boolean;
        mail_notification_for_unread_notifications?: boolean;
      };
    } = {
      token: token,
      user: {},
    };
    Object.entries(newsletter).forEach(([key, value]) => {
      switch (key) {
        case "capinsider":
          data.user.newsletter_capinsider = value;
          break;
        case "earlybird":
          data.user.newsletter_earlybird = value;
          break;
        case "events":
          data.user.newsletter_events = value;
          break;
        case "invitations":
          data.user.events_invitations = value;
          break;
        case "lunchbreak":
          data.user.newsletter_lunchbreak = value;
          break;
        case "new_features":
          data.user.newsletter_new_features = value;
          break;
        case "after_onboarding":
          data.user.email_after_onboarding = value;
          break;
        case "notification_mailer":
          data.user.mail_notification_for_unread_messages = value;
          break;
        case "notifications":
          data.user.mail_notification_for_unread_notifications = value;
          break;
        default:
          // do nothing
          break;
      }
    });
    if (Object.entries(data.user).length === 0) {
      return new Promise((resolve) => resolve);
    }

    return this.axios.post("/api/v3/frontend/user_email_settings", data);
  };

  public handleAPIError(
    error: ExplicitAny,
    callback: (errorMessage: string) => void = (errorMessage) => CreateToast.error(errorMessage)
  ): void {
    const errorMessages = error.response?.data?.errors as { [key: string]: Array<string> };
    let firstErrorMessage;
    if (errorMessages) {
      if (Array.isArray(errorMessages)) {
        firstErrorMessage = errorMessages[0].msg || errorMessages[0];
      } else if (Object.keys(errorMessages)[0] === "email") {
        firstErrorMessage = `${Object.keys(errorMessages)[0]}: ${errorMessages[Object.keys(errorMessages)[0]][0]}`;
      } else if (Object.keys(errorMessages)[0] === "financial_broker_details") {
        firstErrorMessage = errorMessages[Object.keys(errorMessages)[1]][0];
      } else {
        firstErrorMessage = errorMessages[Object.keys(errorMessages)[0]][0];
      }
    }
    if (error.response?.status === 422 && !firstErrorMessage) return;

    callback(firstErrorMessage || error.message);
  }

  public handleRoutingAPIError: Api.HandleRoutingApiErrorFn = (error, callback) => {
    let message = "";
    let code = 500;
    const status = "";
    if (error instanceof Error || (typeof error === "object" && error !== null)) {
      const { response } = error as AxiosError<any>;
      if (response) {
        code = response.status;
        if (response.data?.errors?.length > 0 && response.data.errors[0].msg) {
          message = response.data.errors[0].msg;
        } else {
          if (settingsService.options.environment === "development") {
            // make errors even traceable in the dev environment
            console.error(error);
          }
          message = i18nInstance.t("flash_messages.error_occurred") as string;
        }
      }
    } else {
      message = error;
    }
    const routerError = { message, code, status };

    if (code === 401) location.href = "/users/sign_in";
    else if (callback) callback(routerError);

    return routerError;
  };

  private setAxiosConfig: Api.SetAxiosConfigfn = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use(this.setCapReferrer);
    axiosInstance.interceptors.request.use(this.copyUtmParamsToRequest);

    axiosInstance.defaults.headers.common["content-type"] = "application/json";
    axiosInstance.defaults.headers.common.accept =
      "application/json, application/vnd.capitalinside.v3, application/vnd.capitalinside.v4";
    axiosInstance.defaults.headers.common["x-requested-with"] = "XMLHttpRequest";

    if (settingsService.options.environment !== "test")
      axiosInstance.defaults.headers.common["x-csrf-token"] = settingsService.options!.csrfToken;
    this.optionsPassed = true;
  };

  private setCapReferrer(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    if (config.headers) config.headers.CAP_Referrer = routingService.referrer;
    return config;
  }

  /*
   * Will copy utm parameters on the current url onto api requests for tracking purposes.
   * It will only copy utm parameters on the page where capinside was originally entered.
   *
   * We determine this page by checking if the referrer has the same origin as our current
   * page. If it has a referrer of "" or a different origin we can assume that we entered
   * capinside here.
   */
  private copyUtmParamsToRequest(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    if (routingService.referrer.startsWith(location.origin)) return config;

    const currentParams = new URLSearchParams(window.location.search.substring(1));

    currentParams.forEach((value, key) => {
      if (key.startsWith("utm")) {
        config.params = config.params ?? {};
        config.params[key] = value;
      }
    });

    return config;
  }
}

export const apiService = new Service();
