
import { DestroyToast } from "@utils/toasts";
import { defineComponent, computed, onMounted, PropType } from "vue";

export default defineComponent({
  props: {
    id: { default: "", type: String, required: true },
    title: { default: "", type: String, required: true },
    message: { default: "", type: String, required: true },
    variant: { default: "success", type: String as PropType<Toasts.Variants> },
    toastClass: { default: "", type: String },
    timeout: { default: 5000, type: Number },
    modal: { default: null, type: Object as PropType<Nullable<Toasts.ModalOptions>> },
    to: { default: null, type: Object as PropType<Nullable<RawLocation>> },
    href: { default: "", type: String },
  },
  setup(props) {
    const classes = computed(() => [`${props.variant}`, props.toastClass].filter((i) => i));

    onMounted(() => {
      setTimeout(() => DestroyToast(props.id), props.timeout);
    });

    const removeToast = (): void => {
      DestroyToast(props.id);
    };

    return { classes, removeToast };
  },
});
