import { algoliaService } from "@services/algolia.service";
import { SearchClient } from "algoliasearch";
import { MultipleQueriesResponse, SearchResponse } from "@algolia/client-search";
import { settingsService } from "@services/settings.service";
import { useRegion } from "@composables/region";

interface Query {
  indexName: string;
  query: string;
  hitsPerPage: number;
  page: number;
  filters?: string;
  numericFilters?: string[];
  optionalFilters?: string | string[];
  queryLanguages?: string[];
  restrictSearchableAttributes?: string[];
  attributesToRetrieve?: string[];
}

interface ResultParser {
  key: CapAlgolia.SearchType;
  parser: (arg0: ExplicitAny) => ExplicitAny;
}

export class GlobalSearch {
  public currentSearchType = "all";
  public paginationPage = 0;
  public searchOptions: CapAlgolia.SearchOptions;

  private readonly INTERNAL_CONTENT_FILTER = `external:false`;

  private readonly searchClient: SearchClient;
  private readonly indexNames: CapAlgolia.IndexNames;
  private readonly locale: string;
  private readonly queryLanguages: Array<string>;
  private queries: Query[] = [];
  private parsers: Array<ResultParser> = [];
  private region = useRegion();

  constructor(options: CapAlgolia.SearchOptions = {}) {
    this.indexNames = settingsService.options.algolia_index_names;
    this.locale = settingsService.options.locale;
    this.queryLanguages = settingsService.options.algolia_query_languages;
    this.searchClient = algoliaService.getClient();
    this.searchOptions = options;
  }

  public async search(term: string): Promise<CapAlgolia.GlobalSearchResult> {
    this.buildAlgoliaQueries(term);

    const response = await this.searchClient.multipleQueries(this.queries);
    const parsed: { [key: string]: ExplicitAny } = {};
    (response.results as Array<SearchResponse>).map((result, index) => {
      const { key: resultKey, parser } = this.parsers[index];
      parsed[resultKey] = this.mapResult(result, parser);
    });
    return {
      raw: response as MultipleQueriesResponse<SearchResponse>,
      parsed: parsed as CapAlgolia.ParsedSearchResult,
    };
  }

  private buildAlgoliaQueries(query: string): void {
    this.resetAlgoliaQueries();

    this.enqueueUsers(query);
    this.enqueueArticles(query);
    this.enqueuePodcasts(query);
    this.enqueueVideos(query);
    this.enqueueWebcasts(query);
    this.enqueueFunds(query);
    this.enqueueCompanyProfiles(query);
    this.enqueueSocialEvents(query);
    this.enqueuePortfolios(query);
    this.enqueueContentRoutes(query);
  }

  private resetAlgoliaQueries(): void {
    this.parsers = [];
    this.queries = [];
  }

  private enqueueUsers(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "users") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.users,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      optionalFilters: ["_tags:has_image", `country_code:${this.region.currentRegionCode()}`],
      filters: "_tags:can_be_found_on_site_wide_search",
      ...this.searchOptions["users"],
    });
    this.parsers.push({
      key: "users",
      parser: this.mapUser,
    });
  }

  private enqueueArticles(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "articles") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.contents,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      filters: "type:Article",
      optionalFilters: this.INTERNAL_CONTENT_FILTER,
      attributesToRetrieve: algoliaService.NEWS_TILE_ATTRIBUTES_TO_RETRIEVE,
      ...this.searchOptions["articles"],
    });
    this.parsers.push({
      key: "articles",
      parser: this.mapArticle,
    });
  }

  private enqueuePodcasts(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "podcasts") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.contents,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      filters: "type:Podcast",
      optionalFilters: this.INTERNAL_CONTENT_FILTER,
      attributesToRetrieve: algoliaService.NEWS_TILE_ATTRIBUTES_TO_RETRIEVE,
      ...this.searchOptions["podcasts"],
    });
    this.parsers.push({
      key: "podcasts",
      parser: this.mapArticle,
    });
  }

  private enqueueVideos(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "videos") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.contents,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      filters: "type:Video",
      optionalFilters: this.INTERNAL_CONTENT_FILTER,
      attributesToRetrieve: algoliaService.NEWS_TILE_ATTRIBUTES_TO_RETRIEVE,
      ...this.searchOptions["videos"],
    });
    this.parsers.push({
      key: "videos",
      parser: this.mapArticle,
    });
  }

  private enqueueWebcasts(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "webcasts") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.contents,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      filters: "type:Webcast",
      optionalFilters: this.INTERNAL_CONTENT_FILTER,
      attributesToRetrieve: algoliaService.NEWS_TILE_ATTRIBUTES_TO_RETRIEVE,
      ...this.searchOptions["webcasts"],
    });
    this.parsers.push({
      key: "webcasts",
      parser: this.mapArticle,
    });
  }

  private enqueueFunds(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "funds") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.funds,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      ...this.searchOptions["funds"],
    });
    this.parsers.push({
      key: "funds",
      parser: this.mapProduct,
    });
  }

  private enqueueSocialEvents(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "social_events") {
      return;
    }

    const currentTimestamp = new Date().getTime() / 1000;
    this.queries.push({
      indexName: this.indexNames.social_events,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      numericFilters: [`published_at < ${currentTimestamp}`, `end_date > ${currentTimestamp}`],
      ...this.searchOptions["social_events"],
    });
    this.parsers.push({
      key: "social_events",
      parser: this.mapSocialEvent,
    });
  }

  private enqueuePortfolios(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "portfolios") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.portfolios,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
      ...this.searchOptions["portfolios"],
    });
    this.parsers.push({
      key: "portfolios",
      parser: this.mapPortfolio,
    });
  }

  private enqueueCompanyProfiles(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "company_profiles") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.company_profiles,
      query,
      hitsPerPage: 6,
      restrictSearchableAttributes: ["name", `description_${this.locale}`],
      queryLanguages: this.queryLanguages,
      page: this.paginationPage,
      ...this.searchOptions["company_profiles"],
    });
    this.parsers.push({
      key: "company_profiles",
      parser: this.mapCompany,
    });
  }

  private enqueueContentRoutes(query: string): void {
    if (this.currentSearchType !== "all" && this.currentSearchType !== "content_routes") {
      return;
    }

    this.queries.push({
      indexName: this.indexNames.content_routes,
      query,
      hitsPerPage: 6,
      page: this.paginationPage,
    });
    this.parsers.push({
      key: "content_routes",
      parser: (contentRoute: ContentHubs.Algolia): ContentHubs.Algolia => contentRoute,
    });
  }

  private mapResult<T>(searchResult: SearchResponse, mapper: (h: ExplicitAny) => T): CapAlgolia.Result<T> {
    return {
      meta: {
        nbhits: searchResult.nbHits,
      },
      records: searchResult.hits.map(mapper),
    };
  }

  private mapArticle(article: Articles.AlgoliaContent): Articles.TileData {
    return algoliaService.mapAlgoliaContent(article);
  }

  private mapProduct(product: Products.Algolia): Products.Legacy.Product {
    return {
      ...product,
      updated_at: new Date(product.updated_at * 1000).toISOString(),
      publication_date: new Date(product.publication_date * 1000).toISOString(),
      price_last_updated_at: new Date(product.price_last_updated_at * 1000).toISOString(),
      launch_date: new Date(product.launch_date * 1000).toISOString(),
      weight: 0,
      followed: false,
      fondcategory: {
        name: product.focus_asset,
        url: product.url, // TODO: this is a lie but a good lie
      },
    };
  }

  private mapCompany(algoliaCompany: Companies.Algolia): Companies.Tile {
    return {
      ...algoliaCompany,
      company_url: algoliaCompany.url,
      company_logo: algoliaCompany.logo,
    };
  }

  private mapSocialEvent(algoliaSocialEvent: Events.Algolia): Events.Event {
    return {
      ...algoliaSocialEvent,
      id: parseInt(algoliaSocialEvent.id, 10),
      event_date: new Date(algoliaSocialEvent.date * 1000).toISOString(),
      event_end_date: new Date(algoliaSocialEvent.end_date * 1000).toISOString(),
      headline: algoliaSocialEvent.title,
      event_url: algoliaSocialEvent.event_link,
      participationState: "loading",
    };
  }

  private mapUser(algoliaUser: Users.Algolia): Users.Algolia {
    return algoliaUser;
  }

  private mapPortfolio(algloliaPortfolio: Portfolios.Algolia): Portfolios.Algolia {
    return algloliaPortfolio;
  }
}
