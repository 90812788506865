var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "ci-layout" },
    [
      _c("ci-professional-gate"),
      _c("ci-header"),
      _c(
        "section",
        { ref: "page-content", staticClass: "ci-content" },
        [
          _c("portal-target", {
            ref: "content-header",
            attrs: { name: "content-header" },
          }),
          _c(
            "div",
            {
              staticClass: "ci-content__body d-flex",
              class: _vm.wrapContent ? "container-mw" : "p-0",
            },
            [
              _c(
                "div",
                { staticClass: "ci-content__body__inner" },
                [
                  _c("portal-target", {
                    ref: "ads-container-top",
                    staticClass: "below-header",
                    class: _vm.windowIsScrolled ? "scroll-top" : "",
                    attrs: { name: "ads-container-top" },
                  }),
                  _vm._t("default"),
                ],
                2
              ),
              _vm.showAdsContainerRight
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-shrink-0 below-header ci-content-padding-l",
                    },
                    [
                      _c("portal-target", {
                        ref: "ads-container-right",
                        attrs: { name: "ads-container-right" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("ci-footer", { ref: "page-footer" }),
      _vm.confirmationReminder
        ? _c("ci-confirmation-reminder-modal")
        : _vm._e(),
      _c("ci-notice-bar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }