/* eslint-disable max-lines */
import { i18nInstance } from "@composables/i18n";
import { utilityService } from "@services/utility.service";
import {
  BASE_CHART_OPTIONS,
  BASE_OPTIONS,
  CHART_COLORS,
  CHART_PERIODS,
  CUSTOM_LEGEND,
  CUSTOM_NAVIGATOR,
  CUSTOM_RANGE_SELECTOR,
  CUSTOM_TOOLTIPS,
} from "./charts.helper";
import Highcharts from "highcharts";

class Service implements Charts.Service {
  public get colors(): Indexable {
    return CHART_COLORS;
  }

  public performanceChart: Charts.PerformanceChartFn = (series, options = {}): Highcharts.Options => ({
    ...BASE_OPTIONS,
    chart: {
      ...BASE_CHART_OPTIONS,
      className: "performance-chart",
      height: options.chartHeight || null,
    },
    title: {
      text: options.title || "",
    },
    tooltip: CUSTOM_TOOLTIPS,
    legend: CUSTOM_LEGEND(options.legend?.enableRemoving, 90),
    yAxis: {
      labels: {
        formatter: function () {
          return `${this.axis.defaultLabelFormatter.call(this)}%`;
        },
        style: {
          fontSize: "14px",
          color: CHART_COLORS.muted,
        },
      },
      plotLines: [
        {
          value: 0,
          width: 2,
          color: "#c4c4c4",
        },
      ],
    },
    xAxis: {
      gridLineWidth: 0,
      maxPadding: 0.2,
      minPadding: 0.2,
      labels: {
        style: {
          fontSize: "14px",
          color: CHART_COLORS.muted,
        },
      },
    },
    navigator: CUSTOM_NAVIGATOR,
    rangeSelector: {
      ...CUSTOM_RANGE_SELECTOR(),
      selected: options.selectedPeriod === 0 || options.selectedPeriod ? options.selectedPeriod : 3,
    },
    plotOptions: {
      series: {
        compare: "percent",
        showInNavigator: true,
        animation: {
          duration: options.animation ? 1000 : 0,
        },
        events: options.legend?.enableRemoving
          ? {
              legendItemClick: (e) => {
                e.preventDefault();
                options.legend.onclick(e);
              },
            }
          : {},
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series,
  });

  public portfolioPerformanceChart: Charts.PerformanceChartFn = (series, options = {}): Highcharts.Options => {
    const basePerformanceChart = this.performanceChart(series, options);

    return {
      ...basePerformanceChart,
      rangeSelector: { enabled: false },
      navigator: { enabled: false },
      xAxis: {
        ...basePerformanceChart.xAxis,
        showFirstLabel: false,
        showLastLabel: false,
      },
    };
  };

  public comparisonChart: Charts.ComparisonChartFn = (series, options = {}): Highcharts.Options => ({
    ...this.performanceChart(series, options),
  });

  public comparisonTileChart: Charts.ComparisonTileChartFn = (series, options = {}): Highcharts.Options => ({
    ...BASE_OPTIONS,
    credits: { enabled: false },
    legend: { enabled: false },
    tooltip: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: { visible: false },
    yAxis: {
      plotLines: [{ value: 0, width: 2, color: "silver" }],
      min: options.minMax * -1,
      max: options.minMax,
      startOnTick: true,
      endOnTick: true,
      showEmpty: true,
      tickPosition: "inside",
      tickAmount: 5,
      labels: {
        formatter: function () {
          return `${this.axis.defaultLabelFormatter.call(this)}%`;
        },
      },
    },
    plotOptions: {
      series: {
        compare: "percent",
        compareStart: true,
        compareBase: 0,
      },
    },
    chart: {
      ...BASE_CHART_OPTIONS,
      zooming: { mouseWheel: false },
      height: options.height || "200px",
      margin: 0,
      className: "performance-chart",
    },
    series,
  });

  public portfolioTileChart: Charts.PortfolioTileChartFn = (series, options = {}): Highcharts.Options => ({
    ...BASE_OPTIONS,
    credits: { enabled: false },
    legend: { enabled: false },
    tooltip: {
      ...CUSTOM_TOOLTIPS,
      formatter: function () {
        const { points } = this;
        const tooltips = points!.map(
          (v) => `<strong>${utilityService.formatPercentage((v.point as any).change as number, " ")}</strong>`
        );
        tooltips.unshift(false as any);
        return tooltips;
      },
    },
    rangeSelector: {
      enabled: false,
      buttons: CHART_PERIODS(),
      selected: options.selectedPeriod === 0 || options.selectedPeriod ? options.selectedPeriod : 3,
    },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    xAxis: { visible: false },
    yAxis: {
      plotLines: [{ value: 0, width: 2, color: "silver" }],
      startOnTick: false,
      endOnTick: false,
      labels: {
        formatter: function () {
          return `${this.axis.defaultLabelFormatter.call(this)}%`;
        },
      },
    },
    plotOptions: {
      series: {
        compare: "percent",
        compareBase: 0,
      },
    },
    chart: {
      ...BASE_CHART_OPTIONS,
      zooming: { mouseWheel: false },
      height: options.height || "200px",
      margin: 0,
      className: "performance-chart",
    },
    series: series.map(
      (series) =>
        ({
          ...series,
          type: "area",
          fillColor: {
            linearGradient: [0, 0, 0, 150] as any,
            stops: [
              [0, `${CHART_COLORS.primaryGradient}aa`],
              [1, `${CHART_COLORS.primaryGradient}1A`],
            ],
          },
        } as any)
    ),
  });

  public riskReturnChart: Charts.RiskReturnChartFn = (series, options = {}): Highcharts.Options => ({
    ...BASE_OPTIONS,
    chart: {
      ...BASE_CHART_OPTIONS,
      type: "scatter",
      panning: {
        enabled: true,
        type: "xy",
      },
      zooming: {
        pinchType: "xy",
      },
    },
    title: {
      text: options.title || "",
    },
    legend: CUSTOM_LEGEND(options.legend?.enableRemoving, 85),
    xAxis: {
      gridLineWidth: 1,
      title: {
        text: i18nInstance.t("products.risk_return_chart.xaxis_label", {
          period: i18nInstance.t(`products.risk_return_chart.periods.${options.period}`),
        }) as string,
        align: "low",
        style: {
          "font-weight": 400,
          "fontSize": "14px",
        },
      },
      labels: {
        formatter: function () {
          return `${this.axis.defaultLabelFormatter.call(this)}%`;
        },
        style: {
          fontSize: "14px",
          color: CHART_COLORS.muted,
        },
        x: 0,
      },
      plotLines: [
        {
          color: CHART_COLORS.muted,
          dashStyle: "LongDash",
          width: 1,
          value: options.averagePerformance?.volatility || 0,
        },
      ],
    },
    yAxis: {
      gridLineWidth: 1,
      opposite: true,
      title: {
        align: "high",
        text: i18nInstance.t("products.risk_return_chart.yaxis_label", {
          period: i18nInstance.t(`products.risk_return_chart.periods.${options.period}`),
        }) as string,
        rotation: 0,
        offset: -70,
        style: {
          "font-weight": 400,
          "fontSize": "14px",
        },
        textAlign: "left",
      },
      labels: {
        formatter: function () {
          return `${this.axis.defaultLabelFormatter.call(this)}%`;
        },
        style: {
          fontSize: "14px",
          color: CHART_COLORS.muted,
        },
        x: 5,
        y: 5,
      },
      plotLines: [
        {
          color: CHART_COLORS.muted,
          dashStyle: "LongDash",
          width: 1,
          value: options.averagePerformance?.performance || 0,
        },
      ],
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        turboThreshold: 0,
        events: options.legend?.enableRemoving
          ? {
              legendItemClick: (e) => {
                e.preventDefault();
                options.legend.onclick(e);
              },
            }
          : {
              legendItemClick: (e) => {
                e.preventDefault();
              },
            },
        animation: {
          duration: options.animation ? 1000 : 0,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
        point: {
          events: {
            click: function () {
              if (!this.series.userOptions.custom?.blockClickRedirect) {
                let route;

                if (this.series.userOptions.custom?.type === "Portfolio")
                  route = window.$vnode.$router.resolve({
                    name: window.$vnode.$routes.PORTFOLIOS_SHOW,
                    params: { id: (this as any).slug },
                  });
                else if (this.series.userOptions.custom?.type === "Peergroup")
                  route = window.$vnode.$router.resolve({
                    name: window.$vnode.$routes.PRODUCTS_RANKING_INDEX,
                    params: { peergroup: (this as any).peergroupName },
                  });
                else
                  route = window.$vnode.$router.resolve({
                    name: window.$vnode.$routes.PRODUCTS_SHOW,
                    params: { id: (this as any).slug },
                  });

                window.open(route.href)!.focus();
              }
            },
          },
        },
      },
      scatter: {
        stickyTracking: false,
        marker: {
          symbol: "circle",
          radius: 10,
          lineWidth: 1,
          states: {
            hover: {
              lineColor: `${CHART_COLORS.primary}80`,
              lineWidthPlus: 10,
            },
          },
        },
      },
    },
    tooltip: {
      ...CUSTOM_TOOLTIPS,
      headerFormat: "<div>",
      footerFormat: "</div>",
      followPointer: true,
      padding: 16,
      formatter: function () {
        return `<h6 class='mb-1'>${this.point.name}</h6>
                  <div class='py-1'>Performance: ${utilityService.formatPercentage(this.point.y as number, " ")}</div>
                  <div class='py-1'>Volatility: ${utilityService.formatPercentage(this.point.x as number, " ")}</div>`;
      },
    },
    series,
  });

  public backgroundChart: Charts.BackgroundChartFn = (
    seriesData,
    gradient: boolean = false,
    negative: boolean = false
  ): Highcharts.Options => ({
    ...BASE_OPTIONS,
    chart: {
      ...BASE_CHART_OPTIONS,
      zooming: { mouseWheel: false },
      alignTicks: false,
      margin: 0,
    },
    accessibility: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      area: {
        animation: false,
        enableMouseTracking: false,
        color: CHART_COLORS.primary,
        lineWidth: 1,
      },
    },
    series: [
      {
        type: "area",
        ...(gradient ? {} : { threshold: undefined }),
        fillColor: {
          linearGradient: [0, 0, 0, 100] as any,
          stops: [
            [0, negative ? `${CHART_COLORS.dangerGradient}66` : `${CHART_COLORS.primaryGradient}66`],
            [1, negative ? `${CHART_COLORS.dangerGradient}00` : `${CHART_COLORS.primaryGradient}00`],
          ],
        },
        color: negative ? CHART_COLORS.dangerGradient : CHART_COLORS.primaryGradient,
        opacity: 1,
        data: seriesData,
      },
    ],
  });

  public notificationChart: Charts.NotificationChartFn = (
    seriesData,
    options = {},
    negative = false
  ): Highcharts.Options => ({
    ...BASE_OPTIONS,
    chart: {
      ...BASE_CHART_OPTIONS,
      margin: [0, -6, 0, -6],
    },
    series: [
      {
        type: "area",
        data: seriesData,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        opacity: negative ? 0.7 : 1,
      },
    ],
    legend: { enabled: false },
    plotOptions: {
      area: {
        animation: false,
        enableMouseTracking: false,
        color: negative ? CHART_COLORS.danger : CHART_COLORS.primary,
        opacity: negative ? 0.7 : 1,
        lineWidth: 1,
        fillColor: {
          linearGradient: [0, 0, 0, 100] as any,
          stops: [
            [0, negative ? `${CHART_COLORS.dangerGradient}aa` : `${CHART_COLORS.primaryGradient}aa`],
            [1, negative ? `${CHART_COLORS.dangerGradient}12` : `${CHART_COLORS.primaryGradient}1a`],
          ],
        },
        threshold: null,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxHeight: 106,
            minHeight: 56,
          },
        },
      ],
    },
    title: { text: "" },
    tooltip: { enabled: false },
    xAxis: { visible: false },
    yAxis: { visible: false },
    ...options,
  });

  public assetflowChart: Charts.AssetflowChartFn = (
    series,
    categories,
    currency,
    options = {}
  ): Highcharts.Options => ({
    chart: {
      type: "column",
      zooming: {
        type: "xy",
      },
      marginTop: 50,
    },

    title: {
      text: "",
    },
    legend: {
      align: "left",
      itemStyle: {
        fontSize: "12px",
        fontWeight: "normal",
        textOverflow: "ellipsis",
      },
      symbolRadius: 0,
    },
    plotOptions: {
      column: {
        turboThreshold: 3000,
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
        animation: {
          duration: options.animation ? 1000 : 0,
        },
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 1,
      opposite: true,
      maxPadding: 0.2,
      minPadding: 0.2,
      title: {
        align: "high",
        text: i18nInstance
          .t("products.assetflow_chart.yaxis_label", {
            currency: currency,
          })
          .toString(),
        rotation: 0,
        offset: -110,
        style: {
          "font-weight": 400,
        },
        textAlign: "left",
        y: -20,
        x: 0,
      },
    },
    xAxis: {
      gridLineWidth: 1,
      maxPadding: 0.2,
      minPadding: 0.2,
      categories: categories,
    },
    tooltip: {
      useHTML: true,
      borderColor: `${CHART_COLORS.primary}80`,
      backgroundColor: "#FFF",
      padding: 16,
      formatter: function () {
        return `<strong class='mb-1'>${this.point.series.name}</strong>
                  <span class='py-1'> ${utilityService.formatNumber(this.point.y!, 2)} ${
          this.series.userOptions.custom?.currency
        }</span>`;
      },
    },
    series: series,
  });

  public assetflowSharpeRatioChart: Charts.AssetflowSharpeRatioChartFn = (
    series,
    currency,
    options = {}
  ): Highcharts.Options => ({
    ...BASE_OPTIONS,
    chart: {
      ...BASE_CHART_OPTIONS,
      type: "scatter",
      zooming: {
        type: "xy",
      },
      marginTop: 50,
      height: "40%",
    },

    title: {
      text: "",
    },
    legend: {
      align: "left",
      itemStyle: {
        fontSize: "12px",
        fontWeight: "normal",
        textOverflow: "ellipsis",
      },
    },
    xAxis: {
      gridLineWidth: 1,
      maxPadding: 0.2,
      minPadding: 0.2,
      title: {
        text: i18nInstance.t("products.assetflow_sharp_ratio_chart.xaxis_label").toString(),
        align: "low",
        style: {
          "font-weight": 400,
        },
      },
      plotLines: [
        {
          color: CHART_COLORS.muted,
          dashStyle: "LongDash",
          width: 1,
          value: options.averages?.sharpe_ratio,
        },
      ],
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 1,
      opposite: true,
      maxPadding: 0.2,
      minPadding: 0.2,
      title: {
        align: "high",
        text: i18nInstance
          .t("products.assetflow_sharp_ratio_chart.yaxis_label", {
            currency: currency,
          })
          .toString(),
        rotation: 0,
        offset: -110,
        style: {
          "font-weight": 400,
        },
        textAlign: "left",
        y: -20,
        x: 0,
      },
      plotLines: [
        {
          color: CHART_COLORS.muted,
          dashStyle: "LongDash",
          width: 1,
          value: options.averages?.inflow_outflow,
        },
      ],
    },
    plotOptions: {
      series: {
        turboThreshold: 3000,
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          },
        },
        showInLegend: true,
        animation: {
          duration: options.animation ? 1000 : 0,
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      scatter: {
        stickyTracking: false,
        marker: {
          symbol: "circle",
          radius: 10,
          lineWidth: 1,
          states: {
            hover: {
              lineColor: `${CHART_COLORS.primary}80`,
              lineWidthPlus: 10,
            },
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      borderColor: `${CHART_COLORS.primary}80`,
      backgroundColor: "#FFF",
      headerFormat: "<div>",
      footerFormat: "</div>",
      followPointer: true,
      padding: 16,
      style: {
        color: "#333333",
        fontSize: "14px",
        whiteSpace: "nowrap",
      },
      formatter: (instance: any) => `<h6 class='mb-1'>${instance.chart.hoverPoint.product_name}</h6>
              <div class='py-1'><strong>Net Assetflow:</strong> ${utilityService.formatNumber(
                instance.chart.hoverPoint.y,
                0
              )} ${currency}</div>
              <div class='py-1'><strong>Sharpe Ratio:</strong> ${utilityService.formatNumber(
                instance.chart.hoverPoint.x,
                2
              )}</div>`,
    },
    series: series,
  });

  public donutChart: Charts.DonutChartFn = (series, options = {}): Highcharts.Options => {
    const emptyState = options.emptyState || false;
    const colors = (): string[] => {
      if (options.disabled) return CHART_COLORS.donut.disabled;
      if (emptyState) return CHART_COLORS.donut.empty;

      return CHART_COLORS.donut.enabled;
    };
    return {
      colors: colors(),
      chart: {
        type: "pie",
        className: "donut-chart",
        height: 240,
        animation: options.animation,
      },
      title: {
        text: "",
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          cursor: emptyState ? "pointer" : "pointer",
          borderWidth: 2,
          enableMouseTracking: !emptyState,
          dataLabels: {
            enabled: false,
          },
          size: 220,
          center: ["50%", 90],
        },
        series: {
          animation: {
            duration: options.animation ? 1000 : 0,
          },
          point: {
            events: options.pointEvents,
          },
        },
      },
      series,
    };
  };
}

export const chartService = new Service();
/* eslint-enable max-lines */
