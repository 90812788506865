import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/users";

export class UsersApiService extends BaseApiService implements UsersApi.Service {
  public all: UsersApi.AllFn = (query) => this.axios.get(`${API_ENDPOINT}${query ? "?query=" + query : ""}`);

  public get: UsersApi.GetFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}`);
  public update: UsersApi.UpdateFn = (id, data) => this.axios.patch(`${API_ENDPOINT}/${id}`, data);
  public delete: UsersApi.DeleteFn = (id) => this.axios.delete(`${API_ENDPOINT}/${id}`);
  public getUserPortfolios: UsersApi.GetUserPortfoliosFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}/portfolios`);
  public getUserProducts: UsersApi.GetUserProductsFn = (id) => this.axios.get(`${API_ENDPOINT}/${id}/products`);
  public getUserArticles: UsersApi.GetUserArticlesFn = (id, params = {}) =>
    this.axios.get(`${API_ENDPOINT}/${id}/contents`, { params });
  public getNewsletterContents: UsersApi.GetNewsletterContentsFn = (id) =>
    this.axios.get(`${API_ENDPOINT}/newsletter_archive/${id}`);
  public getWatchlist: UsersApi.GetWatchlistFn = (id, page, perPage) =>
    this.axios.get(`${API_ENDPOINT}/${id}/watchlist`, {
      params: { page, per_page: perPage },
    });
}
