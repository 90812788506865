import Share from "@components/base/share.vue";
import FoldableText from "@components/misc/foldable-text.vue";
import Gating from "@components/misc/gating.vue";
import ProductTile from "@components/products/tile.vue";
import ImgixLazy from "@components/base/imgix-lazy.vue";
import ShareContent from "@components/messaging/share-content.vue";
import VueSelect from "@components/plugins/vue-select.vue";
import Loading from "@components/base/loading.vue";
import LoginForm from "@components/base/login-form.vue";
import FEWrapper from "@components/base/forms/wrapper.vue";
import CIRouterView from "@router/router-view.vue";
import Input from "@components/base/forms/input.vue";
import InputNumber from "@components/base/forms/input-number.vue";
import CIButton from "@components/base/button.vue";
import CiTag from "@components/tags/tag.vue";
import DropdownMenu from "@components/base/dropdown-menu.vue";
import DropdownItem from "@components/base/dropdown-item.vue";
import { LoadingCard } from "@components/misc/loading";
import PortfolioTile from "@components/portfolios/tile.vue";
import CiRouterLink from "@router/router-link.vue";
import CiUserAvatar from "@components/base/avatar.vue";
import CiSortableTableHeader from "@components/base/sortable-table-header.vue";
import CiFooter from "@components/footer/footer.vue";
import CiHeader from "@components/header/header.vue";
import CiLayout from "@components/layout.vue";
import CiSuggestedExternalContent from "@components/ads/suggested-external-content.vue";
import CiBookmark from "@components/base/bookmark.vue";
import CiToaster from "@components/base/toaster.vue";
import CiContentTile from "@components/contents/ContentTile.vue";

/* eslint-disable prettier/prettier, max-len */

export const LegacyComponentsDictionary: ComponentsDictionary = {
  sync: {},
  async: {
    // Should be refactored into new context and SFC
    "cap-users-list": () => import("@modules/companies/users-list/users-list.component"),
    "cap-user-search-modal": () => import("@modules/user/search/modal.component"),

    // Chunk groups
    "cap-news-feed": () =>
      import(/* webpackChunkName: "legacy_start" */ "@modules/articles/feed/article-feed.component"), // Should be part of page component after start page refactoring
    "cap-companies-teaser-list": () =>
      import(/* webpackChunkName: "legacy_start" */ "@modules/companies/teaser-list/companies-teaser-list.component"), // Should be refactored and part of the new start page component when it exists
    "ci-peergroup-list": () => import(/* webpackChunkName: "legacy_start" */ "@components/peergroups/list.vue"), // Should be part of page component after start page refactoring
    "cap-form-checkbox": () =>
      import(/* webpackChunkName: "legacy_forms" */ "@modules/base/forms/form-radio-check.component"), // Replace by ci-checkbox
  },
};

export const ComponentsDictionary: ComponentsDictionary = {
  sync: {
    "v-select": VueSelect,
    "ci-imgix-lazy": ImgixLazy,
    "ci-share": Share,
    "ci-foldable-text": FoldableText,
    "ci-gating": Gating,
    "ci-product-tile": ProductTile,
    "ci-portfolio-tile": PortfolioTile,
    "ci-share-content-via-messaging": ShareContent,
    "ci-loading": Loading,
    "ci-login-form": LoginForm,
    "ci-fe-wrapper": FEWrapper,
    "ci-router-view": CIRouterView,
    "ci-button": CIButton,
    "ci-router-link": CiRouterLink,
    "ci-input": Input,
    "ci-tag": CiTag,
    "ci-dropdown-menu": DropdownMenu,
    "ci-dropdown-item": DropdownItem,
    "ci-input-number": InputNumber,
    "ci-user-avatar": CiUserAvatar,
    "ci-sortable-table-header": CiSortableTableHeader,
    "ci-footer": CiFooter,
    "ci-suggested-external-content": CiSuggestedExternalContent,
    "ci-header": CiHeader,
    "ci-layout": CiLayout,
    "ci-bookmark": CiBookmark,
    "ci-toaster": CiToaster,
    "ci-content-tile": CiContentTile,
  },
  async: {
    "ci-logged-in-banner": () => import("@components/users/logged-in-banner.vue"),
    "ci-input-date": () => import("@components/base/forms/date.vue"),
    "ci-article-series-slider": () => import("@components/articles/series-slider.vue"),
    "ci-usp-component": () => import("@components/base/capinside-usp.vue"),
    "ci-portfolio-tile-shared": () => import("@components/portfolios/tile.shared.vue"),
    "ci-create-portfolio-modal": () => import("@components/portfolios/modals/create-portfolio-modal.vue"),
    "ci-products-start-comparison-modal": () =>
      import("@components/products/comparisons/modals/start-comparison-modal.vue"),
    "ci-registration-form": () =>
      import(/* webpackChunkName: "registration" */ "@components/base/registration-form.vue"),
    "ci-registration-modal": () =>
      import(/* webpackChunkName: "registration" */ "@components/base/registration-modal.vue"),
    "ci-login-modal": () => import(/* webpackChunkName: "registration" */ "@components/base/login-modal.vue"),
    "ci-button-to": () => import("@components/base/button-to.vue"), // Only used in omniauth button -> local?
    "ci-simple-tabs": () => import("@components/base/simple-tabs.vue"),
    "ci-sales-banner": () => import("@components/products/ranking/sales-banner.vue"),
    "ci-products-comparisons-tile": () => import("@components/products/comparisons/tile.vue"),
    "ci-user-tile": () => import("@components/users/tile.vue"),
    "ci-company-tile": () => import("@components/companies/CompanyTile.vue"),
    "ci-subscription": () => import("@components/subscription/subscription.vue"),
    "ci-pagination": () => import("@components/base/pagination.vue"),
    "ci-media-consent": () => import("@components/consent/media-consent.vue"),

    "ci-interest-tags": () => import(/* webpackChunkName: "articles" */ "@components/tags/interest-tags.vue"),
    "ci-related-content-list": () => ({
      component: import(/* webpackChunkName: "articles" */ "@components/articles/related-content-list.vue"),
      loading: LoadingCard,
    }),
    "ci-related-newsletter-content-list": () => ({
      component: import(/* webpackChunkName: "articles" */ "@components/contents/RelatedNewsletterContentList.vue"),
      loading: LoadingCard,
    }),
    "ci-social-event-tile": () => import(/* webpackChunkName: "events" */ "@components/events/tile.vue"),
    "ci-step-progressbar": () => import(/* webpackChunkName: "onboarding" */ "@components/base/step-progressbar.vue"), // Only used in onboarding index -> register locally when its a page component?

    "ci-textarea": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/textarea.vue"),
    "ci-select": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/select.vue"),
    "ci-checkbox": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/checkbox.vue"),
    "ci-radio": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/radio.vue"),
    "ci-checkbutton": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/check-button.vue"),
    "ci-radiobutton": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/radio-button.vue"),
    "ci-pictobutton": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/picto-button.vue"),
    "ci-password-input": () => import(/* webpackChunkName: "forms" */ "@components/base/forms/password_input.vue"),

    "ci-rating-icons": () => import(/* webpackChunkName: "icons" */ "@components/base/rating-icons.vue"),
    "ci-rating-sri": () => import(/* webpackChunkName: "icons" */ "@components/base/rating-sri.vue"),
    "ci-boolean-icon": () => import(/* webpackChunkName: "icons" */ "@components/base/boolean-icon.vue"),

    "ci-emptystate": () => import("@components/base/emptystate.vue"),
    "ci-errorpage": () => import("@router/errorpage.vue"),

    "ci-highcharts": () => import(/* webpackChunkName: "charts" */ "@components/charts/highcharts.vue"),
    "ci-risk-return-chart-products": () =>
      import(/* webpackChunkName: "charts" */ "@components/charts/risk-return-products.vue"),
    "ci-risk-return-chart-peergroups": () =>
      import(/* webpackChunkName: "charts" */ "@components/charts/risk-return-peergroups.vue"),
    "ci-performance-chart-portfolios": () =>
      import(/* webpackChunkName: "charts" */ "@components/charts/performance-portfolios.vue"),
    "ci-performance-chart-comparisons": () =>
      import(/* webpackChunkName: "charts" */ "@components/charts/performance-comparisons.vue"),
    "ci-performance-chart-products": () =>
      import(/* webpackChunkName: "charts" */ "@components/charts/performance-products.vue"),
    "ci-performance-risk-return-card": () => ({
      component: import(/* webpackChunkName: "charts" */ "@components/charts/performance-risk-return-card.vue"),
      loading: LoadingCard,
    }),
    "ci-share-classes-table": () =>
      import(/* webpackChunkName: "products" */ "@components/products/show/share-classes-table.vue"),
    "ci-image-upload": () => import(/* webpackChunkName: "charts" */ "@components/base/forms/image-upload.vue"),
    "ci-widget-fund-search": () => import(/* webpackChunkName: "widgets" */ "@components/widgets/fund-search.vue"),
    "ci-widget-focus-asset-select": () =>
      import(/* webpackChunkName: "widgets" */ "@components/widgets/focus-asset-select.vue"),
    "ci-content-guest-header": () =>
      import(/* webpackChunkName: "widgets" */ "@components/articles/content-guest-header.vue"),
    "ci-popular-content-hubs": () =>
      import(/* webpackChunkName: "widgets" */ "@components/content_hubs/popular-content-hubs.vue"),
  },
};

/* eslint-enable prettier/prettier, max-len */
