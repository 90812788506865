import { Userpilot } from "userpilot";
import { settingsService } from "@services/settings.service";

class UserpilotClass {
  static instance: UserpilotClass;
  currentUser: any;
  userpilot: any;

  private readonly userpilotKlaroIdentifier = "userpilot";

  constructor() {
    if (!UserpilotClass.instance) {
      UserpilotClass.instance = this;
    }
    return UserpilotClass.instance;
  }

  getCurrentUser(): UserpilotClass["currentUser"] {
    return UserpilotClass.instance.currentUser;
  }

  initialize(): Promise<UserpilotClass> {
    return new Promise((resolve) => {
      const klaroManager = window.klaro?.getManager();
      const applyUserpilotConsent = (
        consent: boolean,
        settings: Settings.Options,
        settingsService: Settings.Service
      ): void => {
        if (settings?.user?.id || consent) {
          if (settings?.flippers?.userpilot && settings?.userpilot_token) {
            const user = { ...settingsService.options.user };
            UserpilotClass.instance.userpilot = Userpilot;
            UserpilotClass.instance.userpilot.initialize(settings?.userpilot_token);
            UserpilotClass.instance.identifyUser(user);
          }
        } else {
          if (window.userpilot === undefined) {
            return;
          }

          window.userpilot.destroy();
          window.userpilot = undefined;
        }
      };

      applyUserpilotConsent(klaroManager?.getConsent(this.userpilotKlaroIdentifier), window.$settings, settingsService);

      if (klaroManager) {
        klaroManager.watch({
          update: (klaroManager, name) => {
            if (name !== "applyConsents") {
              return;
            }

            applyUserpilotConsent(
              klaroManager?.getConsent(this.userpilotKlaroIdentifier),
              window.$settings,
              settingsService
            );
          },
        });
      }

      resolve(UserpilotClass.instance);
    });
  }

  getInstance(): any {
    return UserpilotClass.instance;
  }

  trackEvent(eventName: string): void {
    if (UserpilotClass.instance.userpilot) {
      UserpilotClass.instance.userpilot.track(eventName, {
        id: UserpilotClass.instance.currentUser?.secret_identifier,
      });
    }
  }

  identifyUser(user: SessionApi.SessionUser | Settings.User): void {
    if (UserpilotClass.instance.userpilot) {
      if (user.secret_identifier) {
        let params: Settings.UserpilotData = {
          professional: user.professional,
          provider: user.provider,
          avatar: user.avatar && typeof user.avatar == "object" ? user.avatar.url : user.avatar,
          has_avatar: !!user.avatar,
        };
        if ("sign_in_count" in user) {
          const settingsUser = user as Settings.User;
          params = {
            ...params,
            sign_in_count: settingsUser.sign_in_count,
            advisor: settingsUser.advisor,
            considers_himself_professional: settingsUser.considers_himself_professional,
            has_advisory_services: settingsUser.has_advisory_services,
            has_advisor_profile_description: settingsUser.has_advisor_profile_description,
            show_interest_tag_selection: settingsUser.show_interest_tag_selection,
          };
        }
        UserpilotClass.instance.userpilot.identify(user.secret_identifier, params);
        UserpilotClass.instance.currentUser = { ...params, secret_identifier: user.secret_identifier };
      } else {
        UserpilotClass.instance.userpilot.anonymous();
      }
    }
  }

  resetUser(user: SessionApi.SessionUser | Settings.User): void {
    if (UserpilotClass.instance.userpilot && user.secret_identifier) {
      UserpilotClass.instance.userpilot.identify(user.secret_identifier, this.emptyUserpilotData());
    }
  }

  // Userpilot does NOT reset any data so we have to do it ourselves
  private emptyUserpilotData(): Settings.User {
    return {
      id: null,
      type: "guest" as Settings.UserType,
      admin: false,
      secret_identifier: null,
      sso: false,
      partner_admin: false,
      firstname: null,
      admin_translator: false,
      slug: null,
      // region: null, leave this out ro avoid probable side effects
      confirmation_remaining_time_days: null,
      advisor: false,
      provider: null,
      professional: false,
      considers_himself_professional: false,
      show_interest_tag_selection: false,
      sign_in_count: null,
      avatar: null,
      has_avatar: false,
      has_advisory_services: false,
      has_advisor_profile_description: false,
    };
  }
}

const userpilot = new UserpilotClass();
export default userpilot.getInstance();
