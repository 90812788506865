var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("figure", { staticClass: "lazy-imgix" }, [
    _c(
      "picture",
      [
        _vm._l(_vm.sources, function (source) {
          return _c(
            "source",
            _vm._b({ attrs: { alt: _vm.alt } }, "source", source, false)
          )
        }),
        _c("img", { attrs: { loading: "lazy", alt: _vm.alt } }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }