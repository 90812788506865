import { BaseApiService } from "./base";

const API_ENDPOINT_INDEX = "/api/v4/notifications";
const API_ENDPOINT_SET_READ = "/api/v4/notifications/set_read";
const API_ENDPOINT_SET_SEEN = "/api/v4/notifications/set_seen";
const API_ENDPOINT_UNSEEN_COUNT = "/api/v4/notifications/unseen_count";

export class NotificationsApiService extends BaseApiService implements NotificationsApi.Service {
  public index: NotificationsApi.GetNotifications = (params = {}) => this.axios.get(API_ENDPOINT_INDEX, { params });
  public setSeen: NotificationsApi.SetStatus = (ids, context, category) =>
    this.axios.patch(API_ENDPOINT_SET_SEEN, {
      ...(ids?.length ? { ids } : {}),
      context,
      ...(category ? { category } : {}),
    });
  public setRead: NotificationsApi.SetStatus = (ids, context, category) =>
    this.axios.patch(API_ENDPOINT_SET_READ, {
      ...(ids?.length ? { ids } : {}),
      context,
      ...(category ? { category } : {}),
    });
  public getUnseenCount: NotificationsApi.GetUnseenCount = () => this.axios.get(API_ENDPOINT_UNSEEN_COUNT);
}
