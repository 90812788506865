import { buildURL } from "@utils/build-url";
import { BaseApiService } from "./base";
export class ConversationsApiService extends BaseApiService implements ConversationsApi.Service {
  public conversationMessages: ConversationsApi.ConversationMessagesFn = (conversationId, page, markAsRead = true) =>
    this.axios.get(`/api/v4/conversations/${conversationId}/messages`, {
      params: { page, mark_as_read: markAsRead },
    });

  public conversation: ConversationsApi.ConversationFn = (id) =>
    this.axios.get("/api/v4/conversations/with_user", { params: { user_id: id } });

  public conversations: ConversationsApi.ConversationsFn = (page) => {
    let url = "/api/v4/conversations";
    if (page) {
      url = buildURL(url, { searchParams: { page: String(page) } });
    }
    return this.axios.get(url);
  };

  public createMessage: ConversationsApi.CreateMessageFn = (conversationId, message) =>
    this.axios.post(`/api/v4/conversations/${conversationId}/messages`, {
      message,
    });

  public createConversation: ConversationsApi.CreateConversationFn = (id, message) =>
    this.axios.post("/api/v4/conversations", {
      conversation: {
        user_ids: [id],
        messages_attributes: [{ text: message }],
      },
    });
  public destroyConversation: ConversationsApi.DestroyConversationFn = (id) =>
    this.axios.delete(`/api/v4/conversations/${id}`);

  public limit: ConversationsApi.LimitFn = () => this.axios.get("/api/v4/conversations/limit");

  public status: ConversationsApi.StatusFn = () => this.axios.get("/api/v4/conversations/status");
}
