import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export class FormElementBase extends Vue {
  @Prop({ default: "name" }) public name!: string;
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: false }) public border!: boolean;
  @Prop({ default: "md" }) public size!: string;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: "" }) public value!: string | boolean | number | [];
  @Prop({ default: "name" }) public inputId!: string;
  @Prop({ default: "" }) public leadingIcon!: string;
  @Prop({ default: "" }) public trailingIcon!: string;
  @Prop({ default: false }) public large!: boolean;
  @Prop({ default: "" }) public hint!: string;
  @Prop({ default: false }) public autofocus!: boolean;
  @Prop({ default: true }) public autocomplete!: boolean;
  @Prop({ default: false }) public error!: boolean;
  @Prop({ default: false }) public vCenter!: boolean;
  @Prop({ default: () => null }) public trailingIconFn!: Nullable<() => void>;
  @Prop({ default: () => null }) public leadingIconFn!: Nullable<() => void>;
  @Prop({ default: "" }) public humanName: string | undefined;
  @Prop({ default: () => ({}) }) public rules!: Indexable;
  @Prop({ default: "" }) public vid!: string;
  @Prop({ default: null }) public validationMessages!: Nullable<Indexable>;
  @Prop({ default: true }) public displayOptionalLabel!: boolean;
  @Prop({ default: "eager" }) public mode!: "passive" | "lazy" | "eager" | "aggressive";
  @Prop({ default: " " }) public placeholder!: string;

  public internalValue: any = "";
  public forcedPopulated: boolean = false;

  public get validationName(): string {
    return this.humanName || this.label || this.name;
  }

  public get visibleLabel(): string {
    if (Object.keys(this.rules).length > 0 && !this.rules.required && this.displayOptionalLabel) {
      return this.$t("ui.shared.global.optional_label", { label: this.label }) as string;
    }

    return this.label;
  }

  public get hintText(): string {
    return typeof this.hint === "string" ? this.hint : "";
  }

  public get populated(): boolean {
    if (this.forcedPopulated) return true;
    if (typeof this.internalValue === "number") return true;
    if (typeof this.internalValue === "string") return this.internalValue.length > 0;
    if (typeof this.internalValue === "boolean") return true;

    return this.internalValue !== undefined && this.internalValue !== null;
  }

  public get trailingIconClasses(): string {
    return `${this.trailingIcon} ${this.trailingIconFn ? "has-trailing-fn" : ""}`.trim();
  }

  public get trailingIconHandler(): any {
    // eslint-disable-next-line no-empty-function
    const fallback = (): void => {};
    return this.trailingIconFn || fallback;
  }

  public get leadingIconClasses(): string {
    return `${this.leadingIcon} ${this.leadingIconFn ? "has-leading-fn" : ""}`.trim();
  }

  public get leadingIconHandler(): any {
    // eslint-disable-next-line no-empty-function
    const fallback = (): void => {};
    return this.leadingIconFn || fallback;
  }

  public get baseClasses(): Indexable {
    return {
      "has-leading-icon": this.leadingIcon.length > 0,
      "has-trailing-icon": this.trailingIcon.length > 0,
      "populated": this.populated,
      "large": this.large,
      "disabled": this.disabled,
      "no-label": !this.label,
      "v-center": this.vCenter,
      "bordered": this.border,
      "size-sm": this.size === "sm",
      "size-md": this.size === "md",
      "size-lg": this.size === "lg",
      "invalid": this.error,
    };
  }

  @Watch("value")
  onValueChanged(val: ExplicitAny): void {
    this.internalValue = val;
  }

  public onChange(ev: ExplicitAny): void {
    this.internalValue = this.parseValue(ev.target.value);
    this.$emit("input", this.internalValue);
  }

  public handleInput(ev: ExplicitAny): void {
    this.$emit("input", ev.target.value);
    const el = ev.target;
    const { selectionStart } = el;

    /*
     * resets the cursor position to previous position
     * needed because v-model puts the cursor to end of input
     */

    this.$nextTick(() => {
      this.$nextTick(() => {
        el.setSelectionRange(selectionStart, selectionStart);
      });
    });
  }

  public handlePaste(ev: ClipboardEvent): void {
    ev.stopPropagation();
    ev.preventDefault();
    const text = ev.clipboardData?.getData("text/plain");
    if (text) this.$emit("input", this.value ? this.value : "" + text);
  }

  protected async mounted(): Promise<void> {
    this.internalValue = this.value;
    if (this.autofocus) {
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      const element = this.$el.querySelector("input, textarea");
      if (element) (element as HTMLInputElement).focus();
    }
  }

  private parseValue(value: string): any {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? value : parsed;
  }
}
