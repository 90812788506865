
import { computed, defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { AnalyticsStoreModule } from "@stores/analytics.store";
import { ViewportStoreModule } from "@stores/viewport.store";

const analyticsStore = getModule(AnalyticsStoreModule);
const viewportStore = getModule(ViewportStoreModule);

export default defineComponent({
  name: "CiPartnerButtons",
  props: {
    onboardingType: { type: String, default: "" },
    gatingBannerLocation: { type: String, default: "" },
    authenticityToken: { type: String, default: "" },
    registrationReferrer: { type: String, default: () => window.location.href },
  },

  setup(props) {
    const handheld = computed(() => viewportStore.handheld);

    const extraParams = computed(() => {
      const loginLocation = analyticsStore.loginLocation || props.onboardingType;
      return {
        login_location: loginLocation,
        gating_banner_location: props.gatingBannerLocation,
        onboarding_type: props.onboardingType,
        registration_referrer: props.registrationReferrer,
      };
    });

    return {
      handheld,
      extraParams,
    };
  },
});
