
import { ViewportSizes } from "@models/enums";
import { ImgixService } from "@services/imgix.service";
import { Vue, Component, Prop } from "vue-property-decorator";

type LazyLoadSizeArray = [number, number, number, number, number, number];
const LazyLoadSizeArrayDefault = [
  ViewportSizes.XS,
  ViewportSizes.SM,
  ViewportSizes.MD,
  ViewportSizes.LG,
  ViewportSizes.XL,
  ViewportSizes.XXL,
];

@Component({
  name: "ci-imgix-lazy",
})
export default class extends Vue {
  @Prop({ required: true }) public url!: string;
  @Prop({ default: () => ({}) }) public params!: Indexable;
  @Prop({ default: () => LazyLoadSizeArrayDefault }) public sizes!: LazyLoadSizeArray | number;
  @Prop({ default: () => "" }) public alt!: string;

  public get sources(): Indexable[] {
    if (Array.isArray(this.sizes)) {
      return this.sizes.map((size, index) => ({
        srcset: ImgixService.image(this.url, { width: size, ...this.params }),
        media: this.mediaFromIndex(index),
      }));
    } else {
      return [
        {
          srcset: ImgixService.image(this.url, { width: this.sizes, ...this.params }),
        },
      ];
    }
  }

  private mediaFromIndex(index: number) {
    if (index === 0) return `(max-width: ${ViewportSizes.SM}px)`;
    if (index === 1) return `(min-width: ${ViewportSizes.SM}px) and (max-width: ${ViewportSizes.MD}px)`;
    if (index === 2) return `(min-width: ${ViewportSizes.MD}px) and (max-width: ${ViewportSizes.LG}px)`;
    if (index === 3) return `(min-width: ${ViewportSizes.LG}px) and (max-width: ${ViewportSizes.XL}px)`;
    if (index === 4) return `(min-width: ${ViewportSizes.XL}px) and (max-width: ${ViewportSizes.XXL}px)`;
    else return `(min-width: ${ViewportSizes.XXL}px)`;
  }
}
