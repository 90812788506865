import { DirectiveOptions } from "vue";
import { gtmTrackingService } from "@services/gtm-tracking.service";
import { getModule } from "vuex-module-decorators";
import { AnalyticsStoreModule } from "@stores/analytics.store";

const analyticsStore = getModule(AnalyticsStoreModule);

const updateAnalyticsStore = (overrides: { eventCategory: string; eventLabel: string; eventAction: string }): void => {
  if (overrides.eventCategory === "Login") {
    const match = overrides.eventLabel.match(/login_location : ([^;]*)( ;|$)/);
    const loginLocation = match ? match[1] : "";
    analyticsStore.updateLoginLocation(loginLocation);
  }
};
const directive: DirectiveOptions = {
  bind: (element, binding): void => {
    const trackingPayload = binding.value;

    if (gtmTrackingService.isTrackingDataValid(trackingPayload))
      element.addEventListener("click", () => {
        updateAnalyticsStore(trackingPayload);
        gtmTrackingService.track(trackingPayload);
      });
    else console.error("ga click directive - malformed value");
  },
};

export default directive;
