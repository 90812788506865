
import { Component } from "vue-property-decorator";
import { FormElementBase } from "./base";
import { ValidationProvider } from "vee-validate";

@Component({
  name: "ci-fe-wrapper",
  components: { ValidationProvider },
})
export default class extends FormElementBase {
  public get populated(): boolean {
    if (this.forcedPopulated) return true;

    return this.internalValue;
  }
}
