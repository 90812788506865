
import { Vue, Component } from "vue-property-decorator";
import { debounce } from "throttle-debounce";
import { Constants } from "@app/constants";
import { OptionsMapper, GlobalSearchInstance, ResultMapper } from "./nav-search-helper";
import { getModule } from "vuex-module-decorators";
import { HeaderStoreModule } from "./header.store";
import { storageService } from "@services/storage.service";
import { ciTrackingService } from "@services/ci-tracking.service";
import { Emitter } from "@utils/events";
import { buildURL } from "@utils/build-url";
import VSelect from "@components/plugins/vue-select.vue";

const HeaderStore = getModule(HeaderStoreModule);

const MAX_SEARCH_HISTORY_SIZE = 5;

@Component({
  name: "ci-nav-search",
})
export default class extends Vue {
  public search: {
    value: string;
    last: string;
    options: AIS.ResultOption[];
    history: AIS.ResultOption[];
    backdrop: boolean;
  } = {
    value: "",
    last: "",
    options: [],
    history: [],
    backdrop: false,
  };

  public searching = false;

  public debouncedSearch = debounce(Constants.defaultDelay, this.internalSearch);

  public get queryLength() {
    return this.search.value?.length || 0;
  }

  public get showHistory(): boolean {
    return this.queryLength === 0 && this.search.history.length > 0;
  }

  public get showSearchHint() {
    return (this.queryLength > 0 || !this.search.history.length) && this.queryLength < 3;
  }

  public get queryTooLong() {
    return this.queryLength > Constants.algoliaMaxCharacters;
  }

  public get results(): AIS.ResultOption[] {
    if ((this.queryLength > 0 && this.queryLength < 3) || this.queryTooLong) {
      return [];
    } else if (this.queryLength === 0) {
      return this.search.history;
    }

    const searchUrl = buildURL("/search", { searchParams: { q: this.search.value } });
    const ShowAllItem = ResultMapper({
      type: "search",
      label: this.search.value,
      track: true,
      url: searchUrl,
      icon: "mdi-magnify",
    });

    return this.search.last
      ? this.searching
        ? [ShowAllItem]
        : [ShowAllItem, ...this.search.options]
      : this.search.history;
  }

  public get wrapperAttributes(): Indexable {
    return {
      "label": "",
      "value": this.search.value,
      "trailingIcon": this.search.value ? "mdi-close" : "",
      "trailingIconFn": this.clear,
      "size": "sm",
      "leading-icon": `${HeaderStore.searchExpanded ? "mdi-arrow-left" : "mdi-magnify"} mdi-24px`,
      ...(HeaderStore.searchExpanded ? { "leading-icon-fn": HeaderStore.collapseSearch } : {}),
    };
  }

  public async clear(): Promise<void> {
    await this.$nextTick();
    await this.$nextTick();
    this.focusSearchElement();
  }

  public handleSearch(query: string): void {
    this.search.value = query;
    if (this.queryLength < 3 || this.queryTooLong) return;

    this.searching = true;
    this.search.last = query;
    this.debouncedSearch(query);
  }

  public handleSearchBlur(): void {
    this.search.backdrop = false;
    Emitter.emit("ci::header-search::blur");
  }

  public handleSearchFocus(): void {
    this.search.backdrop = true;
    Emitter.emit("ci::header-search::focus");
  }

  public handleInput(option: AIS.ResultOption): void {
    if (!option?.url) return;

    this.appendToSearchHistory(option);
    if (option.track) this.trackQuery();

    if (this.$page.context === "spa") this.$router.push(option.url);
    else window.location.href = option.url;
  }

  public decorateLabel(option: AIS.ResultOption): string {
    if (
      this.results?.length &&
      option.label === this.results[0].label &&
      option.type === "search" &&
      !this.showHistory &&
      !this.showSearchHint
    ) {
      return this.$t("searches.show_all_results") as string;
    } else return option.label;
  }

  public deleteSearchHistory(): void {
    storageService.set("searchHistory", []);
    this.search.history = [];
  }

  protected mounted(): void {
    this.search.history = storageService.get("searchHistory") || [];
    Emitter.on("ci::focus::header-search", this.focusSearchElement);
  }

  private focusSearchElement(): void {
    (this.$refs.vs as VSelect)?.focusSearchElement();
  }

  private async internalSearch(query: string): Promise<void> {
    if (!query) return;

    try {
      this.searching = true;
      const results = await GlobalSearchInstance.search(query);
      this.search.options = OptionsMapper(results);
    } catch (err) {
      console.error(err);
    } finally {
      this.searching = false;
    }
  }

  private appendToSearchHistory(option: AIS.ResultOption): void {
    const currentHistory: AIS.ResultOption[] = storageService.get("searchHistory") || [];
    const optionWithoutTracking = Object.assign({}, option);
    optionWithoutTracking.track = false;

    if (currentHistory.find((o) => o.url === optionWithoutTracking.url)) return;

    currentHistory.unshift(optionWithoutTracking);

    if (currentHistory.length > MAX_SEARCH_HISTORY_SIZE) {
      currentHistory.pop();
    }

    storageService.set("searchHistory", currentHistory);
    this.search.history = currentHistory;
  }

  private trackQuery(): void {
    ciTrackingService.track({
      event: {
        action: "search",
        value: null,
      },
      target: {
        name: "Search",
        identifier: this.search.last,
      },
      source: {
        name: "MenubarSearch",
        identifier: window.location.href,
      },
    });
  }
}
