import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";

@Component({
  directives: {
    "dynamic-init": {
      bind(_el, binding, vnode) {
        if (vnode.context) (vnode.context as any).$root.setDynamic(binding.value);
      },
    },
  },
})
export default class extends Vue {
  public dynamic: any = {};

  public setDynamic(data: any): void {
    Object.entries(data).forEach(([key, value]) => {
      this.$set(this.dynamic, key, value);
    });
  }
}
