var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "login login-form",
      class: { "login-form--is-full": _vm.isFullScreen },
    },
    [
      _c(
        "div",
        {
          staticClass: "login-form__content",
          class: { "login-form__content--is-full": _vm.isFullScreen },
        },
        [
          _c("header", { staticClass: "login-form__header" }, [
            _vm.isFullScreen
              ? _c("h1", {
                  staticClass: "login-form__header-title",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.base.login_form.heading")
                    ),
                  },
                })
              : _vm._e(),
            _vm.isFullScreen
              ? _c("p", {
                  staticClass: "login-form__header-text",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.base.login_form.sub_heading")
                    ),
                  },
                })
              : _vm._e(),
            !_vm.isFullScreen
              ? _c("h1", {
                  staticClass: "text-center login-form__header-title-modal",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.components.base.login_form.login_with")
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _c(
            "section",
            {
              staticClass: "login-form__providers",
              class: { "login-form__providers--is-full": _vm.isFullScreen },
            },
            [
              _c("ci-omniauth-buttons", {
                attrs: {
                  providers: _vm.providers,
                  "onboarding-type": _vm.trackingOnboardingType,
                  "gating-banner-location": _vm.gatingBannerLocation,
                  "authenticity-token":
                    _vm.$settings.options.registration.omniauth
                      .form_authenticity_token,
                },
              }),
              _c("ci-button-to", {
                ref: "redirectButton",
                staticClass: "hidden",
                attrs: {
                  url: _vm.redirectUrl,
                  method: _vm.redirectMethod,
                  "authenticity-token":
                    _vm.$settings.options.registration.omniauth
                      .form_authenticity_token,
                  "extra-params": { user_return_to: _vm.currentLocation },
                },
              }),
            ],
            1
          ),
          _c(
            "form",
            {
              staticClass: "login-form__form",
              attrs: { id: "login-form" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("ci-input", {
                      attrs: {
                        type: "email",
                        "input-id": "user_email",
                        label: `${_vm.$t("ui.shared.global.email")}*`,
                        "human-name": _vm.$t("ui.shared.global.email"),
                      },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row mt-3" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("ci-password-input", {
                      attrs: {
                        "input-id": "user_password",
                        label: `${_vm.$t("ui.shared.global.password")}*`,
                        "human-name": _vm.$t("ui.shared.global.password"),
                      },
                      model: {
                        value: _vm.password,
                        callback: function ($$v) {
                          _vm.password = $$v
                        },
                        expression: "password",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "row my-2 justify-content-center" }, [
                _c("span", {
                  staticClass: "text-danger",
                  domProps: { textContent: _vm._s(_vm.errorText) },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass: "row justify-content-between align-items-center",
                },
                [
                  _c(
                    "div",
                    { class: _vm.isFullScreen ? "col-12" : "col-6" },
                    [
                      _c("ci-checkbox", {
                        attrs: {
                          name: "user_remember_me",
                          label: _vm.$t(
                            "ui.components.base.login_form.remember_me"
                          ),
                          "human-name": _vm.$t(
                            "ui.components.base.login_form.remember_me"
                          ),
                          "show-asteriks": false,
                          vCenter: true,
                        },
                        model: {
                          value: _vm.rememberMe,
                          callback: function ($$v) {
                            _vm.rememberMe = $$v
                          },
                          expression: "rememberMe",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: [
                        _vm.isFullScreen
                          ? "col-12"
                          : "col-6 d-flex justify-content-end",
                      ],
                    },
                    [
                      _c("ci-button", {
                        staticClass: "login-form__form-button",
                        class: {
                          "login-form__form-button--is-full": _vm.isFullScreen,
                        },
                        attrs: {
                          id: "login-submit",
                          variant: "filled-primary",
                          type: "submit",
                          disabled: !_vm.email || !_vm.password,
                          label: _vm.$t(
                            "ui.components.base.login_form.sign_in"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "login-form__form-helpers mt-3",
                  class: {
                    "login-form__form-helpers--is-full": _vm.isFullScreen,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "login-form__form-additional" },
                    [
                      _c("ci-router-link", {
                        staticClass: "login-form__form-helper-link",
                        attrs: {
                          id: "forgot-your-password-short",
                          to: _vm.newPasswordUrl,
                          label: _vm.$t(
                            "ui.components.base.login_form.forgot_password"
                          ),
                        },
                      }),
                      _c("ci-router-link", {
                        staticClass: "login-form__form-helper-link",
                        attrs: {
                          id: "no-confirmation-mail",
                          to: _vm.newConfirmationMailUrl,
                          label: _vm.isFullScreen
                            ? _vm.$t(
                                "ui.components.base.login_form.no_confirmation_mail_full"
                              )
                            : _vm.$t(
                                "ui.components.base.login_form.no_confirmation_mail"
                              ),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm.partnersEnabled
            ? _c(
                "section",
                { staticClass: "partner-logins pt-5" },
                [
                  _c("ci-partner-buttons", {
                    attrs: {
                      "onboarding-type": _vm.trackingOnboardingType,
                      "gating-banner-location": _vm.gatingBannerLocation,
                      "authenticity-token":
                        _vm.$settings.options.registration.omniauth
                          .form_authenticity_token,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.isFullScreen
        ? _c("hr", { staticClass: "login-form__bottom-divider" })
        : _vm._e(),
      _c(
        "footer",
        {
          staticClass: "login-form__footer",
          class: { "login-form__footer--is-full": _vm.isFullScreen },
        },
        [
          _c("h5", {
            staticClass: "login-form__footer-headline",
            domProps: { innerHTML: _vm._s(_vm.registrationHintHeadline) },
          }),
          _c("ci-button", {
            directives: [
              {
                name: "ga-track-click",
                rawName: "v-ga-track-click",
                value: {
                  eventCategory: "Registration",
                  eventLabel: "register_location : modal-login-page",
                  eventAction: "RegisterClick",
                },
                expression:
                  "{ eventCategory: 'Registration', eventLabel: 'register_location : modal-login-page', eventAction: 'RegisterClick' }",
              },
            ],
            staticClass: "login-form__footer-button",
            attrs: {
              id: "signup-button",
              variant: _vm.isFullScreen ? "outline-primary" : "outline-white",
              label: _vm.isFullScreen
                ? _vm.$t("ui.components.base.login_form.register_full")
                : _vm.$t("ui.components.base.login_form.register"),
              to: _vm.registrationUrl,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }