
import { Vue, Component, Prop } from "vue-property-decorator";
import { GenericUser, VerifiedBadge } from "@pictograms";
import { ImgixService } from "@services/imgix.service";
import InlineSvg from "vue-inline-svg";

// see $cap-circle-size-... in SCSS
const CircleSizeMapping: { [key: string]: number } = {
  sm: 2 * 16,
  md: 3 * 16,
  lg: 4 * 16,
  xl: 5 * 16,
  xxl: 7.5 * 16,
  xxxl: 9 * 16,
  xxxxl: 12.5 * 16,
};

@Component({
  name: "ci-user-avatar",
  components: {
    "inline-svg": InlineSvg,
  },
})
export default class extends Vue {
  @Prop({ default: "md" }) public size!: "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl" | "xxxxl";
  @Prop({ default: false }) public sidebar!: boolean;
  @Prop({ default: false }) public showVerifiedBadge!: boolean;
  @Prop({ default: "" }) public icon!: string;
  @Prop({ default: "Profile Avatar" }) public username!: string;

  @Prop({
    default: null,
    validator: (value: string) => {
      const valid = !value || !value.match(/^https?:/) || value.startsWith("http://localhost");
      return valid;
    },
  })
  public src!: Nullable<string>;
  private avatarImgParams = { fit: "crop", crop: "faces" };

  public get genericIcon(): ImportedSVG {
    return GenericUser;
  }

  public get avatarAltText(): string {
    return this.$t("ui.components.base.avatar.user_avatar", { name: this.username }) as string;
  }

  public get verifiedBadge(): ImportedSVG {
    return VerifiedBadge;
  }

  public get classes(): string {
    return `${this.sidebar ? "sidebar-avatar" : ""} ${this.size} ${
      this.hasAvatar ? "avatar-available" : "avatar-fallback"
    }`.trim();
  }

  public get hasAvatar(): boolean {
    if (this.src) return this.src.length > 0;
    else return false;
  }

  public get avatarUrl(): string {
    if (!this.src) return "";
    if (this.src.match(/^(http|https|data):/)) return this.src;
    return ImgixService.image(this.src, this.avatarImgParams);
  }

  public get avatarSrcset(): string {
    if (!this.src) return "";
    if (this.src.match(/^(http|https|data):/)) return "";
    return ImgixService.buildSrcSet(this.src, {
      ...this.avatarImgParams,
      w: CircleSizeMapping[this.size],
      h: CircleSizeMapping[this.size],
    });
  }
}
