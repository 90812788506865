import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/countries";

export class CountriesApiService extends BaseApiService implements CountriesApi.Service {
  public countries(): ApiRequest<Countries.CountriesApiResponse> {
    return this.axios.get(API_ENDPOINT);
  }

  public states(countryCode: string): ApiRequest<Countries.StatesApiResponse> {
    return this.axios.get(`${API_ENDPOINT}/${countryCode}/states`);
  }
}
