import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/users/settings";

export class SettingsApiService extends BaseApiService implements SettingsApi.Service {
  public getUserSettingsProfile: SettingsApi.GetUserSettingsProfileFn = () => this.axios.get(API_ENDPOINT);
  public updateSettings: SettingsApi.UpdateSettingsFn = (params) => this.axios.patch(API_ENDPOINT, { user: params });
  public updatePrivacies: SettingsApi.UpdatePrivaciesSettingsFn = (params) =>
    this.axios.patch(`${API_ENDPOINT}/privacies`, { access_setting: params });
}
