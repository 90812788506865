
import Vue, { CreateElement, VNode } from "vue";
import { Component, Prop } from "vue-property-decorator";
import VueSelect, { VueSelectInstance } from "vue-select";

(VueSelect.props.components as any).default = (): any => ({
  deselect: {
    render: (createElement: CreateElement): VNode => createElement("span", { class: `mdi mdi-close` }, ""),
  },
  openIndicator: {
    render: (createElement: CreateElement): VNode => createElement("span", { class: `mdi mdi-chevron-down` }, ""),
  },
});

@Component({
  mixins: [VueSelect as any],
})
export default class extends Vue {
  @Prop({ default: () => false }) public autoFocus!: boolean;

  public focusSearchElement(): void {
    (this as VueSelectInstance).searchEl.focus();
  }

  protected async mounted(): Promise<void> {
    await this.$nextTick();
    await this.$nextTick();
    if (this.autoFocus) this.focusSearchElement();
  }
}
