var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    [
      _vm.value === "article_8"
        ? [
            _vm.short
              ? _c("span", {
                  staticClass: "text-xs text-muted",
                  domProps: { textContent: _vm._s(8) },
                })
              : _c("span", {
                  staticClass: "text-xs text-muted",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.shared.global.models.products.sfdr") + ": 8"
                    ),
                  },
                }),
            _c("div", {
              staticClass: "mdi mdi-leaf mdi-16px text-primary ml-2",
            }),
          ]
        : _vm.value === "article_9"
        ? [
            _vm.short
              ? _c("span", {
                  staticClass: "text-xs text-muted",
                  domProps: { textContent: _vm._s(9) },
                })
              : _c("span", {
                  staticClass: "text-xs text-muted",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("ui.shared.global.models.products.sfdr") + ": 9"
                    ),
                  },
                }),
            _c("div", {
              staticClass: "mdi mdi-leaf mdi-16px text-primary-700 ml-2",
            }),
          ]
        : _c("span", [_vm._v("-")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }