var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "ci-loading flex-center position-fixed filled-vp",
      class: _vm.loading ? "show" : "hide",
    },
    [
      _c("div", { staticClass: "modal-backdrop show" }),
      _c(
        "div",
        {
          staticClass:
            "ci-loading__body position-relative flex-center flex-column text-light",
        },
        [
          _c("div", { staticClass: "mdi mdi-loading mdi-spin h1" }),
          _c("p", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.slow,
                expression: "slow",
              },
              {
                name: "t",
                rawName: "v-t",
                value: "loading.slow",
                expression: "'loading.slow'",
              },
            ],
            staticClass: "ci-loading__message text-center font-weight-semi",
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }