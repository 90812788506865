import { settingsService } from "@services/settings.service";

export class CurrentUserClass implements Settings.User {
  public admin: boolean = false;
  public id: Nullable<number> = null;
  public partner_admin: boolean = false;
  public advisor: boolean = false;
  public provider: Nullable<string> = null;
  public firstname: string = "";
  public sso: Nullable<boolean> = null;
  public type: Settings.UserType = "guest";
  public professional: boolean = false;
  public considers_himself_professional: boolean = false;
  public avatar: string = "";
  public slug: string = "";
  public region: Nullable<Settings.Region> | undefined = undefined;
  public confirmation_remaining_time_days: Nullable<string> = null;

  constructor() {
    this.initialize();
  }

  public get signedIn(): boolean {
    return ["user", "admin"].includes(this.type);
  }

  public initialize(): void {
    this.update(settingsService.options.user);
  }

  private update(data: Settings.User): void {
    if (this.checkProperty("admin", data)) this.admin = data.admin!;
    if (this.checkProperty("id", data)) this.id = data.id!;
    if (this.checkProperty("partner_admin", data)) this.partner_admin = data.partner_admin!;
    if (this.checkProperty("advisor", data)) this.advisor = data.advisor!;
    if (this.checkProperty("provider", data)) this.provider = data.provider!;
    if (this.checkProperty("firstname", data)) this.firstname = data.firstname!;
    if (this.checkProperty("sso", data)) this.sso = data.sso!;
    if (this.checkProperty("type", data)) this.type = data.type!;
    if (this.checkProperty("professional", data)) this.professional = data.professional!;
    if (this.checkProperty("considers_himself_professional", data))
      this.considers_himself_professional = data.considers_himself_professional!;

    if (this.checkProperty("avatar", data)) this.avatar = data.avatar!;
    if (this.checkProperty("slug", data)) this.slug = data.slug!;
    if (this.checkProperty("region", data)) this.region = data.region;
    if (this.checkProperty("confirmation_remaining_time_days", data))
      this.confirmation_remaining_time_days = data.confirmation_remaining_time_days!;
  }

  private checkProperty(key: Settings.UserInterfaceKeys, data: Settings.User): boolean {
    return key in data && data[key] !== undefined;
  }
}

export const CurrentUser = new CurrentUserClass();
