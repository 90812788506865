var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "toast show",
      class: _vm.classes,
      attrs: { role: "alert", "aria-live": "assertive", "aria-atomic": "true" },
    },
    [
      _vm.title
        ? _c("div", { staticClass: "toast-header" }, [
            _c("strong", {
              staticClass: "mr-2",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
            _c(
              "div",
              {
                staticClass: "close ml-auto",
                attrs: { type: "button", "aria-label": "Close" },
                on: { click: _vm.removeToast },
              },
              [_c("div", { staticClass: "mdi mdi-close" })]
            ),
          ])
        : _vm._e(),
      _vm.modal
        ? [
            _c("div", {
              directives: [
                {
                  name: "ci-modal",
                  rawName: "v-ci-modal",
                  value: _vm.modal,
                  expression: "modal",
                },
              ],
              staticClass: "toast-body cursor-pointer",
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
          ]
        : _vm.to || _vm.href
        ? [
            _c("ci-router-link", {
              staticClass: "toast-body cursor-pointer",
              attrs: { to: _vm.to || _vm.href },
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
          ]
        : [
            _c("div", {
              staticClass: "toast-body",
              domProps: { innerHTML: _vm._s(_vm.message) },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }