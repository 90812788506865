const isArray: Validators.IsArrayFn = (value) => Array.isArray(value);
const eachHasKeys: Validators.EachHasKeysFn = (objects, keys) => objects.every((object) => hasKeys(object, keys));
const hasKeys: Validators.HasKeysFn = (object, keys) => keys.every((key) => object.hasOwnProperty(key));
const isFunction: Validators.IsFunctionFn = (value) => {
  if (value) {
    return value && {}.toString.call(value) === "[object Function]";
  } else return false;
};
const isObject = (val: any): boolean => typeof val === "object" && val !== null && !Array.isArray(val);
export const CustomValidators: ValidatorDictionary = {
  isArray,
  eachHasKeys,
  hasKeys,
  isFunction,
  isObject,
};
