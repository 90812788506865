var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    staticClass: "ci-form--wrapper",
    attrs: {
      name: _vm.humanName,
      rules: _vm.rules,
      "skip-if-empty": false,
      tag: "div",
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors, invalid, classes }) {
            return [
              _c(
                "div",
                {
                  staticClass: "ci-checkbox--group",
                  class: [
                    {
                      ...classes,
                      "no-label": _vm.noLabel,
                      "no-spacing": _vm.noSpacing,
                      "v-center": _vm.vCenter,
                      "toggle-checkbox": _vm.toggle,
                    },
                  ],
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalValue,
                        expression: "internalValue",
                      },
                    ],
                    staticClass: "ci-checkbox--input",
                    attrs: {
                      name: _vm.name,
                      id: _vm.name,
                      type: "checkbox",
                      disabled: _vm.disabled,
                    },
                    domProps: {
                      value: _vm.fieldValue,
                      checked: Array.isArray(_vm.internalValue)
                        ? _vm._i(_vm.internalValue, _vm.fieldValue) > -1
                        : _vm.internalValue,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.internalValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = _vm.fieldValue,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.internalValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.internalValue = $$c
                          }
                        },
                        _vm.handleChange,
                      ],
                      input: _vm.handleInput,
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass:
                        "ci-checkbox--label d-flex align-items-center",
                      class: { "text-disabled": _vm.disabled },
                      attrs: { for: _vm.name },
                    },
                    [
                      _vm.required && _vm.showAsteriks
                        ? _c(
                            "span",
                            { staticClass: "mr-1", attrs: { id: "asteriks" } },
                            [_vm._v("*")]
                          )
                        : _vm._e(),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.label) },
                      }),
                      _vm._t("default"),
                    ],
                    2
                  ),
                  _vm.toggle
                    ? _c("label", {
                        staticClass: "ci-checkbox--toggle",
                        attrs: { for: _vm.name },
                      })
                    : _vm._e(),
                ]
              ),
              errors.length > 0
                ? _c("span", { staticClass: "ci-checkbox--error" }, [
                    _vm._v(_vm._s(errors[0])),
                  ])
                : _vm._e(),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }