import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { store } from "@stores";

@Module({
  store: store,
  name: "analytics",
  namespaced: true,
})
export class AnalyticsStoreModule extends VuexModule {
  private pLocations: Indexable<string> = {
    login: "",
    gatingBanner: "",
  };

  get loginLocation(): string {
    return this.pLocations.login;
  }

  get gatingBannerLocation(): string {
    return this.pLocations.gatingBanner;
  }

  @Action public async updateLoginLocation(loginLocation: string): Promise<void> {
    this.context.commit("assignLoginLocation", loginLocation);
  }

  @Action public async updateGatingLocation(gatingLocation: string): Promise<void> {
    this.context.commit("assignGatingLocation", gatingLocation);
  }

  @Mutation protected assignLoginLocation(data: string): void {
    this.pLocations.login = data;
  }

  @Mutation protected assignGatingLocation(data: string): void {
    this.pLocations.gatingBanner = data;
  }
}
