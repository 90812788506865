
import { Emitter } from "@utils/events";
import { BModal } from "bootstrap-vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class extends BModal {
  @Prop({ default: "flexible" }) variant!: Nullable<"grow" | "fixed" | "flexible">;
  @Prop({ default: "md" }) size!: "md" | "lg" | "fullscreen";

  public get modalStyles(): Indexable {
    const styles = {
      display: this.isBlock ? "block" : "none",
    };

    return styles;
  }

  public get modalClasses(): Indexable {
    return [
      {
        "fade": !this.noFade,
        "show": this.isShow,
        "busy": this.busy,
        "ci-modal": true,
        "ci-modal-flexible": this.variant === "flexible",
        "ci-modal-fixed": this.variant === "fixed",
        "ci-modal-grow": this.variant === "grow",
        "ci-modal-size-md": !["lg", "fullscreen"].includes(this.size),
        "ci-modal-size-lg": this.size === "lg",
        "ci-modal-size-fullscreen": this.size === "fullscreen",
        "ci-modal-no-header": this.hideHeader,
        "ci-modal-no-footer": this.hideFooter,
      },
      this.modalClass,
    ];
  }

  protected created(): void {
    this.$bvModalList.register(this.id);
    this.$on("show", () => Emitter.emit("ci::modal::show"));
    this.$on("hide", () => Emitter.emit("ci::modal::hide"));
  }

  protected destroyed(): void {
    this.$bvModalList.drop(this.id);
  }
}
