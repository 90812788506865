
import { Component, Vue } from "vue-property-decorator";
import Logo from "@images/logos/logo-ci-white-plain.svg";
import HeaderNavPrimary from "./nav-primary.vue";
import HeaderNavSecondary from "./nav-secondary.vue";
import { HeaderStoreModule } from "./header.store";
import { getModule } from "vuex-module-decorators";
import { Emitter } from "@utils/events";
import { ViewportStoreModule } from "@stores/viewport.store";
import { throttle } from "throttle-debounce";
import ResizeObserver from "resize-observer-polyfill";
import { ciTrackingService } from "@services/ci-tracking.service";

const ViewportStore = getModule(ViewportStoreModule);
const HeaderStore = getModule(HeaderStoreModule);

@Component({
  name: "ci-header",
  components: {
    "ci-header-nav-primary": HeaderNavPrimary,
    "ci-header-nav-secondary": HeaderNavSecondary,
  },
})
export default class extends Vue {
  public get navExpanded(): boolean {
    return HeaderStore.navExpanded;
  }

  public get searchExpanded(): boolean {
    return HeaderStore.searchExpanded;
  }

  public get conditionals(): Indexable<boolean> {
    return HeaderStore.conditionals;
  }

  public get logo(): string {
    return Logo as any;
  }

  public trackClick(event: Event, targetIdentifier: string): void {
    ciTrackingService.trackClick("ButtonOnMenuBar", targetIdentifier);
  }

  public toggleNav(): void {
    if (this.navExpanded) this.closeNav();
    else this.openNav();
  }

  protected mounted(): void {
    Emitter.on("ci::routing::start", () => this.closeNav());
    Emitter.on("ci::modal::show", () => this.closeNav());
    Emitter.on("ci::header-search::blur", () => HeaderStore.collapseSearch());

    new ResizeObserver(
      throttle(100, (entries: ResizeObserverEntry[]) => {
        const sizes = { ...ViewportStore.elementSizes, header: entries[0].contentRect.height };

        ViewportStore.setElementSizes(sizes);
      })
    ).observe(this.$el);
  }

  private closeNav(): void {
    HeaderStore.collapseNav();
    document.body.classList.remove("nav-open");
  }

  private openNav(): void {
    HeaderStore.expandNav();
    document.body.classList.add("nav-open");
  }
}
