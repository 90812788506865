import { Router } from "@router";
import { ciTrackingService } from "@services/ci-tracking.service";
import { apiService } from "@services/api.service";
import { ViewportStoreModule } from "@stores/viewport.store";
import { computed, Ref, ref, watch } from "vue";
import { NotificationMapper } from "./notifications.mapper";
import { getModule } from "vuex-module-decorators";

const ViewportStore = getModule(ViewportStoreModule);

const global = {
  popup: {
    state: ref(false),
    data: ref([]) as Ref<Notifications.MappedItem[]>,
    loading: ref(true),
    unseenCount: ref(0),
  },
  center: {
    data: ref([]) as Ref<Notifications.MappedItem[]>,
    loading: ref(true),
    pagination_loading: ref(false),
    hasNextPage: ref(true),
    params: ref({
      category: "all",
      only_unread: false,
    }),
    page: ref(1),
  },
  requestPending: ref(false),
};

const filtered = computed(() => ({
  center: {
    unseen: global.center.data.value.filter((notification) => !notification.original.seen_at),
    unread: global.center.data.value.filter((notification) => !notification.original.read_at),
  },
  popup: {
    unseen: global.popup.unseenCount,
    unread: global.popup.data.value.filter((notification) => !notification.original.read_at),
  },
}));

const trackPopupStateChange = (type: "close" | "open") => {
  ciTrackingService.track({
    event: {
      action: type,
      value: null,
    },
    target: {
      name: "NotificationPopup",
      identifier: "unused",
      url: location.href,
    },
    source: {
      name: Router.currentRoute.meta?.trackingIdentifier,
      identifier: location.href,
    },
  });
};

const popup = {
  ...global.popup,
  open: (track: boolean = false) => {
    global.popup.state.value = true;
    if (track) trackPopupStateChange("open");
  },
  close: (track: boolean = false) => {
    global.popup.state.value = false;
    if (track) trackPopupStateChange("close");
  },
  toggle: (track: boolean = false) => {
    global.popup.state.value = !global.popup.state.value;
    if (track) trackPopupStateChange(global.popup.state.value ? "open" : "close");
  },
  fetch: async () => {
    global.popup.loading.value = true;

    const response = await apiService.notifications.index();
    const unseenCount = await apiService.notifications.getUnseenCount();

    global.popup.data.value = response.data.notifications.map(NotificationMapper.map);
    global.popup.unseenCount.value = unseenCount.data.unseen_count;
    global.popup.loading.value = false;
  },
};

const center = {
  ...global.center,
  fetch: async () => {
    global.center.loading.value = true;

    const options = {
      ...(global.center.params.value.category === "all" ? {} : { category: global.center.params.value.category }),
      ...(global.center.page.value > 1 ? { page: global.center.page.value } : {}),
      ...(global.center.params.value.only_unread ? { only_unread: true } : {}),
    };

    global.requestPending.value = true;
    const response = await apiService.notifications.index(options);
    global.requestPending.value = false;
    global.center.hasNextPage.value = !!response.data.meta.next_page;
    global.center.data.value = response.data.notifications.map(NotificationMapper.map);
    global.center.loading.value = false;
    if (ViewportStore.smartphone) {
      window.scrollTo(0, 0);
    }
  },
  fetchNext: async () => {
    global.center.page.value++;
    global.center.pagination_loading.value = true;
    const options = {
      ...(global.center.params.value.category === "all" ? {} : { category: global.center.params.value.category }),
      page: global.center.page.value,
      ...(global.center.params.value.only_unread ? { only_unread: true } : {}),
    };
    global.requestPending.value = true;
    const response = await apiService.notifications.index(options);
    global.requestPending.value = false;
    global.center.hasNextPage.value = !!response.data.meta.next_page;

    response.data.notifications
      .map(NotificationMapper.map)
      .forEach((notification) => global.center.data.value.push(notification));
    global.center.pagination_loading.value = false;
  },
};

watch(global.center.params.value, () => {
  center.page.value = 1;
  center.fetch();
});

export const useNotifications = () => ({
  popup,
  center,
  filtered,
  requestPending: computed(() => global.requestPending.value),
  markAllAsSeen: async (context: "center" | "popup", category: string = "all") => {
    await apiService.notifications.setSeen([], context, category);
    global.center.data.value.forEach((notification) => (notification.original.seen_at = new Date().toISOString()));
    global.popup.data.value.forEach((notification) => (notification.original.seen_at = new Date().toISOString()));
    if (context === "popup" && global.popup.unseenCount.value) {
      global.popup.unseenCount.value = 0;
    }
  },
  markAllAsRead: async (context: "center" | "popup", category: string | "all" = "all") => {
    await apiService.notifications.setRead([], context, category);
    global.center.data.value.forEach((notification) => (notification.original.read_at = new Date().toISOString()));
    global.popup.data.value.forEach((notification) => (notification.original.read_at = new Date().toISOString()));
    if (global.center.params.value.only_unread) global.center.data.value = [];
  },
  markAsSeen: async (id: string, context: "center" | "popup") => {
    await apiService.notifications.setSeen([id], context);
    const listItem = global.center.data.value.find((notification) => notification.original.id === id);
    const popupItem = global.popup.data.value.find((notification) => notification.original.id === id);
    if (listItem) listItem.original.read_at = new Date().toISOString();
    if (popupItem) popupItem.original.read_at = new Date().toISOString();
  },
  markAsRead: async (id: string, context: "center" | "popup") => {
    await apiService.notifications.setRead([id], context);

    const listItem = global.center.data.value.find((notification) => notification.original.id === id);
    const popupItem = global.popup.data.value.find((notification) => notification.original.id === id);
    const readDate = new Date().toISOString();

    if (listItem) {
      listItem.original.seen_at = readDate;
      listItem.original.read_at = readDate;
    }

    if (popupItem) {
      popupItem.original.seen_at = readDate;
      popupItem.original.read_at = readDate;
    }
  },
});
