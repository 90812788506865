var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ci-gating", class: _vm.gated ? "gated" : "ungated" },
    [
      _c(
        "div",
        {
          ref: "teaser",
          staticClass: "ci-gating-teaser w-100",
          style: _vm.teaserStyle,
        },
        [_vm._t("default")],
        2
      ),
      _vm.gated
        ? _c("div", { staticClass: "ci-gating-banner" }, [
            _c("div", {
              staticClass: "ci-gating-banner__fade",
              style: _vm.teaserStyle,
            }),
            _c("div", { staticClass: "ci-gating-banner__blocker" }, [
              _c(
                "div",
                { staticClass: "rounded-lg border text-center p-4 p-md-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-md-center justify-content-center text-left text-muted",
                    },
                    [
                      _c("span", {
                        staticClass:
                          "mdi mdi-lock mdi-24px mr-3 mr-md-2 flex-shrink-0",
                      }),
                      _vm.gatingInfo.description[0]
                        ? _c("span", {
                            staticClass:
                              "ci-gating-banner__blocker--description text-md",
                            domProps: {
                              textContent: _vm._s(
                                _vm.gatingInfo.description[0]
                              ),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c("hr", { staticClass: "divider dark my-4" }),
                  _c("div", { staticClass: "mb-4" }, [
                    _vm.gatingInfo.enticement[0]
                      ? _c("h3", {
                          staticClass: "text-lg",
                          domProps: {
                            textContent: _vm._s(_vm.gatingInfo.enticement[0]),
                          },
                        })
                      : _vm._e(),
                    _vm.gatingInfo.enticement[1]
                      ? _c("h3", {
                          staticClass: "text-lg",
                          domProps: {
                            textContent: _vm._s(_vm.gatingInfo.enticement[1]),
                          },
                        })
                      : _vm._e(),
                  ]),
                  !_vm.$user.signedIn
                    ? _c("ci-button", {
                        directives: [
                          {
                            name: "ga-track-click",
                            rawName: "v-ga-track-click",
                            value: _vm.tracking.registration,
                            expression: "tracking.registration",
                          },
                        ],
                        staticClass: "ci-gating-banner__blocker--register",
                        attrs: {
                          variant: "filled-primary",
                          to: _vm.registrationUrl,
                          buttonClass: "ci-gating-banner__blocker--register",
                          label: _vm.$t("ui.components.gating.register"),
                        },
                      })
                    : _c("ci-button", {
                        attrs: {
                          buttonClass: "ci-gating-banner__blocker--register",
                          variant: "filled-primary",
                          to: {
                            name: _vm.$routes.USERS_SETTINGS,
                            query: { tab: "investment_profile" },
                          },
                          label: _vm.$t("ui.components.gating.adjust_profile"),
                        },
                      }),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      !_vm.$user.signedIn
                        ? [
                            _c("span", {
                              directives: [
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value:
                                    "ui.components.gating.already_registered",
                                  expression:
                                    "'ui.components.gating.already_registered'",
                                },
                              ],
                              staticClass: "mr-2",
                            }),
                            _c("a", {
                              directives: [
                                {
                                  name: "ga-track-click",
                                  rawName: "v-ga-track-click",
                                  value: _vm.tracking.login,
                                  expression: "tracking.login",
                                },
                                {
                                  name: "t",
                                  rawName: "v-t",
                                  value: "ui.components.gating.sign_in_now",
                                  expression:
                                    "'ui.components.gating.sign_in_now'",
                                },
                              ],
                              staticClass:
                                "ci-gating-banner__blocker--login cursor-pointer",
                              on: { click: _vm.login },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }