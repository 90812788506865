var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ci-header__nav-user" },
    [
      _c(
        "ci-dropdown-menu",
        {
          staticClass: "interaction-dropdown",
          attrs: {
            placement: "bottom",
            hover: !_vm.$root.handheld && !_vm.$root.isTouchDevice,
            "content-class": "py-2",
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "py-2" },
                    [
                      _c("ci-user-avatar", {
                        attrs: {
                          src: _vm.userImage,
                          sidebar: true,
                          username: _vm.$user.firstname,
                          size: "sm",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c("ci-dropdown-item", {
                attrs: {
                  iconClasses: "mdi mdi-24px mdi-account",
                  name: _vm.$t("ui.shared.navigation.user.profile"),
                  to: {
                    name: _vm.$routes.USERS_SHOW,
                    params: { slug: _vm.$user.slug },
                  },
                },
                on: {
                  click: function ($event) {
                    return _vm.trackUserNavClick("user-profile")
                  },
                },
              }),
              _vm.$user.admin
                ? _c("ci-dropdown-item", {
                    attrs: {
                      iconClasses: "mdi mdi-24px mdi-wrench",
                      name: _vm.$t("ui.shared.navigation.user.admin"),
                      href: "/admin",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.trackUserNavClick("admin")
                      },
                    },
                  })
                : _vm._e(),
              _c("ci-dropdown-item", {
                attrs: {
                  "icon-classes": "mdi mdi-24px mdi-cog",
                  name: _vm.$t("ui.shared.navigation.user.settings"),
                  to: { name: _vm.$routes.USERS_SETTINGS },
                },
                on: {
                  click: function ($event) {
                    return _vm.trackUserNavClick("settings")
                  },
                },
              }),
              _c("ci-dropdown-item", {
                attrs: {
                  id: "logout",
                  "icon-classes": "mdi mdi-24px mdi-power",
                  name: _vm.$t("ui.shared.navigation.user.logout"),
                  href: "/users/sign_out",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleLogoutClick("logout")
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }