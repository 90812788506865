import { DirectiveOptions } from "vue";

const RIPPLE_DURATION = 1000;

const directive: DirectiveOptions = {
  bind(element) {
    if (!element.style.position) element.style.position = "relative";
    element.classList.add("btn-cursor");
    const wrapper = document.createElement("div");
    wrapper.classList.add("ripple-wrapper");
    const ripple = document.createElement("div");
    ripple.classList.add("ripple");
    wrapper.appendChild(ripple);
    element.appendChild(wrapper);

    let timeout: ReturnType<typeof setTimeout>;

    const hideRipple = (): void => {
      ripple.classList.remove("show");
    };

    const showRipple = (event: MouseEvent): void => {
      const diameter = Math.max(element.clientHeight, element.clientWidth);
      const rect = element.getBoundingClientRect();
      const left = event.clientX - rect.left - diameter / 2 + "px";
      const top = event.clientY - rect.top - diameter / 2 + "px";
      ripple.style.width = diameter + "px";
      ripple.style.height = diameter + "px";
      ripple.style.left = left;
      ripple.style.top = top;

      ripple.classList.add("show");
    };

    element.addEventListener("mousedown", (event) => {
      hideRipple();
      clearTimeout(timeout);
      showRipple(event);
      timeout = setTimeout(hideRipple, RIPPLE_DURATION);
    });
  },
};

export default directive;
