import { render, staticRenderFns } from "./item.vue?vue&type=template&id=7bf4070d&lang=pug"
import script from "./item.vue?vue&type=script&lang=ts"
export * from "./item.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@locales/de/de.yml?namespace=ui.shared.navigation?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuild%2Ffrontend%2Fcomponents%2Fheader%2Fitem.vue&locale=de&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@locales/en/en.yml?namespace=ui.shared.navigation?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuild%2Ffrontend%2Fcomponents%2Fheader%2Fitem.vue&locale=en&lang=yaml&external"
if (typeof block1 === 'function') block1(component)

export default component.exports