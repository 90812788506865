import { DirectiveOptions } from "vue";
import { VBTooltip } from "bootstrap-vue";

const isTruncated = (element: HTMLElement): boolean => element.scrollWidth > element.clientWidth;

const directive: DirectiveOptions = {
  bind(element, binding, vnode, oldVnode) {
    vnode.context!.$nextTick(() => {
      if (isTruncated(element)) VBTooltip.bind!(element, binding, vnode, oldVnode);
    });
  },
  componentUpdated(element, binding, vnode, oldVnode) {
    vnode.context!.$nextTick(() => {
      if (isTruncated(element)) VBTooltip.componentUpdated!(element, binding, vnode, oldVnode);
    });
  },
  unbind(element, binding, vnode, oldVnode) {
    VBTooltip.unbind!(element, binding, vnode, oldVnode);
  },
};

export default directive;
