// eventBus.js
import { RangeSelectorButtonsEventsOptions } from "highcharts";
import mitt from "mitt";

/**
 * Naming convention
 * it emits that something is done? ci::<target>::<state>
 * it emits that something should be done? ci::<state>::<target>
 */

type BusEvents = {
  "ci::routing::start": void;
  "ci::routing::stop": { to: Route; from: Route };
  "ci::modal::show": void;
  "ci::modal::hide": void;
  "ci::focus::header-search": void;
  "ci::header-search::focus": void;
  "ci::header-search::blur": void;
  "ci::header-item::touched": string;
  "ci::portal-sticky::resized": number;
  "ci::globalsearch::searched": void;
  "ci::element-in-viewport": string;
  "ci::element-out-viewport": string;
  "ci::toast::create": Toasts.Props;
  "ci::toast::destroy": string;
  "ci::collapsible-header::updated": { id: string; collapsed: boolean };
  "ci::scroll::throttled": void;
  "ci::infinite-scroll": void;
  "ci::reflow::charts": void;
  "ci::asset-flow::fond-selected": {
    product: Nullable<Products.AssetflowScatterChartDataItem>;
    currency: Nullable<string>;
  };
  "ci::product::refetch": void;
  "ci::product-alarm::set-product-id": number;
  "ci::product-alarm::activate": PerformanceAlarms.Details;
  "ci::product-alarm::deactivate": void;
  "ci::product::visibility-toggled": void;
  "ci::product-collection-item::hovered": string | number;
  "ci::product-collection-item::left": string | number;
  "ci::page::print": void;
  "ci::page::resized": void;
  "ci::page::viewport-changed": string;
  "ci::clear-refinement::ais": void;
  "ci::user::avatar-changed": string;
  "ci::range-selector::clicked": { event: Event; id: string; period: RangeSelectorButtonsEventsOptions };
  "ci::comparison-cart::added": void;
  "ci::hide::dropdown-menu": void;
  "ci::dropdown-menu-item::selected": void;
  "ci::dropdown-menu-item::deselected": void;
  "ci::ads::display-ad": { itemId: string };
  "ci::ads::external::content::mounted": { itemId: string };
  "ci::loader::start": void;
  "ci::loader::stop": void;
  "ci::bookmark::followed": { id: string | number; type: "contents" | "products" | "portfolios" | "model_portfolios" };
  "ci::bookmark::unfollowed": {
    id: string | number;
    type: "contents" | "products" | "portfolios" | "model_portfolios";
  };
};

export const Emitter = mitt<BusEvents>();
