import Appsignal from "@appsignal/javascript";
import { settingsService } from "@services/settings.service";
import { plugin as pathDecorator } from "@appsignal/plugin-path-decorator";
import { plugin as networkBreadcrumbs } from "@appsignal/plugin-breadcrumbs-network";

export const appsignal = new Appsignal({
  key: process.env.NODE_ENV === "test" ? "dummy" : settingsService.options.appsignal_frontend_api_key,
  revision: settingsService.options.release,
  ignoreErrors: [/Network Error/, /Request aborted/],
});

appsignal.use(pathDecorator());
appsignal.use(networkBreadcrumbs());
