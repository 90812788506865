import { utilityService } from "@services/utility.service";
import { CustomValidators } from "@utils/validators";
import { DirectiveOptions } from "vue";

type ParsingOptions = { value: string | number; delimiter: string; symbol: string; decimals: number };

const parse = (options: ParsingOptions, element: HTMLElement | null, isCurrency: boolean): string => {
  const tpl = element?.getAttribute("tpl") || null;
  let val = options.value;
  if (typeof val === "string") val = parseFloat(val);
  if (val === 0 && isCurrency) return "0,00";
  if ((!val && val !== 0) || isNaN(val)) return "-";

  const { decimals } = options;
  const threshold = 1 / Math.pow(10, decimals);
  const rounded = utilityService.round(val, decimals) as number;
  if (Math.abs(rounded) < threshold) return `0${options.delimiter}${options.symbol}`;

  const parsed = `${val.toLocaleString(utilityService.currentLocale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}${options.delimiter}${options.symbol}`;

  if (tpl) return tpl.replace("$v", parsed);
  else return parsed;
};

const style = (options: ParsingOptions, element: HTMLElement, positive: boolean = true): void => {
  let val = options.value;
  const positiveClass = positive ? "text-primary" : "text-base";
  const negativeClass = "text-danger";
  const fallbackClass = "text-medium";

  if (typeof val === "string") val = parseFloat(val);
  if (!val || isNaN(val)) {
    element.classList.add(fallbackClass);
    return;
  }

  if (val < 0) {
    element.classList.remove(fallbackClass);
    element.classList.remove(positiveClass);
    element.classList.add(negativeClass);
  } else if (val > 0) {
    element.classList.remove(fallbackClass);
    element.classList.remove(negativeClass);
    element.classList.add(positiveClass);
  } else {
    element.classList.remove(negativeClass);
    element.classList.remove(positiveClass);
    element.classList.add(fallbackClass);
  }
};

const parsingOptions = (
  param: Nullable<string | number | Indexable> | undefined,
  defaultSymbol: string = ""
): ParsingOptions => ({
  value: CustomValidators.isObject(param) ? (param as Indexable).value || "" : param,
  delimiter: CustomValidators.isObject(param) ? (param as Indexable).delimiter : " ",
  symbol: CustomValidators.isObject(param) ? (param as Indexable).symbol || defaultSymbol : defaultSymbol,
  decimals: CustomValidators.isObject(param)
    ? (param as Indexable).decimals === 0
      ? 0
      : (param as Indexable).decimals || 2
    : 2,
});

// the order of unbind/bind may happen in reverse on the vue js rendering when resolving shadow dom diffs
// to work around that issue you may use keys on the parent element to enforce a complete replacement
const FormatDirectiveFactory = (symbol: string, isCurrency: boolean = false): DirectiveOptions => ({
  bind(element, binding) {
    const opts = parsingOptions(binding.value, symbol);
    element.textContent = parse(opts, element, isCurrency);
    if (binding.modifiers.style) style(opts, element, !binding.modifiers.negative);
  },
  update(element, binding) {
    const opts = parsingOptions(binding.value, symbol);
    element.textContent = parse(opts, element, isCurrency);
    if (binding.modifiers.style) style(opts, element, !binding.modifiers.negative);
  },
  unbind(element, binding) {
    if (!binding.modifiers.preserve) element.textContent = "";
  },
});

export const NumberFormatterHelpers = {
  parse: parse,
  parsingOptions: parsingOptions,
};

export const PercentageFormatDirective = FormatDirectiveFactory("%");
export const CurrencyFormatDirective = FormatDirectiveFactory("EUR", true);
export const NumberFormatDirective = FormatDirectiveFactory("");
