
import { Component, Vue } from "vue-property-decorator";
import CiLoading from "@components/base/loading.vue";
import { Loader } from "@composables/loader";

@Component({
  name: "ci-router-view",
  components: { CiLoading },
})
export default class extends Vue {
  public get error(): Nullable<RouterError> {
    return Loader.error.state;
  }

  public get loading(): boolean {
    return Loader.loading.state;
  }

  protected created(): void {
    this.$page.context = "spa";
  }
}
