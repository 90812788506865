import { i18nInstance } from "@composables/i18n";
import { dateService } from "@services/date.service";
import { GlobalSearch } from "@services/global-search";
import { viewportService } from "@services/viewport.service";

export const ResultMapper = (options: {
  type: string;
  label: string;
  image?: Nullable<string>;
  icon?: Nullable<string>;
  url?: Nullable<string>;
  track?: boolean;
}): AIS.ResultOption => ({ track: true, ...options });

const LabelCreator = {
  article: (article: Articles.Article) => {
    const date: string = article.publication_date
      ? `${dateService.format("dd.MM.yyyy", article.publication_date)} • `
      : "";
    const author: string = article.author ? `, ${article.author}` : "";
    return `${date}${article.title}${author}`;
  },
  product: (product: Products.Legacy.Product) => `ISIN: ${product.isin} • ${product.name}`,
  portfolio: (portfolio: Portfolios.Algolia) => `${portfolio.name} • ${portfolio.owner.name}`,
};

const GenerateSearchOptions = (): CapAlgolia.SearchOptions => {
  const options: CapAlgolia.SearchOptions = {};

  options["articles"] =
    options["podcasts"] =
    options["videos"] =
    options["webcasts"] =
    options["company_profiles"] =
    options["users"] =
    options["social_events"] =
      {
        hitsPerPage: viewportService.check(["xs", "sm"]) ? 1 : 2,
      };

  options["funds"] = {
    hitsPerPage: 4,
  };

  return options;
};

export const GlobalSearchInstance = new GlobalSearch(GenerateSearchOptions());

const CreateOptionsGroup = (label: string, items: AIS.ResultOption[]): AIS.ResultOption[] => {
  const arr = [...items];
  if (items.length > 0)
    arr.unshift({
      type: "chapter",
      label: label,
      track: false,
    });

  return arr;
};

// this defines the ordering of the instant search results.
// to change the ordering in the search results go to search.composable.ts
export const OptionsMapper = (results: CapAlgolia.GlobalSearchResult): AIS.ResultOption[] => [
  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.company_profiles") as string, [
    // i18n
    ...(results.parsed.company_profiles?.records || []).map(({ name, company_url: url }: Companies.Tile) =>
      ResultMapper({ type: "company_profile", label: name, icon: "mdi-domain", url })
    ),
  ]),

  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.funds") as string, [
    // i18n
    ...(results.parsed.funds?.records || []).map((product: Products.Legacy.Product) =>
      ResultMapper({ type: "fund", label: LabelCreator.product(product), icon: "mdi-trending-up", url: product.url })
    ),
  ]),

  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.portfolios") as string, [
    // i18n
    ...(results.parsed.portfolios?.records || []).map((portfolio: Portfolios.Algolia) =>
      ResultMapper({
        type: "portfolio",
        label: LabelCreator.portfolio(portfolio),
        icon: "mdi-chart-areaspline",
        url: portfolio.url,
      })
    ),
  ]),

  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.events") as string, [
    // i18n
    ...(results.parsed.social_events?.records || []).map(({ headline, event_url: url }: Events.Event) =>
      ResultMapper({ type: "social_event", label: headline, icon: "mdi-calendar", url })
    ),
    ...(results.parsed.webcasts?.records || []).map((article: Articles.Article) =>
      ResultMapper({
        type: "webcast",
        label: LabelCreator.article(article),
        icon: "mdi-television-play",
        url: article.url,
      })
    ),
  ]),

  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.news") as string, [
    // i18n
    ...(results.parsed.articles?.records || []).map((article: Articles.Article) =>
      ResultMapper({
        type: "article",
        label: LabelCreator.article(article),
        icon: "mdi-file-document",
        url: article.url,
      })
    ),
    ...(results.parsed.videos?.records || []).map((article: Articles.Article) =>
      ResultMapper({
        type: "video",
        label: LabelCreator.article(article),
        icon: "mdi-play-circle-outline",
        url: article.url,
      })
    ),
    ...(results.parsed.podcasts?.records || []).map((article: Articles.Article) =>
      ResultMapper({
        type: "podcast",
        label: LabelCreator.article(article),
        icon: "mdi-volume-high",
        url: article.url,
      })
    ),
  ]),

  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.users") as string, [
    // i18n
    ...(results.parsed.users?.records || []).map((user: Users.Algolia) =>
      ResultMapper({
        type: "user",
        label: [user.name, user.position, user.company_name].filter((e) => e).join(" • "),
        image: user.image_url,
        url: user.url,
      })
    ),
  ]),

  ...CreateOptionsGroup(i18nInstance.t("ui.shared.global.searchresults.content_hubs") as string, [
    ...(results.parsed.content_routes?.records || []).map((contentRoute: ContentHubs.Algolia) =>
      ResultMapper({
        type: "content_route",
        label: contentRoute.title,
        icon: "mdi-book-open-page-variant",
        url: contentRoute.path,
      })
    ),
  ]),
];
