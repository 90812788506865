
import { viewportService } from "@services/viewport.service";
import { ADS_INVENTORY } from "@models/constants/ads_inventory";
import { Emitter } from "@utils/events";
import { GoogletagSupport } from "@components/ads/googletags-support";
import { computed, defineComponent, onBeforeUnmount, onMounted } from "vue";
import { settingsService } from "@services/settings.service";
import { CurrentUser } from "@utils/user";

export default defineComponent({
  name: "CiSuggestedExternalContent",
  props: {
    id: { type: String, required: true },
    type: {
      type: String,
      required: true,
      validator: (t: string) => ["skyscraper", "sidebar", "display", "inline"].includes(t),
    },
    memberAd: {
      type: Boolean,
      default: false,
    },
    easeOut: {
      type: Boolean,
      default: true,
    },
    sticky: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const show = computed(() => {
      const pageAdsEnabled = settingsService.hasFlipper("page_ads");
      const visibleForUser = CurrentUser.signedIn === !!props.memberAd;
      const withSidebar = ADS_INVENTORY[props.id]?.sidebar && viewportService.check(["xl", "xxl"]);

      return pageAdsEnabled && visibleForUser && (withSidebar || props.type === "display" || props.type === "inline");
    });

    const wrapperClasses = computed(() => {
      const classes = [props.type];
      if (props.sticky) {
        classes.push("sticky");
      }
      if (ADS_INVENTORY[props.id]?.sidebar) return classes;
      if (props.type === "display") {
        classes.push("flex-center");
        if (props.easeOut) {
          classes.push("ease-out-section");
        }
        return classes;
      }
      if (props.type === "inline") {
        return classes.concat(["flex-center", "ci-content-padding-as-body-t"]);
      }
      return [];
    });

    const display = () => {
      if (show.value && GoogletagSupport.findSlotByDomId(props.id) !== undefined) {
        window.googletag.cmd.push(() => {
          window.googletag.display(props.id);
        });
      }
    };

    const addInitAdsListener = () => {
      Emitter.on("ci::ads::display-ad", ({ itemId }) => {
        if (itemId === props.id) {
          display();
        }
      });
    };

    const addResizeListener = () => {
      Emitter.on("ci::page::resized", async () => {
        await refreshCmd();
      });
    };

    const refreshCmd = async () => {
      window.googletag.cmd.push(() => {
        window.googletag.pubads().refresh();
      });
    };

    onMounted(() => {
      if (show.value) {
        addInitAdsListener();
        addResizeListener();
      }
      Emitter.emit("ci::ads::external::content::mounted", { itemId: props.id });
    });

    onBeforeUnmount(() => {
      Emitter.off("ci::ads::display-ad", addInitAdsListener);
      Emitter.off("ci::page::resized", addResizeListener);
    });

    return {
      show,
      wrapperClasses,
    };
  },
});
