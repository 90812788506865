import { utilityService } from "@services/utility.service";
import { CustomValidators } from "@utils/validators";
import { DirectiveOptions } from "vue";

type ParsingOptions = { value: string | number; opts?: Indexable };

const parse = (options: { value: any | number; opts?: Indexable }, element: HTMLElement): string => {
  const tpl = element.getAttribute("tpl");
  const { value } = options;
  const opts = options.opts || {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  let parsed = "-";
  if (["string", "number"].includes(typeof value)) {
    const isTimestamp = typeof value === "number";
    const date = new Date(isTimestamp ? value * 1000 : value);
    if (!isNaN(date.valueOf())) parsed = date.toLocaleDateString(utilityService.currentLocale, opts);
  }

  if (tpl) return tpl.replace("$v", parsed);
  else return parsed;
};

const parsingOptions = (param: string | number | Indexable): ParsingOptions => ({
  value: CustomValidators.isObject(param) ? (param as Indexable).value || "" : param,
  opts: CustomValidators.isObject(param) ? (param as Indexable).opts || null : null,
});

const directive: DirectiveOptions = {
  bind(element, binding) {
    element.textContent = parse(parsingOptions(binding.value), element);
  },
  update(element, binding) {
    element.textContent = parse(parsingOptions(binding.value), element);
  },
  unbind(element, binding) {
    if (!binding.modifiers.preserve) element.textContent = "";
  },
};

export const DateFormatterHelper = {
  parse: parse,
};

export default directive;
