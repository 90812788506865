
import { PortalTarget } from "portal-vue";
import { Component, Prop } from "vue-property-decorator";
import { CreateElement } from "vue";
@Component({})
export default class extends PortalTarget {
  @Prop({ default: false }) sticky!: boolean;

  protected render(h: CreateElement): VNode {
    const noWrapper = this.noWrapper();
    const children = this.children();
    const Tag = this.transition || this.tag;
    if (noWrapper) {
      return children[0];
    } else {
      if (this.slim && !Tag) return h();
      else {
        return h(
          Tag,
          {
            props: {
              // if we have a transition component, pass the tag if it exists
              tag: this.transition && this.tag ? this.tag : undefined,
            },
            class: {
              "vue-portal-target": true,
              "sticky": this.sticky,
            },
          },
          children
        );
      }
    }
  }
}
