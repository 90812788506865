var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "ci-notifications-list d-flex justify-content-center flex-grow-1",
      class:
        _vm.items.length === 0 ? "align-items-center" : "align-items-start",
      attrs: { id: "notifications-list" },
    },
    [
      _vm.loading
        ? [
            _c("div", {
              staticClass: "flex-center mdi mdi-loading mdi-spin mdi-48px",
            }),
          ]
        : [
            _vm.items.length
              ? [
                  _c(
                    "div",
                    { staticClass: "notifications-list-wrapper w-100" },
                    [
                      _vm._l(_vm.items, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.original.id,
                            staticClass: "notification-list-item",
                            attrs: { "data-testid": "notification-item" },
                          },
                          [
                            _c("notification-wrapper", {
                              attrs: {
                                "handheld-style": _vm.handheldStyle,
                                notification: item,
                                location: _vm.location,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._t("default"),
                    ],
                    2
                  ),
                ]
              : [
                  _c(
                    "ci-emptystate",
                    {
                      staticClass: "h-100 flex-center mb-3 mt-n3",
                      attrs: {
                        "image-type": "notifications",
                        "locale-path": `notifications.${_vm.type}.${
                          _vm.category || "all"
                        }`,
                        small: _vm.location === "popup",
                      },
                    },
                    [
                      _vm.location !== "popup"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex m-n2 flex-wrap w-100 justify-content-center emptystate-buttons-gap",
                            },
                            _vm._l(_vm.buttons, function (button) {
                              return _c("ci-button", {
                                key: button.labelKey,
                                staticClass:
                                  "mt-2 ci-notifications-list__edit-button",
                                attrs: {
                                  label: _vm.$t(button.labelKey),
                                  variant: button.variant || "filled-primary",
                                  to: button.to,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }