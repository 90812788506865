import { getModule } from "vuex-module-decorators";
import { ViewportStoreModule } from "@stores/viewport.store";
import { throttle } from "throttle-debounce";
import { Emitter } from "@utils/events";

const viewportStore = getModule(ViewportStoreModule);
class Service implements Viewport.Service {
  public get handheldSizes(): Array<Viewport.VPs> {
    return ["xs", "sm", "md"];
  }

  public get desktopSizes(): Array<Viewport.VPs> {
    return ["lg", "xl", "xxl"];
  }

  public check: Viewport.CheckFn = (viewport) => {
    if (typeof viewport === "string") return viewportStore.currentViewport === viewport;
    if (Array.isArray(viewport)) return viewport.indexOf(viewportStore.currentViewport) >= 0;
    return false;
  };

  public registerEvents: Viewport.RegisterEventsFn = () => {
    const emitter = throttle(50, () => {
      this.recalculateAppHeight();
      Emitter.emit("ci::page::resized");
      viewportStore.update().then((viewport) => {
        if (viewport) Emitter.emit("ci::page::viewport-changed", viewport);
      });
    });
    window.addEventListener("resize", emitter);
    viewportStore.update();
    this.recalculateAppHeight();
  };

  private recalculateAppHeight(): void {
    /*
     * set --app-height CSS var to allow avoiding 100vh for fixed elements
     * https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
     */
    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
  }
}

export const viewportService = new Service();
