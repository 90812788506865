
import { Vue, Component, Prop } from "vue-property-decorator";
import Highcharts from "highcharts";
import HCAccessibilityInit from "highcharts/modules/accessibility";
import HCStockInit from "highcharts/modules/stock";
import { Chart } from "highcharts-vue";
import { Emitter } from "@utils/events";

HCAccessibilityInit(Highcharts);
HCStockInit(Highcharts);

@Component({
  name: "ci-highcharts",
  components: {
    highcharts: Chart,
  },
})
export default class extends Vue {
  @Prop({ default: null }) public id!: string;
  @Prop({ default: () => ({}) }) public options!: Highcharts.Options;
  @Prop({ default: "stockChart" }) public constructorType!: "stockChart" | "mapChart" | "ganttChart" | "chart";
  @Prop({ default: () => () => ({}) }) public callback!: (chart: Highcharts.Chart) => any;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: "" }) public disabledText!: string;

  public get mappedOptions(): Highcharts.Options {
    const chartOptions = this.options.chart;
    return {
      ...this.options,
      chart: { ...chartOptions, className: `${chartOptions?.className} ${this.id}` },
    };
  }

  public register(chart: Highcharts.Chart): void {
    if (this.callback) this.callback(chart);

    if ((chart as any).rangeSelector) {
      Emitter.on("ci::range-selector::clicked", this.syncSiblings);
    }

    Highcharts.addEvent(chart.container, "click", () => this.$emit("chart-clicked"));
    Highcharts.addEvent(chart, "render", () => this.$emit("rendered"));
    Highcharts.addEvent(chart.xAxis[0], "setExtremes", (e: any) => {
      this.$emit("period-changed", e.rangeSelectorButton?.id);
    });
    Highcharts.addEvent(chart.xAxis[0], "afterSetExtremes", (e: any) => {
      this.$nextTick(() => this.$emit("zoom", !!e.target.chart.resetZoomButton));
    });
    Highcharts.addEvent(chart, "pan", (e: any) => this.$emit("pan", e));
  }

  protected created(): void {
    Highcharts.setOptions({
      lang: {
        rangeSelectorTo: this.$t("ui.shared.global.highcharts.range_selector_to") as string,
        rangeSelectorZoom: "",
        loading: this.$t("ui.shared.global.highcharts.loading") as string,
        noData: this.$t("ui.shared.global.no_data_available") as string,
        resetZoom: this.$t("ui.shared.global.highcharts.reset_zoom") as string,
      },
    });
  }

  protected mounted(): void {
    this.$root.$on("bv::show::modal", (ev: any) => {
      if (ev === "charts-fullscreen") this.reflow();
    });
    Emitter.on("ci::reflow::charts", () => this.reflow(0));
  }

  private reflow(timeout = 100) {
    setTimeout(() => {
      const chartRef = this.$refs.highcharts as Nullable<Indexable>;
      chartRef?.chart.reflow();
    }, timeout);
  }

  private syncSiblings(e: any) {
    if (this.id && this.id === e.id) {
      const siblings = Highcharts.charts.filter((chart) => chart?.container.classList.contains(this.id));
      siblings.forEach((chart: any) => {
        const index = chart.rangeSelector.buttonOptions.findIndex((opt: any) => opt.id === e.period.id);
        chart.rangeSelector.clickButton(index);
      });
    }
  }
}
