
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    value: {
      type: String as PropType<"article_8" | "article_9">,
      default: "",
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
});
