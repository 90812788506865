var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "ci-highcharts--wrapper w-100 h-100 d-flex",
      class: { disabled: _vm.disabled },
    },
    [
      _vm.disabled
        ? _c(
            "div",
            { staticClass: "ci-highcharts--disabled h-100 w-100 flex-center" },
            [
              _vm.disabledText
                ? _c("span", {
                    staticClass:
                      "reason text-body bg-light p-2 rounded font-weight-semi",
                    domProps: { textContent: _vm._s(_vm.disabledText) },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c("highcharts", {
        ref: "highcharts",
        staticClass: "ci-highcharts w-100",
        attrs: {
          disabled: _vm.disabled,
          options: _vm.mappedOptions,
          "constructor-type": _vm.constructorType,
          "data-chart-id": _vm.id,
          callback: _vm.register,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }