import Vue from "vue";
import VueI18n from "vue-i18n";
import { appsignal } from "../appsignal";

import { settingsService } from "@services/settings.service";

Vue.use(VueI18n);
export const getLocaleFromSettings = () => settingsService.options.locale;

const i18n = new VueI18n({
  locale: getLocaleFromSettings(),
  fallbackLocale: getLocaleFromSettings(),
  silentFallbackWarn: true,
  messages: {},
  missing: (locale, key, _vm, values) => {
    if (settingsService.options.environment === "production") {
      appsignal.sendError(new Error(`missing translation locale: ${locale} key: ${key}`));
    }

    console.warn(`[vue-i18n] missing translation locale: ${locale} key: ${key}, values: ${values}`);
  },
});

export default i18n;
