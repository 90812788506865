import { chartApiService } from "@services/performance_charts/chart-api.service";
import PortfolioPerformanceRequest from "@services/performance_charts/portfolio-performance-request";
import ProductPerformanceChartRequest from "@services/performance_charts/product-performance-chart-request";
import PeergroupPerformanceChartRequest from "@services/performance_charts/peergroup-performance-chart-request";

export default class PerformanceChartRequest implements ChartApi.ChartProvider {
  private options: Partial<ChartApi.GetOptions>;
  private requestedObjects: ChartApi.RequestObject[];
  private needToRequestChart: boolean;
  private chartResponse: ChartApi.ResponseItem<number>[];

  constructor(options: Partial<ChartApi.GetOptions> = {}) {
    this.options = options;
    this.chartResponse = [];
    this.requestedObjects = [];
    this.needToRequestChart = true;
  }

  public async charts(): Promise<ChartApi.ResponseItem<number>[]> {
    if (this.needToRequestChart) {
      this.chartResponse = await this.requestChart();
      this.needToRequestChart = false;
    }

    return this.chartResponse;
  }

  public addProduct(isin: string): ProductPerformanceChartRequest {
    const request = new ProductPerformanceChartRequest(isin, this);
    this.requestedObjects.push(request);
    this.needToRequestChart = true;
    return request;
  }

  public addPeergroup(chartId: string): PeergroupPerformanceChartRequest {
    const request = new PeergroupPerformanceChartRequest(chartId, this);
    this.requestedObjects.push(request);
    this.needToRequestChart = true;
    return request;
  }

  public addPortfolio(portfolio: Portfolios.TileData | Portfolios.Data): PortfolioPerformanceRequest {
    const request = new PortfolioPerformanceRequest(portfolio, this);
    this.requestedObjects.push(request);
    this.needToRequestChart = true;
    return request;
  }

  public remove(request: ChartApi.RequestObject): boolean {
    const index = this.requestedObjects.indexOf(request);
    if (index > -1) {
      this.requestedObjects.splice(index, 1);
      this.needToRequestChart = true;
      return true;
    }

    return false;
  }

  private async requestChart(): Promise<ChartApi.ResponseItem<number>[]> {
    const identifiers = new Set<string>();

    this.requestedObjects.forEach((requestedObject) => {
      requestedObject.identifiers().forEach((identifier) => identifiers.add(identifier));
    });

    return await chartApiService.get(Array.from(identifiers), this.options);
  }
}
