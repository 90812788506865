var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    staticClass: "ci-form--wrapper",
    attrs: {
      name: _vm.validationName,
      rules: _vm.rules,
      vid: _vm.vid,
      customMessages: _vm.validationMessages,
      mode: _vm.mode,
      tag: "div",
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ classes, errors, invalid, validated }) {
          return [
            _c(
              "div",
              {
                staticClass: "ci-form--group",
                class: {
                  ...classes,
                  ..._vm.baseClasses,
                  invalid: validated && invalid,
                },
              },
              [
                _vm.leadingIcon
                  ? [
                      _c("span", {
                        staticClass: "ci-form--leading-icon flex-center mdi",
                        class: _vm.leadingIconClasses,
                        on: { click: _vm.leadingIconHandler },
                      }),
                    ]
                  : _vm._e(),
                _c("input", {
                  staticClass: "ci-form--input",
                  class: _vm.inputClass,
                  attrs: {
                    name: _vm.name,
                    type: _vm.type,
                    placeholder: _vm.placeholder,
                    id: _vm.inputId,
                    autocomplete: _vm.autocomplete ? "on" : "off",
                    disabled: _vm.disabled,
                    readonly: _vm.readonly,
                  },
                  domProps: { value: _vm.value },
                  on: { input: _vm.handleInput, paste: _vm.handlePaste },
                }),
                _c(
                  "label",
                  {
                    staticClass: "ci-form--label",
                    attrs: { for: _vm.inputId },
                  },
                  [_vm._v(_vm._s(_vm.visibleLabel))]
                ),
                _vm.trailingIcon
                  ? [
                      _c("span", {
                        staticClass:
                          "ci-form--trailing-icon btn-cursor flex-center mdi",
                        class: _vm.trailingIconClasses,
                        on: { click: _vm.trailingIconHandler },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            validated && invalid
              ? _c("span", { staticClass: "ci-form--error text-danger" }, [
                  _vm._v(_vm._s(errors[0])),
                ])
              : _vm._e(),
            _vm.hint
              ? _c("span", {
                  staticClass: "ci-form--hint",
                  domProps: { textContent: _vm._s(_vm.hintText) },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }