export const Constants = {
  maxConversationsPerWeek: 5, //sync with RoR User::MAX_CONVERSATIONS_PER_WEEK
  breakpoints: {
    xs: 360,
    sm: 480,
    md: 768,
    lg: 1000,
    xl: 1300,
    xxl: 1600,
  },
  ciTracking: {
    eventValueGated: 0,
    eventValueAccessible: 1,
    appReferrer: "App",
  },
  comparisonListMaxSize: 10,
  defaultDelay: 500,
  productPerformanceChartDataThreshold: 4,
  modalTimeout: 10000,
  algoliaMaxCharacters: 512,
  communityManagerSlug: "carla-von-capinside",
};
