import { utilityService } from "@services/utility.service";
import { DirectiveOptions } from "vue";
import { ciTrackingService } from "@services/ci-tracking.service";

const directive: DirectiveOptions = {
  bind(element, binding) {
    const impression = binding.value;
    if (ciTrackingService.validateImpression(impression)) {
      const interval = window.setInterval(() => {
        if (utilityService.isVisible(element))
          ciTrackingService.trackImpression(impression).catch(() => window.clearInterval(interval));
      }, 500);
    } else {
      console.error("malformed impression tracking", impression);
    }
  },
};

export default directive;
