import { PluginObject } from "vue/types/umd";
import GAClickDirective from "@directives/tracking/ga-click";
import InternalImpressionDirective from "@directives/tracking/internal-impression";
import TruncatedDirective from "@directives/base/truncated";
import TruncatedTooltipDirective from "@directives/base/truncated-tooltip";
import CopyToClipboardDirective from "@directives/base/copy-to-clipboard";
import DateFormatter from "@directives/base/date-formatter";
import {
  PercentageFormatDirective,
  CurrencyFormatDirective,
  NumberFormatDirective,
} from "@directives/base/number-formatter";
import CreateToastDirective from "@directives/base/create-toast";
import ElementInViewportDirective from "@directives/base/element-in-viewport";
import CollapsibleHeader from "@directives/base/collapsible-header";
import VisibleScrolled from "@directives/base/visible-scrolled";
import ScrollToTop from "@directives/base/scroll-to-top";

export const CIDirectivesPlugin: PluginObject<boolean> = {
  install: (v) => {
    // Base
    v.directive("ci-collapsible-header", CollapsibleHeader);
    v.directive("ci-copy-to-clipboard", CopyToClipboardDirective);
    v.directive("ci-create-toast", CreateToastDirective);
    v.directive("ci-is-in-viewport", ElementInViewportDirective);
    v.directive("ci-format-date", DateFormatter);
    v.directive("ci-format-percent", PercentageFormatDirective);
    v.directive("ci-format-currency", CurrencyFormatDirective);
    v.directive("ci-format-number", NumberFormatDirective);
    v.directive("ci-scroll-to-top", ScrollToTop);
    v.directive("ci-truncated", TruncatedDirective);
    v.directive("ci-truncated-tooltip", TruncatedTooltipDirective);
    v.directive("ci-visible-scrolled", VisibleScrolled);

    // Tracking
    v.directive("ga-track-click", GAClickDirective);
    v.directive("ci-track-impression", InternalImpressionDirective);
  },
};
