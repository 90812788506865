import { PluginObject } from "vue";
import { VueConstructor } from "vue/types/umd";
import { ComponentsDictionary, LegacyComponentsDictionary } from "./components.dictionary";

export const RegisterComponentDictionary = (dictionary: ComponentsDictionaryFlat, v: VueConstructor<Vue>): void => {
  Object.entries(dictionary).map(([tag, component]) => v.component(tag, component));
};

export const CIComponentsPlugin: PluginObject<boolean> = {
  install: (v) => {
    RegisterComponentDictionary(
      {
        ...ComponentsDictionary.sync,
        ...ComponentsDictionary.async,
        ...LegacyComponentsDictionary.sync,
        ...LegacyComponentsDictionary.async,
      },
      v
    );
  },
};
