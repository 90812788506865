
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import NavChild from "./child.vue";
import { Emitter } from "@utils/events";
import { NavigationHelper } from "./helper";
import { viewportService } from "@services/viewport.service";
import "array-flat-polyfill";
import { ciTrackingService } from "@services/ci-tracking.service";

const NAV_HOVER_THRESHOLD = 175;

@Component({
  name: "ci-nav-item",
  components: {
    "ci-nav-child": NavChild,
  },
})
export default class extends Vue {
  @Prop({ required: true }) item!: Navigation.Item;
  @Prop({ default: false }) hideLabel!: boolean;
  @Prop({ default: true }) drop!: boolean;
  @Prop({ default: false }) notification!: number | boolean;
  @Prop({ default: null }) tooltipData!: {
    text: string;
    props?: { disabledTooltip?: string; gatedTooltip?: string; tooltipClass?: string; showGatingTooltip?: boolean };
  };

  public hover: boolean = false;
  public open: boolean = false;
  public routing: boolean = false;
  public hoverTimeout: ReturnType<typeof setTimeout> | null = null;
  public unhoverTimeout: ReturnType<typeof setTimeout> | null = null;

  public get active(): boolean {
    return this.checkActivePath(this.item);
  }

  public get linkProps(): Indexable {
    const followLink =
      this.item.groups.length > 0 ? !(this.$root.isTouchDevice && viewportService.check("lg") && !this.hover) : true;
    const link =
      this.item.route.resolved.meta!.spa && this.$page.context === "spa"
        ? { to: this.item.route.location }
        : { href: this.item.route.href };

    const props: Indexable = {
      active: this.active,
      label: this.hideLabel ? "" : this.$t(`ui.shared.navigation.main.${this.item.localeKey}.title`),
      variant: "filled-navigation",
      dropdown: this.item.groups.length > 0 && !this.$root.handheld,
      icon: this.item.icon,
      buttonClass: "px-2",
      notification: this.notification,
      iconClass: "mdi-24px",
      gated: this.item.route.resolved.meta?.gated,
      ...(followLink ? link : {}),
      ...(this.tooltipData
        ? {
            tooltip: this.tooltipData.text,
            ...(this.tooltipData.props || {}),
          }
        : {}),
    };
    return props;
  }

  @Watch("active")
  onActiveChanged(val: boolean): void {
    this.open = val;
  }

  public trackChildNavItemClick(item: Navigation.Item | Navigation.Child): void {
    ciTrackingService.trackClick("ButtonOnMenuBar", item.trackingIdentifier);
  }

  public checkActivePath(item: Navigation.Item): boolean {
    const children = item.groups.map((group) => group.children).flat();

    if (children.some((child) => NavigationHelper.ItemIsActive(child, this.$route))) return true;

    if (item.additionalRoutes.includes(this.$route.name!)) return true;

    return NavigationHelper.ItemIsActive(item, this.$route);
  }

  public handleTouched(ev: Event): void {
    this.$emit("click", ev);
    Emitter.emit("ci::header-item::touched", this.item.id);
  }

  public handleMouseEnter(): void {
    if (!this.$root.handheld && !this.$root.isTouchDevice) {
      if (this.unhoverTimeout) clearTimeout(this.unhoverTimeout);
      this.hoverTimeout = setTimeout(() => (this.hover = true), NAV_HOVER_THRESHOLD);
    }
  }

  public handleMouseLeave(): void {
    if (!this.$root.handheld && !this.$root.isTouchDevice) {
      if (this.hoverTimeout) clearTimeout(this.hoverTimeout);
      this.unhoverTimeout = setTimeout(() => (this.hover = false), NAV_HOVER_THRESHOLD);
    }
  }

  protected mounted(): void {
    Emitter.on("ci::routing::start", () => this.disableHeader());
    Emitter.on("ci::modal::show", () => this.disableHeader());

    Emitter.on("ci::routing::stop", () => setTimeout(() => this.enableDropping(), NAV_HOVER_THRESHOLD));
    Emitter.on("ci::modal::hide", () => setTimeout(() => this.enableDropping(), NAV_HOVER_THRESHOLD));

    Emitter.on("ci::header-item::touched", (id: string) => {
      if (this.item.id !== id) this.hover = false;
      else this.hover = true;
    });
  }

  private disableHeader(): void {
    this.routing = true;
    this.hover = false;
  }

  private enableDropping(): void {
    this.routing = false;
  }
}
