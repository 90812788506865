import { Emitter } from "@utils/events";
import { DirectiveOptions } from "vue";

const toggleVisibility = (element: HTMLElement, threshold: number): void => {
  if (window.scrollY <= threshold) element.classList.add("d-none");
  else element.classList.remove("d-none");
};

const directive: DirectiveOptions = {
  bind(element, binding) {
    const { amount, unit } = binding.value;

    const threshold = unit === "vp" ? window.innerHeight * amount : amount;

    toggleVisibility(element, threshold);
    Emitter.on("ci::scroll::throttled", () => toggleVisibility(element, threshold));
  },
};

export default directive;
