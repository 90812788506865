// Takes the datefns locale and overrides the formatDistance function to use
// shorter strings than usual

import { enGB } from "date-fns/locale";

const formatDistanceLocale: Indexable = {
  lessThanXSeconds: {
    one: "< 1 sec",
    other: "< {{count}} sec",
  },
  xSeconds: {
    one: "1 sec",
    other: "{{count}} sec",
  },
  halfAMinute: "30 sec",
  lessThanXMinutes: {
    one: "< 1 min",
    other: "< {{count}} min",
  },
  xMinutes: {
    one: "1 min",
    other: "{{count}} min",
  },
  aboutXHours: {
    one: "1 h",
    other: "{{count}} h",
  },
  xHours: {
    one: "1 h",
    other: "{{count}} h",
  },
  xDays: {
    one: "1 d",
    other: "{{count}} d",
  },
  aboutXWeeks: {
    one: "1 wk",
    other: "{{count}} wk",
  },
  xWeeks: {
    one: "1 wk",
    other: "{{count}} wk",
  },
  aboutXMonths: {
    one: "1 mo",
    other: "{{count}} mo",
  },
  xMonths: {
    one: "1 mo",
    other: "{{count}} mo",
  },
  aboutXYears: {
    one: "1 yr",
    other: "{{count}} yr",
  },
  xYears: {
    one: "1 yr",
    other: "{{count}} yr",
  },
  overXYears: {
    one: "> 1 yr",
    other: "> {{count}} yr",
  },
  almostXYears: {
    one: "~ 1 yr",
    other: "~ {{count}} yr",
  },
};

const formatDistance = (token: any, count: any, options: any) => {
  let result;

  const tokenValue = formatDistanceLocale[token];
  if (typeof tokenValue === "string") {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace("{{count}}", count.toString());
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }

  return result;
};

export default { ...enGB, formatDistance };
