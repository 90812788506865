
import { ciTrackingService } from "@services/ci-tracking.service";
import { gtmTrackingService } from "@services/gtm-tracking.service";
import { BTab, BTabs } from "bootstrap-vue";
import { Component, Prop } from "vue-property-decorator";
import { HandleRouterError } from "@router/error-handler";

@Component({
  name: "BTabsExtended",
  components: { "b-tab": BTab },
})
export default class extends BTabs {
  @Prop({ default: true }) public tracking!: boolean;
  @Prop({ default: true }) public replaceUrl!: boolean;
  @Prop({ default: "tab-nav-wrapper" }) public navWrapperClass!: string | string[] | Indexable<boolean>;
  @Prop({ default: "tab" }) public queryParam!: string;
  @Prop({ default: false }) public uniqueParam!: boolean;
  @Prop({ default: () => ({}) }) public trackingOverwrites!: CiTracking.TrackingData;

  public clickTab($tab: BTab, event: Event): void {
    if (this.replaceUrl) this.tabChange($tab);
    if (!this.replaceUrl && this.tracking) this.trackTabChange($tab);
    this.activateTab($tab);
    this.scrollIntoView($tab);
    this.emitTabClick($tab, event);
  }

  protected async mounted(): Promise<void> {
    await this.$nextTick();

    if (this.$route.query[this.queryParam]) {
      const target: BTab = this.registeredTabs.find((tab: BTab) => tab.id === this.$route.query[this.queryParam]);
      if (target) {
        this.activateTab(target);
        this.scrollIntoView(target);
      }
    }
  }

  private scrollIntoView($tab: BTab): void {
    const navWrapper = this.$el.querySelector(`.${this.navWrapperClass}`) as HTMLElement;
    const navElement = this.$el.querySelector(`.nav-tabs [aria-controls='${$tab.id}']`)?.parentElement as HTMLElement;

    if (navWrapper && navElement) {
      navWrapper.scrollTo({
        top: 0,
        left: navElement.offsetLeft - (navWrapper.clientWidth - navElement.clientWidth + navWrapper.offsetLeft) / 2,
        behavior: "smooth",
      });
    }
  }

  private async tabChange($tab: BTab): Promise<void> {
    try {
      if (this.uniqueParam) {
        await this.$router.replace({ query: { [this.queryParam]: $tab.id } });
      } else {
        await this.$router.replace({ query: { ...this.$route.query, [this.queryParam]: $tab.id } });
      }
      if (this.tracking) this.trackTabChange($tab);
    } catch (err) {
      HandleRouterError(err);
    }
  }

  private trackTabChange($tab: BTab) {
    const index = this.registeredTabs.findIndex((tab: BTab) => tab.id === $tab.id);
    const name = this.$route.meta?.trackingIdentifier || "Undefined";

    const internalPayload: CiTracking.TrackingData = {
      event: {
        ...{
          action: "tab_change",
          value: null,
        },
        ...this.trackingOverwrites.event,
      },
      target: {
        ...{
          name: $tab.id,
          identifier: index.toString(),
          url: location.href,
        },
        ...this.trackingOverwrites.target,
      },
      source: {
        ...{
          name: null,
          identifier: ciTrackingService.tabChangeSourceIdentifier(),
        },
        ...this.trackingOverwrites.source,
      },
    };

    const analyticsPayload: GTMTracking.GaTrackingEvent = {
      eventCategory: name,
      eventAction: "TabChange",
      eventLabel: `${this.queryParam} : ${$tab.id}; url = ${location.href}`,
    };

    gtmTrackingService.track(analyticsPayload);
    ciTrackingService.track(internalPayload);
  }
}
