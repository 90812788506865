import { utilityService } from "@services/utility.service";

export default class PortfolioPerformanceRequest implements ChartApi.RequestObject {
  private request: ChartApi.ChartProvider;
  private portfolio: Portfolios.TileData | Portfolios.Data;

  constructor(portfolio: Portfolios.TileData | Portfolios.Data, request: ChartApi.ChartProvider) {
    this.portfolio = portfolio;
    this.request = request;
  }

  public get portfolioId(): number {
    return this.portfolio.id;
  }

  public identifiers(): string[] {
    const funds = this.portfolio.portfolio_products;
    const anyNonZeroWeight: boolean = funds.some((portfolioProduct) => portfolioProduct.weight);
    const relevantFunds = anyNonZeroWeight ? funds.filter((portfolioProduct) => portfolioProduct.weight) : funds;
    return relevantFunds.map((portfolioProduct) => portfolioProduct.product.isin);
  }

  public async chart(): Promise<ChartApi.ResponseItemSeriesData<number>> {
    return this.mergeCharts();
  }

  public meta(): Promise<ChartApi.ResponseItemMeta> {
    return Promise.resolve({
      currency: "",
      name: this.portfolio.name,
      id: `${this.portfolio.slug}-${utilityService.guid()}`,
    });
  }

  public async productCharts(): Promise<ChartApi.ResponseItem<number>[]> {
    return (await this.request.charts()).filter((chart) => this.identifiers().includes(chart.meta.id));
  }

  private async mergeCharts(): Promise<[number, number][]> {
    type MergeData = { data: { timestamp: number; value: number }[]; weight: number };

    const response = await this.productCharts();
    const items: MergeData[] = response.map((item) => {
      const product = this.portfolio.portfolio_products.find((p) => p.product.isin === item.meta.id);
      return {
        data: item.ts.map((seriesElement) => ({
          timestamp: seriesElement[0],
          value: seriesElement[1],
        })),
        weight: product ? (product.weight === null ? 0 : product.weight) : 0,
      };
    });

    // If all items have weight 0, set all to 1
    if (items.every((p) => p.weight === 0)) items.forEach((p) => (p.weight = 1));

    const weightSum = items.reduce((sum, item) => sum + item.weight, 0);

    const dictionary: { [key: string]: number[] } = {};

    items.forEach((product) => {
      product.data.forEach((data) => {
        if (dictionary[data.timestamp]) dictionary[data.timestamp].push(data.value * (product.weight / weightSum));
        else dictionary[data.timestamp] = [data.value * (product.weight / weightSum)];
      });
    });

    const merged = Object.entries(dictionary)
      .filter(([key, value]) => (key ? value.length === items.length : false))
      .map(([key, value]) => [parseInt(key, 10), value.reduce((sum, element) => sum + element)]);

    return merged as [number, number][];
  }
}
