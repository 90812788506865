var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("div", { staticClass: "ci-notice-bar text-sm" }, [
        _c("div", { staticClass: "ci-notice-bar__wrapper" }, [
          _c(
            "div",
            { staticClass: "row no-gutters align-items-center" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ci-notice-bar__content text-md-center text-left col-11 px-3",
                },
                [
                  _c("div", { staticClass: "text-body" }, [
                    _c("span", {
                      directives: [
                        {
                          name: "t",
                          rawName: "v-t",
                          value: _vm.messagePaths.hint,
                          expression: "messagePaths.hint",
                        },
                      ],
                    }),
                    _vm._v(" "),
                    _c(
                      "strong",
                      [
                        _c("ci-router-link", {
                          staticClass: "text-dark",
                          attrs: {
                            label: _vm.$t(_vm.messagePaths.back),
                            to: _vm.homeUrl,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c("ci-button", {
                staticClass: "text-center col-1 text-dark close",
                attrs: { "icon-class": "mdi-18px", icon: "mdi-close" },
                on: { click: _vm.close },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }