import { PluginObject } from "vue";

import VueSocialSharing from "vue-social-sharing";
import VueLimeClamp from "vue-line-clamp";
import InstantSearch from "vue-instantsearch";
import VueRouter from "vue-router";
import VueObserveVisibility from "vue-observe-visibility";
import { Portal, MountingPortal } from "portal-vue";
import PortalTargetExtended from "@components/plugins/portal-target.vue";
import { ValidationObserver } from "vee-validate";

export const CIVendorPlugin: PluginObject<boolean> = {
  install: (v) => {
    v.use(VueRouter);
    v.use(InstantSearch);
    v.use(VueLimeClamp);
    v.use(VueObserveVisibility);
    v.component("Portal", Portal);
    v.component("PortalTarget", PortalTargetExtended);
    v.component("MountingPortal", MountingPortal);
    v.component("validation-observer", ValidationObserver);
    v.use(VueSocialSharing, {
      networks: { custom_email: "mailto:?subject=@t&body=@d%0D%0A%0D%0A@u", custom_messaging: "" },
    });
  },
};
