import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/products/performance_alarms";
export class PerformanceAlarmApiService extends BaseApiService {
  public createFollowPerformanceAlarm(
    payload: PerformanceAlarms.Api.CreatePayload
  ): ApiRequest<{ performance_alarm: PerformanceAlarms.Details }> {
    return this.axios.post(API_ENDPOINT, payload);
  }

  public updatePerformanceAlarm(
    performanceAlarmId: number,
    payload: PerformanceAlarms.Api.UpdatePayload
  ): ApiRequest<{ performance_alarm: PerformanceAlarms.Details }> {
    return this.axios.patch(`${API_ENDPOINT}/${performanceAlarmId}`, payload);
  }

  public deletePerformanceAlarm(performanceAlarmId: number): ApiRequest<never> {
    return this.axios.delete(`${API_ENDPOINT}/${performanceAlarmId}`);
  }
}
