import { format as DateFnFormat, fromUnixTime } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { utilityService } from "./utility.service";

type AverageDateTypes = Date | string | number;

class Service {
  private get locale() {
    return ["de", "de-DE"].includes(utilityService.currentLocale) ? de : enGB;
  }

  format(format: string, date?: AverageDateTypes): string {
    let dateFormat = format;

    if (format === "medium") {
      dateFormat = ["de", "de-DE"].includes(utilityService.currentLocale) ? "dd.MM.yyyy" : "dd MMM yyyy";
    }

    const source = this.createDateObject(date);
    return DateFnFormat(source, dateFormat, { locale: this.locale });
  }
  formatTime(format: string, date?: AverageDateTypes): string {
    let dateFormat = format;

    if (format === "short") {
      dateFormat = "H:mm";
    } else if (format === "medium") {
      dateFormat = "HH:mm";
    } else if (format === "long") {
      dateFormat = "HH:mm:ss";
    }

    const source = this.createDateObject(date);
    return DateFnFormat(source, dateFormat, { locale: this.locale });
  }

  private createDateObject(date?: AverageDateTypes): Date {
    if (!date) return new Date();
    if (typeof date === "string") return new Date(date); // considered formatted date string
    if (typeof date === "number") return fromUnixTime(date);
    if (typeof date === "object") return new Date(date.getTime()); // considered to be a date object
    throw new Error(
      "dateservice: passed date has to be a formatted datetime string, a unix timestamp or a date object"
    );
  }
}

export const dateService = new Service();
