
import { Vue, Component } from "vue-property-decorator";
import { apiService } from "@services/api.service";
import { CreateToast } from "@utils/toasts";
import { debounce } from "throttle-debounce";
import { Constants } from "@app/constants";

@Component({
  name: "ci-share-content-via-messaging",
})
export default class extends Vue {
  public visible: boolean = false;
  public type: string = "product";
  public message: string = "";
  public searchTerm: string = "";
  public selectedUsers: Users.Search[] = [];
  public fetchedUsers: Users.Search[] = [];
  public shareUrl: string = "";
  public sending: boolean = false;
  public errorText: Nullable<string> = null;
  public conversationLimit: Nullable<number> = null;
  public maxConversationsPerWeek = Constants.maxConversationsPerWeek;

  public debouncedUserSearch = debounce(
    Constants.defaultDelay,
    (search: string, loading: ExplicitAny, vm: ExplicitAny) => {
      loading(true);
      vm.fetchedUsers.length = 0;
      apiService.users
        .all(`${search}&source=conversations`)
        .then((response) => {
          vm.fetchedUsers = response.data.users;
        })
        .catch(apiService.handleAPIError)
        .finally(() => {
          loading(false);
        });
    }
  );

  public get hasConversationLimit(): boolean {
    return this.conversationLimit !== null;
  }

  public get maxUsersReached(): boolean {
    const limit = this.conversationLimit === null ? Constants.maxConversationsPerWeek : this.conversationLimit;
    return this.selectedUsers.length >= limit;
  }

  public selectUser(user: Users.Search): void {
    if (this.selectedUsers.every((addedUser) => addedUser.id !== user.id)) {
      this.selectedUsers.push(user);
      this.fetchedUsers.length = 0;
      this.errorText = null;
    } else {
      this.errorText = "ui.components.messaging.share_content.recipient_already_added";
    }
  }

  public async searchUser(search: string, loading: (result: boolean) => void): Promise<void> {
    this.debouncedUserSearch(search, loading, this);
  }

  public deleteUser(user: Users.Search): void {
    this.selectedUsers = this.selectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
  }

  public async fetchConversationLimit(): Promise<void> {
    try {
      const response = await apiService.conversations.limit();
      this.conversationLimit = response.data.limit;
    } catch (err) {
      apiService.handleAPIError(err);
    }
  }

  public onShow(): void {
    this.fetchConversationLimit();
    this.reset();
  }

  public cancel(): void {
    this.hide();
    this.reset();
  }

  public async sendMessage(): Promise<void> {
    this.sending = true;
    try {
      await Promise.all(
        this.selectedUsers.map((user) =>
          apiService.conversations.createConversation(user.id, this.createMessageWithLink())
        )
      );
      CreateToast.success(
        this.$t("ui.components.messaging.share_content.sending_succeeded", {
          recipients: this.selectedUsers.map((user) => ` ${user.name}`).join(","),
        }) as string
      );
      this.hide();
      this.reset();
      this.sending = false;
    } catch (err) {
      apiService.handleAPIError(err);
      this.sending = false;
    }
  }

  protected mounted(): void {
    this.$root.$on("share-via-custom_messaging", (data: any) => {
      this.visible = true;
      this.shareUrl = data.url;
      this.type = data.type;
    });
  }

  private createMessageWithLink(): string {
    const message = `${this.message} \n\n ${this.shareUrl}`;
    return message;
  }

  private hide(): void {
    this.visible = false;
  }

  private reset(): void {
    this.message = "";
    this.searchTerm = "";
    this.selectedUsers = [];
    this.fetchedUsers = [];
  }
}
