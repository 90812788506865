import { BaseApiService } from "./base";

const ARTICLES_API_ENDPOINT = "/api/v4/articles";
const CONTENTS_API_ENDPOINT = "/api/v4/contents";

export class ArticlesApiService extends BaseApiService implements ArticlesApi.Service {
  public series: ArticlesApi.SeriesFn = (id) => this.axios.get(`${ARTICLES_API_ENDPOINT}/series/${id}`);

  public getContent = (id: string, key: Nullable<string>) =>
    this.axios.get<Contents.Content>(`${CONTENTS_API_ENDPOINT}/${id}${key !== undefined ? "?key=" + key : ""}`);

  public getRelatedArticles: ArticlesApi.GetRelatedArticlesFn = (id) =>
    this.axios.get(`${CONTENTS_API_ENDPOINT}/${id}/related_contents`);

  public getRelatedProducts: ArticlesApi.GetRelatedProductsFn = (id) =>
    this.axios.get<{ products: Products.TileData[] }>(`${CONTENTS_API_ENDPOINT}/${id}/related_products`);
}
