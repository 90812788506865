import Vue, { PluginObject } from "vue";

import { Parameterizer } from "@utils/parameterizer";
import { store } from "@stores";
import { settingsService } from "@services/settings.service";
import { Sanitizer } from "@utils/sanitizer";
import { CurrentUser } from "@utils/user";
import { ValidationSetup } from "@utils/validation-setup";
import { ROUTENAMES } from "@models/constants/routes";
import { Emitter } from "@utils/events";
import { Loader, useLoader } from "@composables/loader";

export const CISetupPlugin: PluginObject<boolean> = {
  install: (v) => {
    const loader = useLoader();

    ValidationSetup();

    Emitter.on("ci::loader::start", () => {
      loader.start();
    });

    Emitter.on("ci::loader::stop", () => {
      loader.stop();
    });

    v.prototype.$emitter = Emitter;
    v.prototype.$loading = Loader.loading;
    v.prototype.$page = Vue.observable({
      context: document.querySelector("meta[name='page-mode']")?.getAttribute("content") || "mpa",
    });
    v.prototype.$parameterize = Parameterizer;
    v.prototype.$routes = ROUTENAMES;
    v.prototype.$sanitize = Sanitizer;
    v.prototype.$settings = settingsService;
    v.prototype.$store = store;
    v.prototype.$user = CurrentUser;
  },
};
