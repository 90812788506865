
import { Emitter } from "@utils/events";
import Userpilot from "@utils/userpilot";
import { settingsService } from "@services/settings.service";
import { Component, Vue } from "vue-property-decorator";
import { ciTrackingService } from "@services/ci-tracking.service";

@Component({
  name: "ci-header-nav-user",
})
export default class extends Vue {
  public userImage = settingsService.options.user.avatar;

  public handleLogoutClick(identifier: "logout"): void {
    Userpilot.resetUser(settingsService.options.user);
    this.trackUserNavClick(identifier);
  }

  public trackUserNavClick(identifier: "user-profile" | "admin" | "settings" | "faq" | "logout"): void {
    switch (identifier) {
      case "user-profile":
        this.trackClick(
          identifier,
          this.$router.resolve({ name: this.$routes.USERS_SHOW, params: { slug: this.$user.slug } }).route.fullPath
        );
        break;
      case "admin":
        this.trackClick(identifier, "/admin");
        break;
      case "settings":
        this.trackClick(identifier, this.$router.resolve({ name: this.$routes.USERS_SETTINGS }).route.fullPath);
        break;
      case "faq":
        this.trackClick(identifier, "/w/faq");
        break;
      case "logout":
        this.trackClick(identifier, undefined);
        break;
      default:
    }
  }

  protected mounted(): void {
    Emitter.on("ci::user::avatar-changed", (image: string) => {
      this.userImage = image;
    });
  }

  private trackClick(targetIdentifier: string, targetUrl: string | undefined): void {
    ciTrackingService.trackClick("ButtonOnMenuBar", targetIdentifier, null, targetUrl);
  }
}
