
import { computed, defineComponent, getCurrentInstance, PropType } from "vue";

export default defineComponent({
  name: "CiSortableTableHeader",
  props: {
    order: { type: String as PropType<"asc" | "desc">, required: false, default: "asc" },
    name: { type: String, required: false, default: "" },
    clickHandlerAsc: { type: Function as PropType<() => void>, required: false, default: null },
    clickHandlerDesc: { type: Function as PropType<() => void>, required: false, default: null },
    active: { type: Boolean, required: false, default: false },
    hideSortButton: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const root = getCurrentInstance()?.proxy;
    const hasDefaultSlot = computed(() => !!root?.$slots.default);

    const sort = () => {
      if (props.hideSortButton) {
        if (props.order === "asc") props.clickHandlerAsc();
        else props.clickHandlerDesc();
      }
    };

    return {
      hasDefaultSlot,
      sort,
    };
  },
});
