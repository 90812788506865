
import { apiService } from "@services/api.service";
import { computed, defineComponent, getCurrentInstance } from "vue";
import { settingsService } from "@services/settings.service";
import { useI18n } from "@composables/i18n";
import { useLoader } from "@composables/loader";
import { CreateToast } from "@utils/toasts";
import { CurrentUser } from "@utils/user";
import InlineSvg from "vue-inline-svg";
import { Subscribe } from "@pictograms";

export default defineComponent({
  name: "CiConfirmationReminderModal",
  components: {
    "inline-svg": InlineSvg,
  },
  setup() {
    const loader = useLoader();
    const { t } = useI18n(getCurrentInstance());
    const mailEnvelope: ImportedSVG = Subscribe;

    const distanceTimeWords = computed(() => CurrentUser.confirmation_remaining_time_days!);

    const resendConfirmationMail = async () => {
      try {
        loader.start();
        await apiService.axios.get(settingsService.options.registration.resend_email_path);
        CreateToast.success(t("ui.components.users.onboarding.register.resent_email") as string);
      } catch (err) {
        CreateToast.error(t("error_occurred") as string);
      } finally {
        loader.stop();
      }
    };

    return { distanceTimeWords, mailEnvelope, resendConfirmationMail };
  },
});
