import { buildURL } from "@utils/build-url";
import { BaseApiService } from "./base";

const API_ENDPOINT = "/api/v4/users/product_comparisons";

export class ComparisonsApiService extends BaseApiService implements ComparisonsApi.Service {
  public all: ComparisonsApi.AllFn = (params = {}) => this.axios.get(API_ENDPOINT, { params });

  public get: ComparisonsApi.GetFn = (id) => {
    const url = buildURL(API_ENDPOINT, { path: [id] });
    return this.axios.get(url);
  };

  public create: ComparisonsApi.CreateFn = (payload) => this.axios.post(API_ENDPOINT, payload);

  public duplicate: ComparisonsApi.DuplicateFn = (id) => {
    const url = buildURL(API_ENDPOINT, { path: [id.toString(), "duplicate"] });
    return this.axios.post(url);
  };

  public update: ComparisonsApi.UpdateFn = (id, payload) => {
    const url = buildURL(API_ENDPOINT, { path: [id.toString()] });
    return this.axios.patch(url, payload);
  };

  public delete: ComparisonsApi.DeleteFn = (id) => {
    const url = buildURL(API_ENDPOINT, { path: [id.toString()] });
    return this.axios.delete(url);
  };

  public open: ComparisonsApi.OpenFn = (id) => this.get(id);
}
