var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ci-header__nav-primary", class: { open: _vm.navExpanded } },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scrollShadow.top,
            expression: "scrollShadow.top",
          },
        ],
        staticClass: "scroll-shadow top",
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.scrollShadow.bottom,
            expression: "scrollShadow.bottom",
          },
        ],
        staticClass: "scroll-shadow bottom",
      }),
      _c(
        "ul",
        {
          staticClass:
            "ci-header__nav-list list-unstyled mb-0 d-lg-flex ci-content-padding-as-body ml-md-n1 flex-grow-1",
          class: { "signed-in": _vm.$user.signedIn },
          on: { scroll: _vm.handleThrottledScroll },
        },
        _vm._l(_vm.items, function (item) {
          return _c("ci-nav-item", {
            key: item.id,
            staticClass: "mx-lg-1",
            attrs: { item: item, drop: _vm.addDropdownArrow(item) },
            on: {
              click: function ($event) {
                return _vm.trackNavItemClick(item)
              },
            },
          })
        }),
        1
      ),
      !_vm.$user.signedIn
        ? _c("div", { staticClass: "d-lg-none p-3 p-md-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("ci-button", {
                    staticClass: "w-100",
                    attrs: {
                      id: "nav-register-handheld",
                      to: {
                        path: "/users/sign_up",
                        query: { onboarding_type: "menubar" },
                      },
                      label: _vm.$t("ui.shared.navigation.misc.register"),
                      variant: "filled-primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.trackNavClick("register")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("ci-button", {
                    staticClass: "w-100",
                    attrs: {
                      id: "nav-login-handheld",
                      gated: true,
                      "show-gating-tooltip": false,
                      label: _vm.$t("ui.shared.navigation.misc.login"),
                      variant: "outline-white",
                    },
                    on: { click: _vm.openLoginModal },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }