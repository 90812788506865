// Takes the datefns locale and overrides the formatDistance function to use
// shorter strings than usual

import { de } from "date-fns/locale";

const formatDistanceLocale: Indexable = {
  lessThanXSeconds: {
    standalone: {
      one: "< 1 Sek.",
      other: "< {{count}} Sek.",
    },
    withPreposition: {
      one: "< 1 Sek.",
      other: "< {{count}} Sek.",
    },
  },
  xSeconds: {
    standalone: {
      one: "1 Sek.",
      other: "{{count}} Sek.",
    },
    withPreposition: {
      one: "1 Sek.",
      other: "{{count}} Sek.",
    },
  },
  halfAMinute: {
    standalone: "30 Sek.",
    withPreposition: "30 Sek.",
  },
  lessThanXMinutes: {
    standalone: {
      one: "< 1 Min.",
      other: "< {{count}} Min.",
    },
    withPreposition: {
      one: "< 1 Min.",
      other: "< {{count}} Min.",
    },
  },
  xMinutes: {
    standalone: {
      one: "1 Min.",
      other: "{{count}} Min.",
    },
    withPreposition: {
      one: "1 Min.",
      other: "{{count}} Min.",
    },
  },
  aboutXHours: {
    standalone: {
      one: "1 Std.",
      other: "{{count}} Std.",
    },
    withPreposition: {
      one: "1 Std.",
      other: "{{count}} Std.",
    },
  },
  xHours: {
    standalone: {
      one: "1 Std.",
      other: "{{count}} Std.",
    },
    withPreposition: {
      one: "1 Std.",
      other: "{{count}} Std.",
    },
  },
  xDays: {
    standalone: {
      one: "1 Tg.",
      other: "{{count}} Tg.",
    },
    withPreposition: {
      one: "1 Tg.",
      other: "{{count}} Tg.",
    },
  },
  aboutXWeeks: {
    standalone: {
      one: "1 Wo.",
      other: "{{count}} Wo.",
    },
    withPreposition: {
      one: "1 Wo.",
      other: "{{count}} Wo.",
    },
  },
  xWeeks: {
    standalone: {
      one: "1 Wo.",
      other: "{{count}} Wo.",
    },
    withPreposition: {
      one: "1 Wo.",
      other: "{{count}} Wo.",
    },
  },
  aboutXMonths: {
    standalone: {
      one: "1 Mon.",
      other: "{{count}} Mon.",
    },
    withPreposition: {
      one: "1 Mon.",
      other: "{{count}} Mon.",
    },
  },
  xMonths: {
    standalone: {
      one: "1 Mon.",
      other: "{{count}} Mon.",
    },
    withPreposition: {
      one: "1 Mon.",
      other: "{{count}} Mon.",
    },
  },
  aboutXYears: {
    standalone: {
      one: "1 J.",
      other: "{{count}} J.",
    },
    withPreposition: {
      one: "1 J.",
      other: "{{count}} J.",
    },
  },
  xYears: {
    standalone: {
      one: "1 J.",
      other: "{{count}} J.",
    },
    withPreposition: {
      one: "1 J.",
      other: "{{count}} J.",
    },
  },
  overXYears: {
    standalone: {
      one: "> 1 J.",
      other: "> {{count}} J.",
    },
    withPreposition: {
      one: "> 1 J.",
      other: "> {{count}} J.",
    },
  },
  almostXYears: {
    standalone: {
      one: "~ 1 J.",
      other: "~ {{count}} J.",
    },
    withPreposition: {
      one: "~ 1 J.",
      other: "~ {{count}} J.",
    },
  },
};

const formatDistance = function (token: any, count: any, options: any) {
  let result;
  const tokenValue =
    options !== null && options !== void 0 && options.addSuffix
      ? formatDistanceLocale[token].withPreposition
      : formatDistanceLocale[token].standalone;

  if (typeof tokenValue === "string") {
    result = tokenValue;
  } else if (count === 1) {
    result = tokenValue.one;
  } else {
    result = tokenValue.other.replace("{{count}}", String(count));
  }

  if (options !== null && options !== void 0 && options.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return "in " + result;
    } else {
      return "vor " + result;
    }
  }

  return result;
};

export default { ...de, formatDistance };
