import { DirectiveOptions } from "vue";

const directive: DirectiveOptions = {
  bind(element, _, vnode) {
    vnode.context!.$nextTick(() => {
      element.classList.add(element.scrollWidth > element.clientWidth ? "truncated" : "fitting");
    });
  },
};

export default directive;
