
import { defineComponent, PropType } from "vue";
import NotificationWrapper from "./wrapper.vue";
import { ROUTENAMES } from "@models/constants/routes";

export default defineComponent({
  components: {
    NotificationWrapper,
  },
  props: {
    items: {
      type: Array as PropType<Notifications.MappedItem[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    handheldStyle: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String as PropType<"center" | "popup">,
      required: true,
    },
    type: {
      type: String as PropType<"all" | "unread">,
      default: "all",
    },
    category: {
      type: String as PropType<Notifications.Category>,
      default: "",
    },
    buttons: {
      type: Array as PropType<{ labelKey: string; to: RawLocation; variant?: string }[]>,
      default: () => [
        {
          labelKey: "ui.components.base.emptystate.notifications.buttons.edit_interests",
          to: { name: ROUTENAMES.USERS_SETTINGS, query: { tab: "my_interests" } },
        },
      ],
    },
  },
});
