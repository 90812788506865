
import { Component, Prop } from "vue-property-decorator";
import { FormElementBase } from "./base";
import { ValidationProvider } from "vee-validate";

@Component({
  name: "ci-checkbox",
  components: { ValidationProvider },
})
export default class extends FormElementBase {
  @Prop({ default: false }) public value!: boolean;
  @Prop({ default: false }) public noSpacing!: boolean;
  @Prop({ default: false }) public toggle!: boolean;
  @Prop({ default: true }) public showAsteriks!: boolean;
  @Prop({ default: true }) public fieldValue!: string | boolean;

  public get noLabel(): boolean {
    return this.label.length === 0;
  }

  public get required(): boolean {
    if (this.rules && this.rules.required) return this.rules.required === true;
    return false;
  }

  public handleChange() {
    this.$emit("change", this.internalValue);
    this.handleInput();
  }
  public handleInput() {
    this.$emit("input", this.internalValue);
  }
}
