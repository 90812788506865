import sanitizeHTML from "sanitize-html";

const sanitizeOptions: Indexable<sanitizeHTML.IOptions> = {
  default: {
    allowedTags: [...sanitizeHTML.defaults.allowedTags, "span"],
    allowedAttributes: {
      ...sanitizeHTML.defaults.allowedAttributes,
      span: ["class"],
    },
  },
  curated: {
    allowedTags: [...sanitizeHTML.defaults.allowedTags, "span", "img", "h2", "iframe"],
    allowedAttributes: {
      ...sanitizeHTML.defaults.allowedAttributes,
      span: ["id", "class", "style"],
      img: ["id", "src", "height", "width", "class", "style"],
      p: ["id", "class", "style"],
      a: ["style", "href"],
      h1: ["style"],
      h2: ["style"],
      h3: ["style"],
      h4: ["style"],
      h5: ["style"],
      h6: ["style"],
      iframe: ["width", "height", "src", "frameborder", "allowfullscreen", "class"],
    },
  },
};

export const Sanitizer = (dirty: string, schema: string = "default"): string =>
  sanitizeHTML(dirty, sanitizeOptions[schema] || sanitizeOptions.default);
