var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      attrs: {
        id: "share-content-via-messaging",
        visible: _vm.visible,
        title: _vm.$t(
          "ui.components.messaging.share_content.types." + _vm.type + ".title"
        ),
        "hide-footer": "",
      },
      on: { hidden: _vm.cancel, show: _vm.onShow },
    },
    [
      _c("ci-textarea", {
        staticClass: "mb-3",
        attrs: {
          id: "message",
          label: _vm.$t("ui.components.messaging.share_content.message_label"),
        },
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
      _vm.hasConversationLimit
        ? _c(
            "div",
            { staticClass: "d-flex mb-3", attrs: { id: "limit-info" } },
            [
              _c("div", { staticClass: "mdi mdi-information pr-2" }),
              _c("div", {
                staticClass: "text-left text-muted",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("ui.components.messaging.share_content.limit_info", {
                      remaining: _vm.conversationLimit,
                      limit: _vm.maxConversationsPerWeek,
                    })
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "ci-fe-wrapper",
        {
          staticClass: "mb-3",
          attrs: {
            id: "user-search",
            label: _vm.$t(
              "ui.components.messaging.share_content.add_recipient"
            ),
            value: _vm.searchTerm,
            disabled: _vm.maxUsersReached,
            "leading-icon": "mdi-plus",
          },
        },
        [
          _c("v-select", {
            staticClass: "ci-form--input",
            attrs: {
              label: "name",
              options: _vm.fetchedUsers,
              filterable: false,
              value: _vm.searchTerm,
              disabled: _vm.maxUsersReached,
              "data-cy": "usersearch",
            },
            on: { input: _vm.selectUser, search: _vm.searchUser },
            scopedSlots: _vm._u([
              {
                key: "open-indicator",
                fn: function ({ attributes }) {
                  return [
                    _c(
                      "span",
                      _vm._b(
                        { staticClass: "mdi mdi-chevron-down text-lg" },
                        "span",
                        attributes,
                        false
                      )
                    ),
                  ]
                },
              },
              {
                key: "option",
                fn: function (option) {
                  return [_c("span", [_vm._v(_vm._s(option.name))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.errorText
        ? _c("div", { staticClass: "text-center my-2" }, [
            _c("span", {
              directives: [
                {
                  name: "t",
                  rawName: "v-t",
                  value: _vm.errorText,
                  expression: "errorText",
                },
              ],
              staticClass: "text-danger",
            }),
          ])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "recipients list-unstyled" },
        _vm._l(_vm.selectedUsers, function (user) {
          return _c(
            "li",
            {
              key: user.id,
              staticClass: "d-flex align-items-center py-3 border-bottom",
            },
            [
              _c("ci-user-avatar", {
                staticClass: "mr-3 flex-shrink-0",
                attrs: { src: user.avatar_url, username: user.firstname },
              }),
              _c("strong", {
                staticClass: "flex-grow-1",
                domProps: { textContent: _vm._s(user.name) },
              }),
              _c("ci-button", {
                staticClass: "flex-shrink-0 delete-recipient",
                attrs: { variant: "ghost-dark", icon: "mdi-close" },
                on: {
                  click: function ($event) {
                    return _vm.deleteUser(user)
                  },
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between pt-4" },
        [
          _c("ci-button", {
            staticClass: "cancel mr-2",
            attrs: {
              variant: "filled-light",
              label: _vm.$t("ui.shared.global.cancel"),
            },
            on: { click: _vm.cancel },
          }),
          _c("ci-button", {
            staticClass: "send",
            attrs: {
              variant: "filled-primary",
              icon: _vm.sending ? "mdi-loading mdi-spin" : "",
              label: _vm.$t("send"),
              disabled: _vm.selectedUsers.length < 1 || _vm.sending,
            },
            on: { click: _vm.sendMessage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }