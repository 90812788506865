import VueI18n from "vue-i18n";
import i18n from "@utils/i18n/i18n.instance";
export { i18nLoader } from "@utils/i18n/i18n.loader";

export const i18nInstance = i18n;

// simulated vue i18n v9 composable, until we are able to update

type SimulatedVueI18nComposable = (instance?: { proxy: Vue } | null) => {
  t(key: VueI18n.Path, values?: VueI18n.Values): VueI18n.TranslateResult;
  tc(key: VueI18n.Path, count: number, values?: VueI18n.Values): VueI18n.TranslateResult;
};

export const useI18n: SimulatedVueI18nComposable = (instance) => ({
  tc: (key: string, count: number, values = {}) => {
    if (instance) return instance?.proxy.$i18n.tc(key, count, values);
    return i18nInstance.tc(key, count, values);
  },
  t: (key: string, values = {}) => {
    if (instance) return instance?.proxy.$i18n.t(key, values);
    return i18nInstance.t(key, values);
  },
});
