import parameterize from "parameterize-string";

export const Parameterizer = (
  value: string | null,
  separator: string = "-",
  preserveCase: boolean = false
): string | null => {
  if (value) return parameterize(value, { separator: separator, preserveCase: preserveCase });
  return null;
};
