import Vue from "vue";

const loading = Vue.observable({
  slow: false,
  state: false,
});
const error = Vue.observable({ state: null as Nullable<RouterError> });

export const Loader = {
  loading,
  error,
};

export const useLoader = () => ({
  start: (options: Indexable = { slow: false }) => {
    error.state = null;
    loading.state = true;
    if (options.slow) loading.slow = true;
  },
  stop: () => {
    loading.state = false;
    loading.slow = false;
  },
  setError: (err: ExplicitAny) => {
    loading.state = false;
    loading.slow = false;
    error.state = err;
  },
});
