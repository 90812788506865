import { CurrentUser } from "@utils/user";
import { apiService } from "@services/api.service";
import { CreateToast } from "@utils/toasts";
import { computed, Ref, ref } from "vue";
import { viewportService } from "@services/viewport.service";
import { ciTrackingService } from "@services/ci-tracking.service";

const conversationLimit: Ref<Nullable<number>> = ref(null);
const hasUnreadMessages = ref(false);
const unfilteredConversations: Ref<Conversations.Conversation[]> = ref([]);
const currentConversation: Ref<Nullable<Conversations.Conversation>> = ref(null);
const messages: Ref<Conversations.Message[]> = ref([]);
const meta = ref({
  loading: {
    conversations: false,
    messages: false,
  },
  messages: {
    page: 1,
    end_reached: false,
  },
});
const initialized = ref(false);
const existingConversationUserIds = computed(() => conversations.value.map((c) => c.recipient.id));
const conversations = computed(() => unfilteredConversations.value.filter((conversation) => !conversation.deleted));

const checkUnreadStatus = async () => {
  try {
    const response = await apiService.conversations.status();
    hasUnreadMessages.value = response.data.status;
  } catch (err) {
    console.error(err);
  }
};

const checkForExistingConversationWithRecipient = async (id: number): Promise<void> =>
  new Promise((resolve, reject) => {
    const target = unfilteredConversations.value.find((conversation) => conversation.recipient.id === id);

    if (target) {
      target.deleted = false;
      openConversation(target.id!);
      resolve();
    } else reject(new Error("no existing conversation found"));
  });

const openConversation = async (id: number, initialLoad = false) => {
  unfilteredConversations.value = unfilteredConversations.value.filter((c) => !c.composing);
  currentConversation.value = null;
  messages.value = [];
  meta.value.messages.page = 0;
  meta.value.messages.end_reached = false;
  const target = unfilteredConversations.value.find((c) => c.id === id);
  if (target && !target.deleted) {
    currentConversation.value = target;
    try {
      await loadMessages();
      currentConversation.value.unread = false;
      await ciTrackingService.trackView(
        "Conversation",
        target.id!.toString(),
        initialLoad ? null : "ConversationOverview"
      );
    } catch (err: any) {
      CreateToast.error(err.message);
    }
  }

  await checkUnreadStatus();
};

const loadMessages = async () => {
  if (!meta.value.messages.end_reached) {
    try {
      meta.value.loading.messages = true;
      meta.value.messages.page += 1;
      const response = await apiService.conversations.conversationMessages(
        currentConversation.value!.id!,
        meta.value.messages.page
      );

      if (response.data.meta) {
        meta.value.messages.end_reached = response.data.meta.next_page === null;
      }

      messages.value.unshift(...response.data.messages.reverse());
    } catch (err: any) {
      CreateToast.error(err.message);
    } finally {
      meta.value.loading.messages = false;
    }
  }
};

const closeConversation = () => {
  unfilteredConversations.value = unfilteredConversations.value.filter((c) => !c.composing);

  currentConversation.value = null;
};

const loadConversations = async (page?: number) => {
  try {
    meta.value.loading.conversations = true;
    const response = await apiService.conversations.conversations(page);

    if (!page || page === 1) {
      unfilteredConversations.value = [];
    }

    unfilteredConversations.value.push(...response.data.conversations);
    meta.value.loading.conversations = false;

    if (response.data.meta?.next_page && !meta.value.loading.conversations) {
      await loadConversations(response.data.meta.next_page);
    }
  } catch (err: any) {
    CreateToast.error(err);
  } finally {
    meta.value.loading.conversations = false;
  }
};

const loadConversationLimit = async () => {
  try {
    const response = await apiService.conversations.limit();
    conversationLimit.value = response.data.limit;
  } catch (err) {
    apiService.handleAPIError(err);
  }
};

const initialize = async (initialConversationId?: number) => {
  try {
    await loadConversationLimit();
    await loadConversations();

    if (conversations.value.length > 0 && (viewportService.check(["lg", "xl", "xxl"]) || initialConversationId)) {
      await openConversation(initialConversationId || conversations.value[0].id!, true);
    } else {
      await ciTrackingService.trackView("ConversationOverview", "unused");
    }

    initialized.value = true;
  } catch (err) {
    console.error(err);
  }
};

const createAndOpenComposableConversation = (recipient: UsersV2.Tile) => {
  const conversation = {
    users: [CurrentUser, recipient],
    last_updated_at: "",
    unread_messages: 0,
    unread: false,
    deleted: false,
    recipient,
    composing: true,
  } as Conversations.Conversation;

  messages.value = [];

  unfilteredConversations.value.unshift(conversation);

  currentConversation.value = conversation;

  return conversation;
};

const createConversation = async (
  text: string,
  recipientId?: number
): Promise<Nullable<Conversations.Conversation>> => {
  try {
    const response = await apiService.conversations.createConversation(
      recipientId || currentConversation.value!.recipient.id,
      text
    );
    const { conversation } = response.data;
    loadConversationLimit();

    if (recipientId) return conversation;

    const composableConversation = unfilteredConversations.value.find((c) => c.composing);

    if (conversation && composableConversation) {
      composableConversation.id = conversation.id;
      composableConversation.composing = false;
    }

    await openConversation(conversation.id!);
    return conversation;
  } catch (err: any) {
    apiService.handleAPIError(err);
    return err;
  }
};

const createMessage = async (conversationId: number, text: string) => {
  try {
    const response = await apiService.conversations.createMessage(conversationId, text);

    if (currentConversation.value) {
      messages.value.push(response.data.message);
    }
  } catch (err: any) {
    CreateToast.error(err);
  }
};

const destroyConversation = async (id: number) => {
  try {
    await apiService.conversations.destroyConversation(id!);

    closeConversation();
    unfilteredConversations.value = unfilteredConversations.value.filter((c) => c.id !== id);
  } catch (err: any) {
    CreateToast.error(err);
  }
};

export const useConversations = () => ({
  initialize,
  hasUnreadMessages,
  conversations,
  currentConversation,
  messages,
  checkUnreadStatus,
  checkForExistingConversationWithRecipient,
  createConversation,
  createMessage,
  openConversation,
  existingConversationUserIds,
  unfilteredConversations,
  createAndOpenComposableConversation,
  closeConversation,
  destroyConversation,
  loadMessages,
  conversationLimit,
  initialized,
  meta,
  loadConversations,
});
