import { createConsumer } from "@rails/actioncable";
import rfdc from "rfdc";
import TimeMe from "../vendor/timeme";
import { settingsService } from "@services/settings.service";

class Service implements Utility.Service {
  public app = {
    cable: createConsumer(),
  };

  public storage = window.localStorage;
  public timeme: Nullable<ExplicitAny> = TimeMe;

  public get currentLocale(): string {
    return settingsService.options.locale;
  }

  public guid: Utility.GuidFn = () => {
    const chunk = (): string =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${chunk()}${chunk()}-${chunk()}-${chunk()}-${chunk()}-${chunk()}-${chunk()}${chunk()}${chunk()}`;
  };

  /*
   * Takes a number or null and formats them to a percentage value
   * Numbers will be rounded to two decimal points,
   * but values which would be rounded to 0.00 will return 0% instead
   * examples
   * value: 12.1234 returns: 12.12%
   * value: 0.058 return: 0.06%
   * value: 0.001 returns: 0%
   * Null will returns: -
   */
  public formatPercentage: Utility.FormatPercentageFn = (value, delimiter = "") => {
    let val = value;
    if (typeof val === "string") val = parseFloat(val);
    if (!val || isNaN(val)) return "-";

    const decimals = 2;
    const threshold = 1 / Math.pow(10, decimals);
    const rounded = this.round(val, decimals) as number;
    if (Math.abs(rounded) < threshold) return `0${delimiter}%`;

    return `${val.toLocaleString(this.currentLocale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}${delimiter}%`;
  };

  public formatNumber: Utility.FormatNumberFn = (value, decimals) => {
    let val = value;
    if (typeof val === "string") val = parseFloat(val);
    if (val === undefined || val === null || isNaN(val)) return "-";

    const rounded = this.round(val, decimals);
    return rounded === 0 ? "0" : rounded.toLocaleString(this.currentLocale);
  };

  public getPercentageColor: Utility.GetPercentageColorFn = (percentage) => {
    const parsed = parseFloat(percentage as string);
    return {
      "text-primary": isNaN(parsed) ? false : parsed > 0,
      "text-danger": isNaN(parsed) ? false : parsed < 0,
    };
  };

  public round: Utility.RoundWithDecimalsFn = (value, decimals = 2, toString = false) => {
    let multiplier = decimals === 0 ? 1 : 10;
    for (let i = 1; i < decimals; i++) {
      multiplier = multiplier * 10;
    }
    const result = Math.round(value * multiplier) / multiplier;

    return toString ? result.toFixed(decimals) : result;
  };

  public searchToObject: Utility.SearchToObjectFn = (searchString) =>
    new Promise((resolve) => {
      const pairs = searchString.substring(1).split("&");
      const obj: Indexable<string> = {};
      let pair;
      let i;

      for (i in pairs) {
        if (pairs[i] === "") continue;
        pair = pairs[i].split("=");
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }

      resolve(obj);
    });

  public listenForModalRequest: Utility.ListenForModalRequestFn = (vue) => {
    this.searchToObject(location.search).then((search) => {
      // only try to open "known" modals
      if (search.modal && Object.values(vue.$root.$bvModalList.ids).some((modal) => modal === search.modal)) {
        vue.$root.$emit("bv::show::modal", search.modal);
      }
    });
  };

  public startTimeMe: Utility.StartTimeMeFn = () => {
    this.timeme.initialize({
      currentPageName: window.location.href,
      idleTimeoutInSeconds: 30,
      websocketOptions: { appId: "capinside" },
    });
  };

  public isInViewport: Utility.IsInViewportFn = (element) => {
    const viewport = {
      top: window.pageYOffset,
      left: window.pageXOffset,
      right: window.innerWidth + window.pageXOffset,
      bottom: window.innerHeight + window.pageYOffset,
    };

    const offset = {
      top: element.getBoundingClientRect().top + window.pageYOffset,
      left: element.getBoundingClientRect().left + window.pageXOffset,
    };

    const bounds = {
      ...offset,
      right: offset.left + element.offsetWidth,
      bottom: offset.top + element.offsetHeight,
    };

    return !(
      viewport.right < bounds.left ||
      viewport.left > bounds.right ||
      viewport.bottom < bounds.top ||
      viewport.top > bounds.bottom
    );
  };

  public isVisible: Utility.IsVisibleFn = (element) => this.isInViewport(element) && element.offsetParent !== null;

  /**
   * Takes an object of type Date, Array, Object or primitive such as numbers strings
   * and creates a copy of it
   * @param instance Date | Array | Object | number | string | null
   * @returns copy of object
   */
  public deepCopy<T>(instance: T): T {
    return rfdc()(instance);
  }
}

export const utilityService = new Service();
