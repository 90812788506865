var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ci-follow" },
    [
      _vm.followed
        ? _vm._t(
            "active",
            function () {
              return [
                _c("ci-button", {
                  attrs: {
                    icon: "mdi-bookmark",
                    variant: _vm.variant,
                    size: _vm.buttonSize,
                    gated: true,
                    "gating-type": "login",
                    tooltip: _vm.withLabel
                      ? ""
                      : _vm.$t("ui.shared.global.unfollow"),
                    label: _vm.withLabel
                      ? _vm.$t("ui.shared.global.unfollow")
                      : "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.unfollow.apply(null, arguments)
                    },
                  },
                }),
              ]
            },
            { follow: _vm.follow, unfollow: _vm.unfollow }
          )
        : _vm._t(
            "inactive",
            function () {
              return [
                _c("ci-button", {
                  attrs: {
                    icon: "mdi-bookmark-outline",
                    variant: _vm.variant,
                    size: _vm.buttonSize,
                    gated: true,
                    "gating-type": "login",
                    tooltip: _vm.withLabel
                      ? ""
                      : _vm.$t("ui.shared.global.follow"),
                    label: _vm.withLabel
                      ? _vm.$t("ui.shared.global.follow")
                      : "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.follow.apply(null, arguments)
                    },
                  },
                }),
              ]
            },
            { follow: _vm.follow, unfollow: _vm.unfollow }
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }