var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "partner-buttons" } }, [
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "text-center partner-header-container" }, [
      _c("span", {
        staticClass: "p-2 partner-header",
        domProps: {
          textContent: _vm._s(
            _vm.$t("ui.components.base.partner_buttons.partner_login")
          ),
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-center pt-3" }, [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: { title: "BCA", disabled: _vm.handheld },
              expression: "{ title: 'BCA', disabled: handheld }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "px-3",
          attrs: { id: "bca" },
        },
        [
          _c("ci-button-to", {
            attrs: {
              url: "/users/auth/bca",
              "image-url":
                "https://www.bca.de/wp-content/uploads/2018/06/icon@2x.png",
              "extra-params": _vm.extraParams,
              "authenticity-token": _vm.authenticityToken,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: { title: "FiNet", disabled: _vm.handheld },
              expression: "{ title: 'FiNet', disabled: handheld }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "px-2",
          attrs: { id: "finet" },
        },
        [
          _c("ci-button-to", {
            attrs: {
              url: "https://sso.finet.de/saml2/idp/SSOService.php",
              "extra-params": {
                spentityid: "https://capinside.com/users/saml/metadata",
              },
              "image-url":
                "https://www.finet.de/fileadmin/user_upload/Inhalte/FiNet_Logo-Fav-WG_RGB_128.png",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: { title: "VDH", disabled: _vm.handheld },
              expression: "{ title: 'VDH', disabled: handheld }",
              modifiers: { hover: true },
            },
          ],
          staticClass: "px-3",
          attrs: { id: "vdh" },
        },
        [
          _c("ci-button-to", {
            attrs: {
              url: "https://vdh24.okta.com/app/vdh24_capinside_1/exk3ehn84gIxEuLeG417/sso/saml",
              "image-url":
                "https://www.verbund-deutscher-honorarberater.de/hubfs/VDH_Signet_151201_RGB.png",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }