var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showGate
    ? _c(
        "div",
        { staticClass: "ci-professional-gate" },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-professional-gate",
                variant: "fixed",
                size: "sm",
                roundedBody: true,
                centered: "",
                visible: "",
                "no-close-on-backdrop": "",
                "hide-footer": "",
                "hide-header": "",
              },
              on: { hide: _vm.onHideGate },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "row justify-content-center align-items-center m-0",
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("p", [
                        _c("strong", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("ui.components.professional_gate.headline")
                            ),
                          },
                        }),
                      ]),
                      _c("p", {
                        staticClass: "text-sm",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("ui.components.professional_gate.body")
                          ),
                        },
                      }),
                      _c("ci-button", {
                        staticClass: "w-100",
                        attrs: {
                          variant: "filled-primary",
                          label: _vm.$t(
                            "ui.components.professional_gate.accept"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$bvModal.hide("modal-professional-gate")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }