import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { store } from "@stores";
import { CurrentUser } from "@utils/user";
import { viewportService } from "@services/viewport.service";
@Module({
  dynamic: true,
  store: store,
  name: "header",
  namespaced: true,
})
export class HeaderStoreModule extends VuexModule {
  public searchExpanded: boolean = false;
  public navExpanded: boolean = false;

  public get conditionals(): Indexable {
    const showSearch = (CurrentUser.signedIn && viewportService.check(["xxl", "xl", "md"])) || this.searchExpanded;
    const fullscaleSearch = viewportService.check(["xs", "sm"]) && showSearch;
    const showSearchToggle = !showSearch && !fullscaleSearch;
    const showNotifications = CurrentUser.signedIn;
    const showNotificationsMobileButton = showNotifications && !fullscaleSearch;
    const hideButtonLabels = viewportService.check(["xs", "sm", "md", "lg"]) || showNotifications;
    const showLoginButton = !CurrentUser.signedIn && !fullscaleSearch;
    const showLoginLabel = viewportService.check(["xl", "xxl"]);
    const showMessagingButton = CurrentUser.signedIn && !fullscaleSearch;
    const showRegisterButton = !CurrentUser.signedIn && !fullscaleSearch;
    const showNav = viewportService.check(["xs", "sm", "md", "xl", "xxl"]) || !showSearch;
    return {
      showSearch,
      showSearchToggle,
      hideButtonLabels,
      showLoginButton,
      showLoginLabel,
      showNav,
      fullscaleSearch,
      showMessagingButton,
      showRegisterButton,
      showNotifications,
      showNotificationsMobileButton,
    };
  }

  @Action
  public expandSearch(): void {
    this.context.commit("EXPAND_SEARCH");
  }

  @Action
  public collapseSearch(): void {
    this.context.commit("COLLAPSE_SEARCH");
  }

  @Action
  public expandNav(): void {
    this.context.commit("EXPAND_NAV");
  }

  @Action
  public collapseNav(): void {
    this.context.commit("COLLAPSE_NAV");
  }

  @Mutation
  protected EXPAND_SEARCH(): void {
    this.searchExpanded = true;
  }

  @Mutation
  protected COLLAPSE_SEARCH(): void {
    this.searchExpanded = false;
  }

  @Mutation
  protected EXPAND_NAV(): void {
    this.navExpanded = true;
  }

  @Mutation
  protected COLLAPSE_NAV(): void {
    this.navExpanded = false;
  }
}
