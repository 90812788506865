import Vue from "vue";
import { CustomValidators } from "./validators";

export class StoreClass implements ModalStore<any> {
  public id: string;
  public state: any;

  constructor(id: string, state?: any) {
    this.id = id;
    if (state) this.state = Vue.observable(state);
  }

  public show(state?: any): void {
    if (state) this.update(state);
    window.$vnode.$bvModal.show(this.id);
  }

  public hide(): void {
    window.$vnode.$bvModal.hide(this.id);
  }

  /*
   * Attention: Do not just override the state property,
   * JS will lose the reference and its not observable anymore for vue!
   */
  public update(state: any): void {
    if (this.state) {
      if (CustomValidators.isArray(state)) {
        this.state.length = 0;
        state.forEach((item: any) => this.state.push(item));
      }

      if (CustomValidators.isObject(state)) {
        Object.entries(state).forEach(([key, value]) => {
          this.state[key] = value;
        });
      }
    }
  }
}

export function ModalStoreFactory<T>(id: string, state?: T): ModalStore<T> {
  return new StoreClass(id, state) as ModalStore<T>;
}
