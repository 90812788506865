import { settingsService } from "@services/settings.service";

export const ContentTrackingIdentifier = (): string | null | undefined =>
  document.querySelector("meta[name=tracking-identifier]")?.getAttribute("content");

export const CreateRoute = (config: CIRouteConfig<Partial<CIRouteMeta>>): CIRouteConfig => {
  const meta = {
    pageType: "",
    trackingIdentifier: "",
    gated: false,
    wrapContent: true,
    spa: config.component ? !!config.component : false,
    ...(config.meta || {}),
  };
  return {
    ...config,
    get path(): string {
      const locales = settingsService.options.available_locales.join("|");
      return `/:locale(${locales})*${config.path}`;
    },
    meta: meta,
  };
};
