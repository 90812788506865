
import { computed, defineComponent, getCurrentInstance, PropType, Ref } from "vue";
import { computedAsync } from "@vueuse/core";
import { getUnixTime, subYears } from "date-fns";
import CiBookmark from "@components/base/bookmark.vue";
import CiButton from "@components/base/button.vue";
import CiDropdownItem from "@components/base/dropdown-item.vue";
import CiDropdownMenu from "@components/base/dropdown-menu.vue";
import CiShare from "@components/base/share.vue";
import CiHighcharts from "@components/charts/highcharts.vue";
import { useI18n } from "@composables/i18n";
import { usePortfolioActions } from "@composables/portfolio_actions";
import { SharingNetworks } from "@models/enums";
import CiRouterLink from "@router/router-link.vue";
import { chartService } from "@services/charts.service";
import { dateService } from "@services/date.service";
import { chartApiService } from "@services/performance_charts/chart-api.service";
import PerformanceChartRequest from "@services/performance_charts/performance-chart-request";

type ElementVisibility = {
  history: boolean;
  visibility: boolean;
  share: boolean;
  duplicate: boolean;
  follow: boolean;
  metrics: boolean;
};

export default defineComponent({
  name: "CiPortfolioTile",
  components: { CiBookmark, CiButton, CiDropdownItem, CiDropdownMenu, CiHighcharts, CiRouterLink, CiShare },
  props: {
    portfolio: {
      type: Object as PropType<Portfolios.TileData>,
      default: () => ({}),
    },
    variant: {
      type: String as PropType<"" | "side-by-side" | "start-page">,
      default: "",
    },
    followOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    withBorder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    context: {
      type: String as PropType<"default" | "search">,
      default: "default",
    },
  },
  emits: ["removed"],
  setup(props, { emit }) {
    const portfolioActions = usePortfolioActions(props.portfolio);
    const app = getCurrentInstance();
    const { t } = useI18n(app);

    const elementVisibility: Ref<ElementVisibility> = computed(() => {
      const result: ElementVisibility = {
        history: true,
        visibility: true,
        share: true,
        duplicate: true,
        follow: true,
        metrics: true,
      };

      if (props.context === "search") {
        result.history = false;
        result.follow = false;
      }

      return result;
    });

    const notificationBubbleVisibility: Ref<boolean> = computed(
      () =>
        Date.parse(props.portfolio.updated_at) > (Date.parse(props.portfolio.changes_seen_at as string) || 0) &&
        !portfolioActions.isOwner.value
    );

    const chartPeriodLabel: Ref<string> = computed(() => {
      const oneYearAgo = subYears(new Date(), 1);

      if (oldestDatapointTimestamp.value < getUnixTime(oneYearAgo))
        return t("ui.shared.global.metrics.performance.long_with_year_period", { period: 1 }) as string;
      else
        return t("ui.shared.global.metrics.performance.long_since", {
          date: dateService.format("dd.MM.yyyy", oldestDatapointTimestamp.value),
        }) as string;
    });

    const chartDisabled = computed(() => props.portfolio?.portfolio_products.map((pp) => pp.product).length === 0);

    const defaultChart = chartService.portfolioTileChart(
      [
        {
          fillColor: "transparent",
          color: chartService.colors.primary,
          colorIndex: 0,
          data: [],
          name: "",
        } as any,
      ],
      {
        minMax: 30,
      }
    );

    const portfolioSeriesData = computedAsync(async () => {
      const performanceRequest = new PerformanceChartRequest({
        euro: true,
        range: "1y",
      });
      const portfolioRequest = performanceRequest.addPortfolio(props.portfolio);
      return await portfolioRequest.chart();
    }, null);

    const chartData = computed(() => {
      if (!portfolioSeriesData.value || chartDisabled.value) return defaultChart;

      return chartService.portfolioTileChart([
        {
          fillColor: "transparent",
          color: chartService.colors.primary,
          colorIndex: 0,
          data: portfolioSeriesData.value,
          name: "",
        } as any,
      ]);
    });
    const oldestDatapointTimestamp = computed(() => {
      if (!portfolioSeriesData.value) return 0;

      return chartApiService.getCollectionStartingTimestamp(portfolioSeriesData.value);
    });

    const ownerLabel = computed(() => {
      if (portfolioActions.isOwner.value) return t("ui.shared.portfolios.my_portfolio") as string;
      else return props.portfolio?.owner.name;
    });

    const confirmRemove = async () => {
      const messageBoxOptions = {
        title: t("ui.shared.portfolios.actions.delete.confirmation.title") as string,
        okTitle: t("action.delete") as string,
        okVariant: "danger delete",
        bodyClass: "text-sm",
        modalClass: "ci-modal ci-modal-flexible ci-modal-size-md",
        cancelVariant: "light",
        cancelTitle: t("action.cancel") as string,
      };
      const response = await app?.proxy.$root.$bvModal.msgBoxConfirm(
        t("ui.shared.portfolios.actions.delete.confirmation.description") as string,
        messageBoxOptions
      );
      if (response) {
        portfolioActions.remove({ redirectToIndex: true });
        emit("removed", props.portfolio);
      }
    };

    const sharingOptions = computed(() => ({
      trackingTarget: {
        name: "Portfolio",
        identifier: props.portfolio?.id.toString(),
      },
      sharingUrl: location.origin + `/portfolios/${props.portfolio?.slug}`,
    }));

    return {
      elementVisibility,
      chartData,
      notificationBubbleVisibility,
      chartPeriodLabel,
      chartDisabled,
      isOwner: portfolioActions.isOwner,
      ownerLabel,
      showHistory: portfolioActions.showHistory,
      duplicate: portfolioActions.duplicate,
      setVisibility: portfolioActions.setVisibility,
      rename: portfolioActions.rename,
      confirmRemove,
      sharingOptions,
    };
  },
  computed: {
    sharingNetworks(): SharingNetworks[] {
      return [SharingNetworks.CustomEmail, SharingNetworks.CustomMessaging];
    },
    disabledNetworks(): SharingNetworks[] {
      return this.portfolio.public_portfolio ? [] : [SharingNetworks.CustomEmail];
    },
  },
});
