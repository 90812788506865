
import { ciTrackingService } from "@services/ci-tracking.service";
import { Component, Model, Prop, Vue } from "vue-property-decorator";
import { buildURL } from "@utils/build-url";
import { ROUTENAMES } from "@models/constants/routes";

@Component({
  name: "ci-tag",
})
export default class extends Vue {
  @Prop({ required: true }) public tag!: Record<string, string>;
  @Prop({ required: true }) public tagName!: string;
  @Prop() public tagId?: number;
  @Prop({}) public variant?: string;
  @Prop({ default: "" }) public value?: string;
  @Prop({}) public context?: string;
  @Prop({ default: false }) public deletable?: boolean;
  @Prop({ default: "" }) public trackingIdentifierOverride?: string;
  @Prop({ default: "Tag" }) public trackingTargetName!: string;
  @Prop({ default: "default" }) public styleVariant!: "default" | "light" | "outline-primary";
  @Prop({}) public searchUrl?: string;

  @Model("input", { type: Array }) options?: Users.InterestTagsList;

  public get checked(): boolean {
    const value = this.selected.filter((t) => t.id === this.tagId).length > 0 || false;
    return value;
  }

  public get selected(): Users.InterestTagsList {
    return this.options || [];
  }

  public set selected(val: Users.InterestTagsList) {
    this.$emit("input", val);
  }

  public get interestTagsQuery(): string {
    const searchPath = "/search";
    let compiledPath: string;

    if (this.context !== undefined) {
      compiledPath = buildURL(searchPath, { searchParams: { q: this.tagName, type: this.context } });
    } else {
      compiledPath = buildURL(searchPath, { searchParams: { q: this.tagName } });
    }
    return compiledPath;
  }

  public get contentHubPath(): string {
    return this.$router.resolve({ name: ROUTENAMES.CONTENT_HUBS_SHOW, params: { pathMatch: this.tag.slug } }).href;
  }

  public get newPath(): string {
    if (this.searchUrl) return this.searchUrl;

    return this.contentHubPath;
  }

  public async trackClick(): Promise<void> {
    if (this.tagId) {
      try {
        await ciTrackingService.trackClick(
          this.trackingTargetName,
          this.tagId?.toString(),
          null,
          window.location.origin + this.newPath
        );
      } catch (err) {
        console.error(err);
      }
    }

    if (this.$page.context === "spa") await this.$router.push(this.newPath);
    else window.location.href = this.newPath;
  }
}
