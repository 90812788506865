var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.variant === "link"
        ? [
            _c("ci-router-link", {
              staticClass: "tag flex-center text-decoration-none",
              class: _vm.styleVariant,
              attrs: { to: _vm.newPath, label: _vm.tagName },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.trackClick.apply(null, arguments)
                },
              },
            }),
          ]
        : _vm.variant === "checkbox"
        ? [
            _c(
              "label",
              {
                staticClass: "m-0 tag flex-center",
                class: [_vm.styleVariant, { selected: _vm.checked }],
                attrs: { for: _vm.$vnode.key },
              },
              [
                _c("span", { domProps: { textContent: _vm._s(_vm.tagName) } }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selected,
                      expression: "selected",
                    },
                  ],
                  attrs: { id: _vm.$vnode.key, type: "checkbox" },
                  domProps: {
                    value: _vm.tag,
                    checked: Array.isArray(_vm.selected)
                      ? _vm._i(_vm.selected, _vm.tag) > -1
                      : _vm.selected,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.tag,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selected = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selected = $$c
                      }
                    },
                  },
                }),
              ]
            ),
          ]
        : [
            _c(
              "div",
              { staticClass: "tag flex-center", class: _vm.styleVariant },
              [
                _c("span", {
                  staticClass: "name",
                  domProps: { textContent: _vm._s(_vm.tagName) },
                }),
                _vm.deletable
                  ? _c("a", {
                      staticClass:
                        "cursor-pointer mdi mdi-close text-dark ml-2",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete", _vm.tag)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }