import { CreateElement } from "vue";

export const LoadingSpinner = {
  functional: true,
  render(h: CreateElement) {
    return h("span", { class: "mdi mdi-loading mdi-spin h1 mb-0" });
  },
};

export const LoadingCard = {
  functional: true,
  render(h: CreateElement) {
    return h("div", { class: "ci-section ci-card ci-content-padding my-4 flex-center" }, [h(LoadingSpinner)]);
  },
};
