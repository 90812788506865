
import { buildURL } from "@utils/build-url";
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { AnalyticsStoreModule } from "@stores/analytics.store";
import { TranslateResult } from "vue-i18n";

const analyticsStore = getModule(AnalyticsStoreModule);

@Component({
  name: "ci-gating",
})
export default class extends Vue {
  @Prop({ required: true }) location!: string;
  // use gatingRule to set / unset gating banner
  @Prop({ default: "authenticated" }) gatingRule!:
    | "authenticated"
    | "private"
    | "professional"
    | "classification"
    | "none";

  @Prop({ default: "50vh" }) teaserHeight!: string;
  @Prop({ default: () => ({}) }) learnMoreTrackingData!: {
    sourceName: string;
    sourceIdentifier: string;
    sourceUrl?: string;
    targetUrl?: string;
  };

  public isMounted = false;

  public get gated(): boolean {
    if (!this.gatingRule || this.gatingRule === "none") {
      return false;
    } else {
      return true;
    }
  }

  public get teaserStyle(): Indexable {
    if (this.gated) {
      if (this.teaserHeight === "auto" && this.isMounted && this.$refs.teaser) {
        const maxTeaserHeight = window.innerHeight * 0.3;
        const contentRect = (this.$refs.teaser as any).getBoundingClientRect();
        return {
          height: contentRect.height > maxTeaserHeight ? `${maxTeaserHeight}px` : `${contentRect.height + 32}px`,
        };
      }
      return { height: this.teaserHeight };
    } else return {};
  }

  public get registrationUrl(): string {
    return buildURL("/users/sign_up", {
      searchParams: {
        gating: "true",
        onboarding_type: "gating-banner",
        gating_banner_location: this.location,
      },
    });
  }

  public get tracking(): Indexable<GTMTracking.GaTrackingEvent> {
    return {
      login: {
        eventCategory: "Login",
        eventAction: "LoginClick",
        eventLabel: `login_location : gating-banner ; gating_banner_location : ${this.location}`,
      },
      registration: {
        eventCategory: "Registration",
        eventAction: "RegisterClick",
        eventLabel: `register_location : gating-banner ; gating_banner_location : ${this.location}`,
      },
    };
  }

  public get learnMoreTrackingEvent(): GTMTracking.GaTrackingEvent {
    return {
      eventCategory: "Landingpage",
      eventAction: "Button DetailsOpen",
      eventLabel: { sourceUrl: window.location.href, targetUrl: this.learnMoreUrl, ...this.learnMoreTrackingData },
    };
  }

  public get learnMoreUrl(): string {
    return this.$settings?.options.flippers.gating_digital_workbench
      ? "https://capinside.com/w/digitale-werkbank"
      : "https://capinside.com/w/investmentplattform";
  }

  public get gatingInfo(): Record<string, TranslateResult[]> {
    const info = {
      description: [this.$t("ui.components.gating." + this.gatingRule + ".description")],
      enticement: [this.$t("ui.components.gating." + this.gatingRule + ".enticement_1")],
    };
    const enticement2Key = "ui.components.gating." + this.gatingRule + ".enticement_2";
    if (this.$te(enticement2Key)) {
      info.enticement.push(this.$t(enticement2Key));
    }
    return info;
  }

  public login(): void {
    analyticsStore.updateLoginLocation("gating-banner");
    analyticsStore.updateGatingLocation(this.location);
    this.$bvModal.show("modal-login");
  }

  public mounted(): void {
    this.isMounted = true;
  }
}
