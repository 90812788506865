import { computed, getCurrentInstance, Ref } from "vue";
import { ViewportStoreModule } from "@stores/viewport.store";
import { getModule } from "vuex-module-decorators";
import { useI18n } from "@composables/i18n";
import { CreateToast } from "@utils/toasts";

export const useProductAlarm = (
  product: Ref<Products.TileData | Products.Followed.Product | Products.DetailResponseFunds>
) => {
  const ViewportStore = getModule(ViewportStoreModule);
  const app = getCurrentInstance();
  const { t } = useI18n(app);

  const alarmButtonDisabled = computed(
    () =>
      !!product.value &&
      !!product.value.prices &&
      !(product.value.prices.bid || product.value.prices.nav) &&
      !validPerformancePeriodSelected.value
  );

  const productAlarmTooltipContent = computed(() => {
    if (alarmButtonDisabled.value) {
      return t("products.alarm.no_data");
    }
    if (product.value !== undefined && product.value.performance_alarm) {
      return t(
        product.value.performance_alarm.alarm_type === "price"
          ? "products.alarm.tooltip_price"
          : "products.alarm.tooltip_performance",
        {
          value: product.value.performance_alarm.target_value.toFixed(2),
          unit: product.value.performance_alarm.alarm_type === "performance" ? "%" : product.value.currency,
        }
      );
    }
    return t("products.alarm.set_alarm");
  });

  const isDesktop = computed(() => !ViewportStore.smartphone);
  const displayDesktopProductAlarmModalButton = computed(() => isDesktop.value);
  const displayMobileProductAlarmModalButton = computed(() => !isDesktop.value);

  const validPerformancePeriodSelected = computed(() => {
    if ("performances_euro" in product.value) {
      return (
        !!product.value.performances_euro.performance_1_year ||
        !!product.value.performances_euro.performance_3_years ||
        !!product.value.performances_euro.performance_5_years ||
        !!product.value.performances_euro.performance_10_years ||
        !!product.value.performances_euro.performance_1_month ||
        !!product.value.performances_euro.performance_3_months ||
        !!product.value.performances_euro.performance_6_months
      );
    }

    if ("performance" in product.value) {
      return (
        !!product.value.performance.performance_1_year ||
        !!product.value.performance.performance_3_years ||
        !!product.value.performance.performance_5_years ||
        !!product.value.performance.performance_10_years ||
        !!product.value.performance.performance_1_month ||
        !!product.value.performance.performance_3_months ||
        !!product.value.performance.performance_6_months
      );
    }

    return false;
  });

  const displayProductAlarmModal = computed(
    () =>
      product.value !== undefined &&
      !!(product.value.prices.bid || product.value.prices.nav) &&
      !!product.value.currency &&
      validPerformancePeriodSelected.value
  );

  const confirmProductAlarmRemoval = async (): Promise<boolean> => {
    if (product.value === undefined || !product.value.performance_alarm) return true;

    const response = await app?.proxy.$root.$bvModal.msgBoxConfirm(
      t("ui.shared.products.confirm_product_alarm_removal.message") as string,
      {
        title: t("ui.shared.products.confirm_product_alarm_removal.title") as string,
        size: "sm",
        okVariant: "danger",
        okTitle: t("ui.shared.products.confirm_product_alarm_removal.ok_button") as string,
        cancelTitle: t("action.cancel") as string,
        bodyClass: "text-sm",
        modalClass: "ci-modal ci-modal-flexible ci-modal-size-md",
      }
    );
    if (response)
      CreateToast.success(t("ui.shared.products.confirm_product_alarm_removal.alarm_deactivated").toString());

    return response;
  };

  return {
    alarmButtonDisabled,
    productAlarmTooltipContent,
    displayProductAlarmModal,
    displayMobileProductAlarmModalButton,
    displayDesktopProductAlarmModalButton,
    confirmProductAlarmRemoval,
  };
};

export default useProductAlarm;
