const defaultOptions: Settings.Options = {
  user: {
    type: "guest",
    admin: false,
    region: {
      algolia_languages: ["de", "en"],
      algolia_tag: "region_code_DE",
      base_url: "http://test.host",
      beta: false,
      code: "DE",
      default_locale: "de",
      host: "test.host",
      locales: ["de", "en"],
      name: "GER",
    },
  },
  csrfToken: undefined,
  environment: "development",
  stage: "development",
  release: "undefined",
  viewport: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  algolia_application_id: "",
  algolia_live_search_key: "",
  algolia_regionalized_live_search_keys: {},
  algolia_query_languages: ["de"],
  froala_key: "",
  appsignal_frontend_api_key: "",
  algolia_index_names: {
    users: "",
    funds: "",
    contents: "",
    company_profiles: "",
    social_events: "",
    portfolios: "",
    content_routes: "",
  },
  region: {
    algolia_languages: ["de", "en"],
    algolia_tag: "region_code_DE",
    base_url: "http://test.host",
    beta: false,
    code: "DE",
    default_locale: "de",
    host: "test.host",
    locales: ["de", "en"],
    name: "GER",
  },
  available_regions: [
    {
      algolia_languages: ["de", "en"],
      algolia_tag: "region_code_DE",
      base_url: "http://test.host",
      beta: false,
      code: "DE",
      default_locale: "de",
      host: "test.host",
      locales: ["de", "en"],
      name: "GER",
    },
  ],
  alternates: [
    {
      hreflang: "x-default",
      href_base: "http://test.host",
    },
  ],
  locale: "de",
  available_locales: ["de", "en", "de-CH", "de-AT"],
  only_professional_registrations: false,
  upload: "",
  flippers: {},
  imgix_url: "",
  registration: {
    omniauth: {
      form_authenticity_token: "",
      providers: [],
    },
    path: "",
    redirect_url: "",
    resend_email_path: "",
    root_path: "",
  },
  google_publisher_id: "",
  chart_service_base_url: "",
  login: { block_unconfirmed_user_path: "" },
  userpilot_token: "",
  recaptcha_key: "",
  community_manager_email: "",
  app_request: false,
};

class Service implements Settings.Service {
  public options: Settings.Options;

  constructor() {
    this.options = { ...defaultOptions, ...window.$settings };
    this.loadCSRFToken();
  }

  public get algoliaRegionalizedLiveSearchKey() {
    if (!this.options.algolia_regionalized_live_search_keys) return "";
    return this.options.algolia_regionalized_live_search_keys[this.options.region.code];
  }

  public merge: Settings.MergeFn = (options) => {
    this.options = { ...this.options, ...options };
  };

  public hasFlipper: Settings.HasFlipperFn = (name) => !!settingsService.options.flippers[name];

  private loadCSRFToken: Settings.LoadCSRFTokenFn = () => {
    const meta = document.querySelector("meta[name='csrf-token']");
    const token = meta ? meta.getAttribute("content") || "" : "";

    this.options.csrfToken = token;

    return token;
  };
}

export const settingsService = new Service();
