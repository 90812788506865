var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("validation-provider", {
    staticClass: "ci-form--wrapper",
    attrs: { name: _vm.validationName, rules: _vm.rules, tag: "div" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ classes, errors }) {
          return [
            _c(
              "div",
              {
                staticClass: "ci-form--group",
                class: { ...classes, ..._vm.baseClasses },
              },
              [
                _vm.leadingIcon
                  ? [
                      _c("span", {
                        staticClass: "ci-form--leading-icon flex-center mdi",
                        class: _vm.leadingIconClasses,
                        on: { click: _vm.leadingIconHandler },
                      }),
                    ]
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showValue,
                      expression: "showValue",
                    },
                  ],
                  staticClass: "ci-form--input",
                  class: { "hide-arrows": _vm.hideArrows },
                  attrs: {
                    name: _vm.name,
                    type: "number",
                    max: _vm.max,
                    min: _vm.min,
                    placeholder: " ",
                    id: _vm.inputId,
                    autocomplete: _vm.autocomplete ? "on" : "off",
                    disabled: _vm.disabled,
                  },
                  domProps: { value: _vm.showValue },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.showValue = $event.target.value
                      },
                      _vm.handleInput,
                    ],
                    blur: _vm.handleBlur,
                    focus: _vm.handleFocus,
                  },
                }),
                _c(
                  "label",
                  { staticClass: "ci-form--label", attrs: { for: _vm.name } },
                  [_vm._v(_vm._s(_vm.visibleLabel))]
                ),
                _vm.trailingIcon
                  ? [
                      _c("span", {
                        staticClass:
                          "ci-form--trailing-icon btn-cursor flex-center mdi",
                        class: _vm.trailingIconClasses,
                        on: { click: _vm.trailingIconHandler },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            errors.length > 0
              ? _c("span", { staticClass: "ci-form--error" }, [
                  _vm._v(_vm._s(errors[0])),
                ])
              : _vm._e(),
            _vm.hint
              ? _c("span", {
                  staticClass: "ci-form--hint",
                  domProps: { textContent: _vm._s(_vm.hintText) },
                })
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }