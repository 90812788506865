import { render, staticRenderFns } from "./header.vue?vue&type=template&id=0238cb1c&lang=pug"
import script from "./header.vue?vue&type=script&lang=ts"
export * from "./header.vue?vue&type=script&lang=ts"
import style0 from "./header.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@locales/de/de.yml?namespace=ui.shared.navigation?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuild%2Ffrontend%2Fcomponents%2Fheader%2Fheader.vue&locale=de&lang=yaml&global=true&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@locales/en/en.yml?namespace=ui.shared.navigation?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuild%2Ffrontend%2Fcomponents%2Fheader%2Fheader.vue&locale=en&lang=yaml&global=true&external"
if (typeof block1 === 'function') block1(component)

export default component.exports