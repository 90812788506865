
import { computed, defineComponent, ref } from "vue";
import { settingsService } from "@services/settings.service";
import { CurrentUser } from "@utils/user";

export default defineComponent({
  name: "CiNoticeBar",
  setup() {
    const currentRegion = settingsService.options.region;
    const userRegion = CurrentUser.region!;

    const messagePaths = computed(() => ({
      hint: `ui.components.notice_bar.usage_message.${currentRegion.code}`,
      back: `ui.components.notice_bar.back_message.${userRegion.code}`,
    }));
    const homeUrl = computed(() => userRegion.base_url);
    const closed = ref(false);
    const show = computed(() => {
      if (closed.value) return false;
      if (!CurrentUser.signedIn) return false;

      return userRegion.code !== currentRegion.code;
    });

    const close = () => {
      closed.value = true;
    };

    return { closed, messagePaths, homeUrl, show, close };
  },
});
