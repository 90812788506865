var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.hasFlipperEnabled
    ? _c(
        "ci-router-link",
        {
          staticClass:
            "ci-header__nav-child d-flex align-items-center text-decoration-none p-2 my-1 rounded",
          class: { active: _vm.active },
          attrs: {
            to: _vm.item.route.location,
            gated: _vm.gated,
            "aria-label": _vm.$t(_vm.titlePath),
            title: _vm.$t(_vm.titlePath),
          },
          on: {
            click: function ($event) {
              return _vm.$emit("click", $event)
            },
          },
        },
        [
          _c("div", {
            staticClass: "icon mdi mdi-36px text-primary mr-3",
            class: _vm.item.icon,
          }),
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-content-center" },
            [
              _c("div", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: _vm.titlePath,
                    expression: "titlePath",
                  },
                ],
                staticClass: "title h6 mb-1",
              }),
              _c("span", {
                directives: [
                  {
                    name: "t",
                    rawName: "v-t",
                    value: _vm.descriptionPath,
                    expression: "descriptionPath",
                  },
                ],
                staticClass: "description text-sm",
              }),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }