var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "ci-header__nav-secondary d-flex align-items-center justify-content-end flex-grow-1 position-relative",
    },
    [
      _c(
        "ul",
        {
          staticClass:
            "d-flex align-items-center justify-content-end flex-grow-1",
        },
        [
          _vm.conditionals.showRegisterButton
            ? _c(
                "li",
                [
                  _c("ci-button", {
                    staticClass: "mx-1 flex-shrink-0 px-2",
                    attrs: {
                      id: "header-register",
                      to: {
                        path: "/users/sign_up",
                        query: { onboarding_type: "menubar" },
                      },
                      label: _vm.$t("ui.shared.navigation.misc.register"),
                      variant: "filled-primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.trackNavClick("register")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.conditionals.showLoginButton
            ? _c(
                "li",
                [
                  _c("ci-button", {
                    staticClass: "mx-1 flex-shrink-0 px-2",
                    attrs: {
                      id: "nav-login",
                      icon: !_vm.conditionals.showLoginLabel
                        ? "mdi-login-variant"
                        : "",
                      "hide-label": !_vm.conditionals.showLoginLabel,
                      label: _vm.$t("ui.shared.navigation.misc.login"),
                      variant: _vm.conditionals.showRegisterButton
                        ? "outline-white"
                        : "filled-primary",
                    },
                    on: { click: _vm.openLoginModal },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.conditionals.showSearch
            ? _c(
                "li",
                { staticClass: "w-100 mx-1" },
                [_c("ci-nav-search", { attrs: { id: "nav-search" } })],
                1
              )
            : _vm._e(),
          _vm.conditionals.showSearchToggle
            ? _c(
                "li",
                [
                  _c("ci-button", {
                    staticClass: "mx-1 px-2",
                    attrs: {
                      id: "nav-search-toggle",
                      icon: "mdi-magnify",
                      "icon-class": "mdi-24px",
                      label: _vm.$t("ui.shared.navigation.misc.search"),
                      "hide-label": _vm.conditionals.hideButtonLabels,
                      variant: "filled-navigation",
                    },
                    on: { click: _vm.openSearch },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.conditionals.showMessagingButton
            ? _c("ci-nav-item", {
                key: _vm.conversationsItem.id,
                staticClass: "mx-1",
                attrs: {
                  drop: false,
                  notification: _vm.hasUnreadMessages ? 1 : 0,
                  item: _vm.conversationsItem,
                  "hide-label": _vm.conditionals.hideButtonLabels,
                  "tooltip-data": _vm.conditionals.showNotifications && {
                    text: _vm.$t("ui.shared.navigation.tooltips.messages"),
                    props: {
                      tooltipClass: "ci-header__nav-secondary__toggle-tooltip",
                    },
                  },
                },
                on: {
                  click: function ($event) {
                    return _vm.trackNavItemClick(_vm.conversationsItem)
                  },
                },
              })
            : _vm._e(),
          _vm.conditionals.showNotifications
            ? _c(
                "li",
                [
                  _c("ci-button", {
                    staticClass: "d-none d-md-flex ml-1 mr-2 px-2",
                    attrs: {
                      id: "nav-notifications-toggle",
                      icon: _vm.notificationsItem.icon,
                      "icon-class": "mdi-24px",
                      variant: "filled-navigation",
                      active:
                        _vm.notificationsOpen ||
                        _vm.$route.name == _vm.$routes.NOTIFICATIONS_INDEX,
                      notification:
                        _vm.$route.name !== _vm.$routes.NOTIFICATIONS_INDEX &&
                        _vm.unseenNotificationsCount,
                      tooltip: _vm.$t(
                        "ui.shared.navigation.tooltips.notifications"
                      ),
                      "tooltip-class": "ci-nav-secondary__toggle-tooltip",
                    },
                    on: { click: _vm.handleNotificationClick },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.conditionals.showNotificationsMobileButton
            ? _c("ci-nav-item", {
                key: _vm.notificationsItem.id,
                staticClass: "d-md-none ml-1 mr-2",
                attrs: {
                  id: "nav-notifications-toggle-mobile",
                  drop: false,
                  notification:
                    _vm.$route.name !== _vm.$routes.NOTIFICATIONS_INDEX &&
                    _vm.unseenNotificationsCount,
                  item: _vm.notificationsItem,
                  "hide-label": _vm.conditionals.hideButtonLabels,
                },
                on: {
                  click: function ($event) {
                    return _vm.trackNavItemClick(_vm.notificationsItem)
                  },
                },
              })
            : _vm._e(),
          _vm.conditionals.showNotifications
            ? _c(
                "li",
                [
                  _c("ci-nav-notifications-popup", {
                    attrs: { open: _vm.notificationsOpen },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.$user.signedIn && !_vm.conditionals.fullscaleSearch
            ? _c("li", [_c("ci-nav-user", { staticClass: "mx-1" })], 1)
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }