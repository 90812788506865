var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "ci-user-avatar",
      class: _vm.icon ? "cursor-pointer" : "",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.showVerifiedBadge
        ? _c("inline-svg", {
            staticClass: "verified-badge",
            class: _vm.size,
            attrs: { src: _vm.verifiedBadge },
          })
        : _vm._e(),
      _vm.icon
        ? _c("div", { staticClass: "avatar-icon flex-center cursor-pointer" }, [
            _c("div", {
              staticClass: "mdi mdi-24px text-white",
              class: _vm.icon,
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "avatar circle", class: _vm.classes },
        [
          !_vm.hasAvatar
            ? _c("inline-svg", { attrs: { src: _vm.genericIcon } })
            : _vm._e(),
          _vm.hasAvatar
            ? _c("img", {
                attrs: {
                  srcset: _vm.avatarSrcset,
                  src: _vm.avatarUrl,
                  alt: _vm.avatarAltText,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }