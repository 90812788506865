
import { Vue, Component, Prop } from "vue-property-decorator";
import { NavigationHelper } from "./helper";

@Component({
  name: "ci-nav-child",
})
export default class extends Vue {
  @Prop({ required: true }) item!: Navigation.Child;

  public get gated(): boolean {
    return (this.item.route.resolved.meta?.gated && !this.$user.signedIn) || false;
  }

  public get titlePath(): string {
    return `ui.shared.navigation.sub.${this.item.localeKey}.title`;
  }

  public get descriptionPath(): string {
    return `ui.shared.navigation.sub.${this.item.localeKey}.description`;
  }

  public get active(): boolean {
    return NavigationHelper.ItemIsActive(this.item, this.$route);
  }

  public get hasFlipperEnabled(): boolean {
    return NavigationHelper.FlipperIsActive(this.item);
  }
}
