var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "custom-foldable" }, [
    _c(
      "div",
      {
        staticClass: "custom-foldable__content",
        class: _vm.classes,
        style: _vm.style,
      },
      [
        _c(
          "div",
          {
            ref: "containerInner",
            staticClass: "custom-foldable__content-inner",
          },
          [_vm._t("default")],
          2
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTrigger,
            expression: "showTrigger",
          },
        ],
        staticClass: "custom-foldable__trigger",
        attrs: { "aria-controls": "foldable-text" },
        on: { click: _vm.toggle },
      },
      [
        _vm._t(
          "trigger",
          function () {
            return [
              _c("a", { staticClass: "btn-cursor" }, [
                _vm._v(
                  _vm._s(
                    _vm.collapsed
                      ? _vm.$t("foldable_text.show_more")
                      : _vm.$t("foldable_text.show_less")
                  )
                ),
              ]),
            ]
          },
          { toggle: _vm.toggle, collapsed: _vm.collapsed }
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }