import { DirectiveFunction } from "vue";
import { CreateToast } from "@utils/toasts";

const fn: DirectiveFunction = (_el, binding) => {
  if (!binding.value) {
    console.error("no binding value for create toast directive");
  } else {
    if (typeof binding.value === "string") CreateToast.notice(binding.value);
    else {
      const value = binding.value as { type?: string; message?: string; title?: string };
      switch (value.type) {
        case "notice":
          CreateToast.notice(value.message!);
          break;
        case "error":
          CreateToast.error(value.message!);
          break;
        case "alert":
          CreateToast.warning(value.message!);
          break;
        case "success":
          CreateToast.success(value.message!);
          break;
        default:
          CreateToast.notice(value.message!);
      }
    }
  }
};

export default {
  bind: fn,
};
