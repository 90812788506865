var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "suggested-external-content ci-content-padding-b",
          class: _vm.wrapperClasses,
        },
        [_c("div", { staticClass: "item", attrs: { id: _vm.id } })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }