import { CurrentUser } from "@utils/user";
import { ROUTENAMES } from "./routes";
import { Router } from "@router";

export const NavItem: Navigation.ItemConstructor = (
  id,
  localeKey,
  trackingIdentifier,
  routeName,
  groups = [],
  additionalRoutes = [],
  icon = ""
) => ({
  id,
  localeKey,
  icon,
  trackingIdentifier,
  route: Router.resolve({ name: routeName }),
  groups,
  additionalRoutes,
});

export const NavGroup: Navigation.GroupConstructor = (localeKey, children = [] as Navigation.Child[]) => ({
  localeKey,
  children,
});

export const NavChild: Navigation.ChildConstructor = (
  id,
  localeKey,
  icon,
  trackingIdentifier,
  routeName,
  additionalRoutes = [],
  routeParams = {}
) => ({
  id,
  localeKey,
  icon,
  trackingIdentifier,
  route: Router.resolve({ name: routeName, query: routeParams }),
  additionalRoutes,
});

export const MAIN_NAVIGATION = (): Navigation.List => [
  NavItem("news--nav", "news_center", "news", ROUTENAMES.NEWSCENTER_INDEX, [
    NavGroup("", [
      NavChild("news--nav-all_news", "all_news", "mdi-text-box", "newscenter", ROUTENAMES.NEWSCENTER_INDEX, [], {
        currentTab: CurrentUser.signedIn ? "most_relevant" : "latest",
      }),
      NavChild("news--nav-watchlist", "watchlist", "mdi-bookmark", "content-watchlist", ROUTENAMES.CONTENTS_WATCHLIST),
    ]),
    NavGroup("", [
      NavChild("news--nav-videos", "videos", "mdi-movie-open-play-outline", "videos", ROUTENAMES.CONTENTS_VIDEOS_INDEX),
      NavChild("news--nav-podcasts", "podcasts", "mdi-volume-high", "podcasts", ROUTENAMES.CONTENTS_PODCASTS_INDEX),
    ]),
    NavGroup("", [
      NavChild(
        "news--nav-content-hubs",
        "content_hubs",
        "mdi-book-open-page-variant",
        "content_hubs",
        ROUTENAMES.CONTENT_HUBS_ROOT_SHOW,
        [ROUTENAMES.CONTENT_HUBS_ROOT_SHOW]
      ),
    ]),
  ]),
  NavItem(
    "products--nav",
    "products",
    "funds",
    ROUTENAMES.PRODUCTS_SEARCH_INDEX,
    [
      NavGroup("products.groups.find_funds", [
        NavChild(
          "products--nav-products_search",
          "products_search",
          "mdi-magnify",
          "fund-search",
          ROUTENAMES.PRODUCTS_SEARCH_INDEX
        ),
        NavChild(
          "products--nav-products_ranking",
          "products_ranking",
          "mdi-poll",
          "fund-ranking",
          ROUTENAMES.PRODUCTS_RANKING_START
        ),
        NavChild(
          "products--nav-peergroups",
          "products_peergroups",
          "mdi-star",
          "best-funds",
          ROUTENAMES.PRODUCTS_PEERGROUPS_INDEX
        ),
      ]),
      NavGroup("products.groups.choose_funds", [
        NavChild(
          "products--nav-my_comparisons",
          "my_comparisons",
          "mdi-compare-horizontal",
          "fund-comparison",
          ROUTENAMES.COMPARISONS_INDEX
        ),
        NavChild(
          "products--nav-followed_products",
          "followed_products",
          "mdi-bookmark",
          "fund-watchlist",
          ROUTENAMES.PRODUCTS_WATCHLIST_INDEX
        ),
      ]),
      NavGroup("products.groups.invest", [
        NavChild(
          "products--nav-portfolios_search",
          "portfolios_search",
          "mdi-magnify",
          "portfolios",
          ROUTENAMES.PORTFOLIOS_COMPARISON_INDEX
        ),
        NavChild(
          "products--nav-portfolios",
          "portfolios",
          "mdi-chart-areaspline",
          "portfolios",
          ROUTENAMES.PORTFOLIOS_INDEX
        ),
      ]),
    ],
    [ROUTENAMES.COMPARISONS_SHOW, ROUTENAMES.PRODUCTS_SHOW, ROUTENAMES.PORTFOLIOS_SHOW, ROUTENAMES.PORTFOLIOS_EDIT]
  ),
  NavItem(
    "company-profiles--nav",
    "company_profiles",
    "company-profiles",
    ROUTENAMES.COMPANIES_ASSET_MANAGERS_INDEX,
    [
      NavGroup("", [
        NavChild(
          "company-profiles--nav-asset_managers",
          "company_profiles_asset_managers",
          "mdi-domain",
          "asset-managers",
          ROUTENAMES.COMPANIES_ASSET_MANAGERS_INDEX,
          [ROUTENAMES.COMPANIES_ASSET_MANAGERS_SHOW]
        ),
      ]),
      NavGroup("", [
        NavChild(
          "company-profiles--nav-wealth_managers",
          "company_profiles_wealth_managers",
          "mdi-domain",
          "wealth-managers",
          ROUTENAMES.COMPANIES_WEALTH_MANAGERS_INDEX,
          [ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW]
        ),
      ]),
      NavGroup("", [
        NavChild(
          "company-profiles--nav-financial_service_providers",
          "company_profiles_financial_service_providers",
          "mdi-domain",
          "financial-service-prodviders",
          ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_INDEX,
          [ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_SHOW]
        ),
      ]),
    ],
    [
      ROUTENAMES.COMPANIES_WEALTH_MANAGERS_SHOW,
      ROUTENAMES.COMPANIES_ASSET_MANAGERS_SHOW,
      ROUTENAMES.COMPANIES_SERVICE_PROVIDERS_SHOW,
    ]
  ),
  NavItem(
    "events--nav",
    "events",
    "events",
    ROUTENAMES.EVENTS_INDEX,
    [
      NavGroup("", [
        NavChild(
          "events--nav-upcoming",
          "events_upcoming",
          "mdi-calendar-blank",
          "upcoming-events",
          ROUTENAMES.EVENTS_INDEX
        ),
      ]),
      NavGroup("", [
        NavChild("events--nav-done", "events_done", "mdi-television-play", "past-events", ROUTENAMES.WEBCASTS_INDEX),
      ]),
    ],
    [ROUTENAMES.EVENTS_SHOW]
  ),
  NavItem("advisor_search--nav", "advisor_search", "financial-consulting", ROUTENAMES.ADVISOR_SEARCH_INDEX, []),
];

export const CONVERSATIONS_NAV_ITEM = NavItem(
  "conversations--nav",
  "conversations",
  "messages",
  ROUTENAMES.CONVERSATIONS_INDEX,
  [],
  [],
  "mdi-message-text"
);
export const NOTIFICATIONS_NAV_ITEM = NavItem(
  "notifications--nav",
  "notifications",
  "notifications",
  ROUTENAMES.NOTIFICATIONS_INDEX,
  [],
  [],
  "mdi-bell"
);
