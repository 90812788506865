var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ci-share" },
    [
      _vm.dropdown
        ? [
            _c(
              "ci-dropdown-menu",
              {
                attrs: {
                  placement: _vm.dropdownPosition,
                  showActiveState: false,
                  disabled: _vm.disabled,
                  preventToggle: _vm.togglePrevented,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button-content",
                      fn: function ({ expanded }) {
                        return [
                          _vm._t("button-content", function () {
                            return [
                              _c("ci-button", {
                                staticClass: "share",
                                attrs: {
                                  tooltip:
                                    _vm.tooltip ||
                                    _vm.$t("ui.shared.global.share"),
                                  icon: "mdi-share-variant",
                                  disabled: _vm.disabled,
                                  active: expanded,
                                  gated: _vm.gated,
                                  professional: _vm.professional,
                                  variant: _vm.buttonVariant,
                                  label: _vm.label,
                                },
                              }),
                            ]
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              _vm._l(_vm.networksWithOptions, function (network) {
                return _c(
                  "div",
                  {
                    key: network.attributes.network,
                    staticClass: "share-option",
                  },
                  [
                    network.gated
                      ? [
                          _c("ci-dropdown-item", {
                            class: network.attributes.network,
                            attrs: {
                              modal: `modal-${_vm.gatingType}`,
                              tooltip: _vm.$t(
                                "products.thumbnail_hover.please_login"
                              ),
                              name: network.label,
                              iconClasses: "mdi " + network.icon,
                            },
                          }),
                        ]
                      : [
                          network.internal
                            ? [
                                _c("ci-dropdown-item", {
                                  class: network.attributes.network,
                                  attrs: {
                                    iconClasses: "mdi " + network.icon,
                                    disabled: network.disabled,
                                    name: network.label,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitNetworkEvent(network)
                                    },
                                  },
                                }),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    class: network.attributes.network,
                                    on: {
                                      click: function ($event) {
                                        return _vm.emitNetworkEvent(network)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      network.disabled || _vm.disabled
                                        ? "share-network-disabled"
                                        : "share-network",
                                      _vm._b(
                                        {
                                          tag: "component",
                                          attrs: { tag: "div" },
                                        },
                                        "component",
                                        network.attributes,
                                        false
                                      ),
                                      [
                                        _c("ci-dropdown-item", {
                                          class: network.attributes.network,
                                          attrs: {
                                            tooltip: network.tooltip,
                                            iconClasses: "mdi " + network.icon,
                                            name: network.label,
                                            disabled: network.disabled,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                  ],
                  2
                )
              }),
              0
            ),
          ]
        : [
            _c(
              "ci-dropdown-item",
              {
                attrs: {
                  name: _vm.$t("social_share_button.share_content"),
                  expandable: true,
                  disabled: _vm.disabled,
                  iconClasses: "mdi mdi-18px mdi-share-variant",
                },
              },
              _vm._l(_vm.networksWithOptions, function (network) {
                return _c(
                  "div",
                  {
                    key: network.attributes.network,
                    staticClass: "share-option",
                  },
                  [
                    network.gated
                      ? [
                          _c("ci-dropdown-item", {
                            class: network.attributes.network,
                            attrs: {
                              modal: `modal-${_vm.gatingType}`,
                              tooltip: _vm.$t(
                                "products.thumbnail_hover.please_login"
                              ),
                              name: network.label,
                              iconClasses: "mdi " + network.icon,
                            },
                          }),
                        ]
                      : [
                          network.internal
                            ? [
                                _c("ci-dropdown-item", {
                                  class: network.attributes.network,
                                  attrs: {
                                    iconClasses: "mdi " + network.icon,
                                    disabled: network.disabled,
                                    name: network.label,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitNetworkEvent(network)
                                    },
                                  },
                                }),
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    class: network.attributes.network,
                                    on: {
                                      click: function ($event) {
                                        return _vm.emitNetworkEvent(network)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      network.disabled || _vm.disabled
                                        ? "share-network-disabled"
                                        : "share-network",
                                      _vm._b(
                                        {
                                          tag: "component",
                                          attrs: { tag: "div" },
                                        },
                                        "component",
                                        network.attributes,
                                        false
                                      ),
                                      [
                                        _c("ci-dropdown-item", {
                                          class: network.attributes.network,
                                          attrs: {
                                            tooltip: network.tooltip,
                                            iconClasses: "mdi " + network.icon,
                                            name: network.label,
                                            disabled: network.disabled,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ],
                  ],
                  2
                )
              }),
              0
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }